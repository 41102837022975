import {useLazyQuery} from "@apollo/client";
import GET_MERCHANT_USER from "@/features/user/api/get-merchant-user";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/store";
import {useEffect, useState} from "react";
import { removeAuthParams } from "@/features/auth/store/authSlice";

const useAuthorization = () => {
    const dispatch = useDispatch();
    const { activeMerchantId, merchantUserId } = useSelector(
        (state: RootState) => state.auth.params
    );

    const [getMerchantUser] = useLazyQuery(GET_MERCHANT_USER, {
        fetchPolicy: "no-cache"
    });
    const [currentPermissions, setCurrentPermissions] = useState(null);

    const checkPermission = async (permission: string[] | string) => {
        const permissions = await getPermissions();
        if (Array.isArray(permission)) {
            const results = permission.map((p) => permissions.includes(p.trim()));
            return results;
        } else {
            return permissions.includes(permission.trim());
        }
    }

    const getPermissions = async () => {
        const response = await getMerchantUser({
            variables: {
                merchantId: String(activeMerchantId),
                merchantUserId: String(merchantUserId)
            }
        });
        const { status } = response.data.getCurrentMerchantUser;
        if (status !== "DECLINED") {
            const { permissions } = response.data.getCurrentMerchantUser.merchantUser;
            setCurrentPermissions(permissions)
            return permissions;
        }
        dispatch(removeAuthParams());
        return [];
    }

    return {
        checkPermission,
        permissions: currentPermissions
    };
};

export default useAuthorization;
