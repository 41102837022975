import { useRef, useState } from 'react'
import { ProForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import {Button, Col, Row, Space, Card, message, Tooltip} from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { useParams } from "react-router-dom";
import { useMutation, useQuery} from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

import SAVE_MID_PROFILE_INPUT from "@/features/pos-management/api/mutations/save-mid-profile";
import {SaveOutlined, PlusOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { RESPONSE_STATUS } from '@/config/constants';
import {useTranslation} from "react-i18next";
import GET_MID_PROFILE_DETAIL from "@/features/pos-management/api/queries/get-mid-profile-detail";
import GET_MIDS_LIST from "@/features/pos-management/api/queries/get-mids-list";
import GET_ACQUIRER_LIST from "@/features/pos-management/api/queries/get-acquirer-list";

const EditPosConfiguration = () => {
    const formRef = useRef();
    const { configGroup } = useParams();
    const { activeMerchantId, merchantUserId } = useSelector((state: RootState) => state.auth.params);
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    interface AcquirersInterface {
        id: number;
        name: string;
    }

    const [acquirers, setAcquirers] = useState<AcquirersInterface[]>([]);
    const [midProfile, setMidProfile] = useState<any[]>([]);
    const [oldName, setOldName] = useState<String>("");

    const { data, loading } = useQuery(GET_MID_PROFILE_DETAIL, {
        variables: {
            merchantId: activeMerchantId,
            configGroupId: parseInt(configGroup ? configGroup : ''),
        },
        onCompleted:(data) => {
            setMidProfile(data.getMidProfileDetail.midProfile.midId)
            setOldName(data.getMidProfileDetail.midProfile.groupName)
        }
    });

    const { data: getMidOptions } = useQuery(GET_MIDS_LIST, {
        variables: {
            merchantId: activeMerchantId
        },
        onCompleted: (data) => {
            setOptions(data.getMidConfigurationList.result);
        },
    });
    const { data: acquirerData } = useQuery(GET_ACQUIRER_LIST, {
        variables: {
            merchantId: String(activeMerchantId)
        },
        onCompleted: (data) => {
            setAcquirers(data.getAcquirerList.result);
        },
    })

    const [saveMidProfile] = useMutation(SAVE_MID_PROFILE_INPUT);

    const addMidToProfileInput = () => {
        setMidProfile((options : any[]) => [...options, {
            midId: t("pos.select")
        }]);
    };

    const CreateAcquirerLabel = (option: any) => {
        const acquirer = acquirers.find(acquirer => acquirer.id === option.acquirer);
        const acquirerName = acquirer ? acquirer.name : '';
        const blockageText = option.blockageDayNumber ? ` - ${option.blockageDayNumber} gün bloke` : '';
        return `${option?.name} - ${acquirerName}${blockageText}`;
    };

    const removeMidToProfileInput = (index: number) => {
        setMidProfile((options : any[]) => options.filter((_, i) => i !== index));
    };

    const changeMidProfileInput = (name: string, value: string, index: number) => {
        setMidProfile((midProfile: any) => {
            let list = midProfile.map((item: any) => ({ ...item }));
            list[index][name] = value;
            return list;
        });
    };
    if (loading) return <p>Loading...</p>;

    return (
        <PageContainer>
            <Card title={configGroup !== "new" ? t("pos.edit.mid.profile.configuration") : t("pos.create.mid.profile.configuration")} style={{ marginTop: "15px" }}>

                <ProForm
                    layout='vertical'
                    formRef={formRef}
                    submitter={{
                        render: (props, doms) => {
                            return (
                                <Row style={{ margin: "20px 0 20px 0" }}>
                                    <Col>
                                        <Space>
                                            <Button onClick={addMidToProfileInput}
                                                icon={<PlusOutlined />}
                                                htmlType='button'>Yeni POS Ekle
                                            </Button>
                                            <Button
                                                htmlType='submit' icon={<SaveOutlined />}>
                                                {t("pos.save")}
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            )
                        },
                    }}
                    onFinish={async (values) => {
                        const { name, isDefault } = values;
                        const requestParams = {
                            saveMidProfileInput : {
                                mids: midProfile.filter(value => value.midId !== undefined).map(value => String(value.midId)),
                                merchant:[String(activeMerchantId)],
                                oldConfigGroupName: oldName,
                                newConfigGroupName: name,
                                default: String(isDefault),
                                adminUserId: merchantUserId,
                                merchantId: activeMerchantId
                            }
                        };
                        if(configGroup !== "new"){
                            // @ts-ignore
                            requestParams.saveMidProfileInput["configGroupId"] = Number(configGroup);
                        }

                        saveMidProfile({
                            variables: requestParams
                        }).then((response) => {
                            const { status, message: responseMessage } = response.data.saveMidProfile;
                            if (status !== RESPONSE_STATUS.APPROVED) {
                                throw new Error(responseMessage);
                            }
                            message.success(responseMessage);
                        }).catch((error) => {
                            message.error(error.message);
                        });
                    }}
                >
                    <ProFormText
                        name="name"
                        label={t("pos.profile.name")}
                        placeholder={t("pos.profile.name")}
                        initialValue={data?.getMidProfileDetail?.midProfile.groupName}
                    />
                    <ProFormSelect
                        options={[{label: t("pos.no"), value:0}, {label: t("pos.yes"), value:1}]}
                        label={t("pos.default")}
                        name="isDefault"
                        style={{maxWidth: 200}}
                        initialValue={data?.getMidProfileDetail?.midProfile.default ? data?.getMidProfileDetail?.midProfile.default : 0 }
                    />
                    <h3 style={{ marginTop: 30, marginBottom: 30, borderTop: "1px solid #CCC", paddingTop: 30 }}>{t("pos.assigned.poses")}</h3>

                    {midProfile?.map(({ merchantId, midId, priority}: any, index:any) => (
                        <Row aria-colcount={24} key={index}>
                            <Col span={20} style={{ paddingRight: 20 }}>
                                <ProFormSelect
                                    options={options.map((option: any) => ({
                                        label: CreateAcquirerLabel(option),
                                        value: option?.id
                                    }))}
                                    name={`postMid_${index}`}
                                    initialValue={midId}
                                    fieldProps={{
                                        onChange: (e: string) => {
                                            changeMidProfileInput('midId', e, index)
                                        }
                                    }}
                                />
                            </Col>

                            <Col span={4}>
                                {
                                    <Button
                                        onClick={() => {
                                            removeMidToProfileInput(index)
                                        }}
                                        icon={<CloseCircleOutlined />} danger>
                                        {t("pos.remove")}
                                    </Button>
                                }
                            </Col>
                        </Row>
                    ))}

                </ProForm>

            </Card>
        </PageContainer>

    )
}

export default EditPosConfiguration