import { useState } from "react"
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { Button, InputNumber, message } from "antd";
import { RootState } from "@/store";
import GET_MERCHANT_RATES from "@/features/pos-management/api/queries/get-merchant-rates";
import SAVE_MERCHANT_RATES from "@/features/pos-management/api/mutations/save-merchant-rates";
import BaseTable from "@/components/base/table"
import { bankLogos } from "@/components/financial-management/commisions/table/bankLogos"
import { RESPONSE_STATUS } from "@/config/constants";
import {useTranslation} from "react-i18next";



const MerchantRates = () => {
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const { error, loading, refetch } = useQuery(GET_MERCHANT_RATES, {
        variables: {
            merchantId: String(activeMerchantId)
        },
        onCompleted(data) {
            createColumns(data.getMerchantRates.result);
            generateRows(data.getMerchantRates.result);
        },
    });
    const [saveMerchantRates] = useMutation(SAVE_MERCHANT_RATES);

    const [dataSource, setDataSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const { t } = useTranslation()

    const handleInputNumberChange = (value: string, index: number, acquirerId: number) => {
        value = value === null ? '' : value.toString();
        setDataSource((prevDataSource: any) => {
            const newDataSource = [...prevDataSource];
            newDataSource[index][acquirerId] = value;
            return newDataSource;
        });
    };

    const ROW_TEMPLATE: any[] = [
        { type: "text", installment: "blockageDayNumberForSale", name: `${t("pos.sale.blockage.day")}` },
        { type: "text", installment: "blockageDayNumberForInstallmentSale", name: `${t("pos.installment.blockage.day")}` },
        { type: "text", installment: "delayBlockageDayNumberForInstallmentSale", name: `${t("pos.installment.delay.blockage.day")}` },
        { type: "text", installment: "onus_debit", name: `${t("pos.onus.debit")}` },
        { type: "text", installment: "onus_credit", name: `${t("pos.onus.credit")}` },
        { type: "text", installment: "domestic_debit", name: `${t("pos.domestic.debit")}` },
        { type: "text", installment: "domestic_credit", name: `${t("pos.domestic.credit")}` },
        { type: "text", installment: "international_debit", name: `${t("pos.international.debit")}` },
        { type: "text", installment: "international_credit", name: `${t("pos.international.credit")}` },
        { type: "text", installment: "domestic_amex_credit", name: `${t("pos.domestic.amex.credit")}` },
        { type: "text", installment: "international_amex_credit", name: `${t("pos.international.amex.credit")}` },
        { type: "number", installment: "2", name: "2 Taksit" },
        { type: "number", installment: "3", name: "3 Taksit" },
        { type: "number", installment: "4", name: "4 Taksit" },
        { type: "number", installment: "5", name: "5 Taksit" },
        { type: "number", installment: "6", name: "6 Taksit" },
        { type: "number", installment: "7", name: "7 Taksit" },
        { type: "number", installment: "8", name: "8 Taksit" },
        { type: "number", installment: "9", name: "9 Taksit" },
        { type: "number", installment: "10", name: "10 Taksit" },
        { type: "number", installment: "11", name: "11 Taksit" },
        { type: "number", installment: "12", name: "12 Taksit" },
    ];

    const createColumns = (data: any): void => {
        const staticColumns: any[] = [{
            title: '',
            dataIndex: 'name',
            key: 'installment',
            width: 50,
            fixed: 'left',
            align: 'center',
        }];

        const generatedColumns: any[] = data.map(({ loyaltyId, banks }: any) => ({
            title: !loyaltyId || bankLogos(loyaltyId),
            children: banks
                .map((bank: any) => ({
                    title: bank.bankName,
                    align: 'center',
                    dataIndex: bank.acquirerId,
                    key: bank.acquirerId,
                    render: (text: string, _record: any, index: number) => {
                        return <InputNumber
                            min={'0'}
                            value={text === '-' ? '' : text}
                            onChange={(value: any) => handleInputNumberChange(value, index, bank.acquirerId)}
                        />
                    }
                }))
        }))

        setColumns([...staticColumns, ...generatedColumns]);
    };

    const generateRows = (data: any): void => {
        const tableGeneratedData: any[] = [];

        ROW_TEMPLATE.forEach((row: any) => {
            const tableRow: any = { ...row };

            data.forEach((item: any) => {
                item.banks.forEach((bank: any) => {
                    tableRow[bank.acquirerId] = bank[row.installment] || bank.rates[row.installment] || '';
                })
            });

            tableGeneratedData.push(tableRow);
        });

        setDataSource(tableGeneratedData)
    };

    const validateData = (data: any): boolean => {
        const blockageDayNumberForSale = data.rates.find((rate: any) => rate.installment === 'blockageDayNumberForSale');

        const { installment, name, type, ...rest } = blockageDayNumberForSale;

        const isBlockageDayNumberForSaleEmpty = Object.values(rest).every((item: any) => item === '' || item === null);

        if (isBlockageDayNumberForSaleEmpty) {
            message.error('Blokaj günü boş bırakılamaz!');
            return false;
        }

        return true;
    };

    const handleSubmit = (): void => {
        const requestParams = {
            merchantId: Number(activeMerchantId),
            rates: dataSource
        };

        if (!validateData(requestParams)) return;

        saveMerchantRates({
            variables: requestParams
        }).then((response) => {
            const { status, message: responseMessage } = response.data.saveMerchantRates;
            if (status !== RESPONSE_STATUS.APPROVED) {
                throw new Error(responseMessage);
            }
            message.success(responseMessage);
            refetch();
        }).catch((error) => {
            message.error(error.message);
        });
    };

    if (error) return <p>Hata Oluştu!</p>;

    if (loading) return <p>Loading...</p>;

    const styleButtonPanel: any = { textAlign: "right", padding: "20px 40px", marginTop: 20, background: 'white', borderRadius: 3 }

    return (
        <>
            <BaseTable
                columns={columns}
                dataSource={dataSource}
                bordered
                search={false}
                pagination={false}
                options={false}
                rowKey="installment"
            />
            <div style={styleButtonPanel}>
                <Button onClick={handleSubmit}>{t("pos.save")}</Button>
            </div>
        </>

    )
}
export default MerchantRates