import { gql } from "@apollo/client";

const GET_MID_CONFIGURATION_DETAIL = gql`
    query getMidConfigurationDetail($merchantId: Int, $midId: Int) {
      getMidConfigurationDetail(merchantId: $merchantId, midId: $midId) {
        acquirer
        name
        descriptor
        midTested
        isTsp
        tester
        plusInstallment
        fromDateForPlusInstallment
        toDateForPlusInstallment
        acquirerReference {
            id
            code
            referenceNo
        }
      }
    }
`;

export default GET_MID_CONFIGURATION_DETAIL