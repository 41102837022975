import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import useAuth from "@/hooks/use-auth";
import { onError } from "@apollo/client/link/error";
import { useDispatch } from "react-redux";
import { removeAuthParams } from "@/features/auth/store/authSlice";

interface CustomApolloProviderProps {
  children: React.ReactNode;
}

const CustomApolloProvider = ({ children }: CustomApolloProviderProps) => {
  const { auth, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const logoutLink = onError((context) => {
    const { graphQLErrors } = context;
    // @ts-ignore
    if (graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
      dispatch(removeAuthParams());
      window.location.href = "/user/login";
    }
  });
  const httpLink = new HttpLink({
    uri: process.env["REACT_APP_NODE_API"],
    ...(isAuthenticated && {
      headers: {
        authorization: typeof auth.token === "string" ? auth.token : "",
      },
    }),
  });
  const client = new ApolloClient({
    link: logoutLink.concat(httpLink),
    cache: new InMemoryCache(),
    queryDeduplication: false,
    connectToDevTools: true
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default CustomApolloProvider;
