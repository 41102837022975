const others = {
  "navBar.lang": "Languages",
  "layout.user.link.help": "Help",
  "layout.user.link.privacy": "Privacy",
  "layout.user.link.terms": "Terms",
  "app.preview.down.block": "Download this page to your local project",
  "app.welcome.link.fetch-blocks": "Get all block",
  "app.welcome.link.block-list":
    "Quickly build standard, pages based on `block` development",
};

export default others
