import { useEffect, useState } from "react";
import { Button, Card, InputNumber } from "antd";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import BaseTable from "@/components/base/table";
import { useTranslation } from "react-i18next";


const TypeInstallmentSale = ({ data, receivedValues }: any) => {
    const [rates, setRates] = useState(data.rates);
    const [blockageDayNumber, setBlockageDayNumber] = useState(data.blockageDayNumber);

    const { t } = useTranslation();

    const handleAddRate = () => {
        setRates((currentRates: any) => {
            return [...currentRates, { installmentNumber: 1, rate: 1 }];
        });
    }

    const handleDeleteRate = (index: number) => {
        setRates((currentRates: any) => {
            return currentRates.filter((_: any, i: number) => i !== index);
        });
    }

    const handleChangeRate = (index: number, key: string, value: any) => {
        setRates((currentRates: any) => currentRates.map((rate: any, i: number) => {
            return i === index ? { ...rate, [key]: value } : rate;
        }));
    }

    useEffect(() => {
        receivedValues((currentValues: any) => {
            return {
                ...currentValues,
                installment_sale: {
                    rates,
                    blockageDayNumberForInstallmentSale: blockageDayNumber
                }
            }
        });
    }, [rates, blockageDayNumber, receivedValues])

    const columns = [
        {
            title: t("pos.blockageDay"),
            dataIndex: 'title',
            key: 'title',
            align: "center",
            children: [
                {
                    title: <Button type={"primary"} onClick={handleAddRate}><PlusOutlined /> {t("pos.add.new.installment.rate")}</Button>,
                    dataIndex: 'button',
                    align: 'right',
                    render: (_: string, record: any, index: number) => {
                        return <Button
                            style={{ border: "1px solid #cd3d64", color: "#cd3d64", boxShadow: "none" }}
                            onClick={() => handleDeleteRate(index)}
                            danger>
                            <CloseCircleOutlined />
                        </Button>;
                    },
                },
            ]
        },
        {
            title: <InputNumber
                style={{ width: "100%" }}
                value={blockageDayNumber}
                onChange={(value: any) => setBlockageDayNumber(value)}
                min={1}
            />,
            children: [
                {
                    title: t("pos.installment.number"),
                    dataIndex: 'installmentNumber',
                    align: 'center',
                    width: '33%',
                    render: (_: string, record: any, index: number) => {
                        return <InputNumber
                            value={record.installmentNumber}
                            onChange={(value: any) => handleChangeRate(index, 'installmentNumber', value)}
                        />
                    }
                },
                {
                    title: t("pos.rate"),
                    dataIndex: 'rate',
                    align: 'center',
                    width: '33%',
                    render: (_: string, record: any, index: number) => {
                        return <InputNumber
                            value={record.rate}
                            onChange={(value: any) => handleChangeRate(index, 'rate', value)}
                        />
                    }
                }
            ]
        }
    ];

    return (
        <Card title={t("pos.rates.for.transaction.type.installment.sale")} style={{ marginTop: "15px" }}>
            <BaseTable
                columns={columns}
                dataSource={rates}
                bordered
                search={false}
                pagination={false}
                options={false}
            />

        </Card>
    )
}

export default TypeInstallmentSale;