import { gql } from "@apollo/client";

const REFUND = gql `
    mutation Mutation($amount: Float!, $currency: String, $merchantId: Int!, $referenceNo: String!, $transactionId: String!, $apiKey: String) {
      refund(amount: $amount, currency: $currency, merchantId: $merchantId, referenceNo: $referenceNo, transactionId: $transactionId, apiKey: $apiKey) {
        amount
        amountProcessed
        code
        currency
        date
        descriptor
        isLive
        message
        operation
        referenceNo
        status
        transactionId
        type
      }
    }
`

export default REFUND