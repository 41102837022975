import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/store";
import { setAuthParams } from "@/features/auth/store/authSlice";
import "@/components/header/right/submerchant-dropdown/index.less";
import React from 'react';
import { TreeSelect } from 'antd';


const SubMerchantDropdown = () => {
  const { merchantId, subMerchants } = useSelector((state: RootState) => state.auth.params);
  const dispatch = useDispatch();

    const renderTreeNodes = (data: any) =>
        data.map((item: any) => {
            if (item.subMerchants) {
                return (
                    <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id} disabled={item.disabled}>
                        {renderTreeNodes(item.subMerchants)}
                    </TreeSelect.TreeNode>
                );
            }
            return <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id} disabled={item.disabled} />;
        });

  const handleOk = (id: number) => {
      dispatch(
      setAuthParams({
        merchantId: id,
        activeMerchantId: id,
      })
    );
      setTimeout(() => {
          window.location.reload();
      }, 500)

  };

  return (
    <div style={{padding: "0 15px"}}>
        <TreeSelect
            style={{ width: 300 }}
            value={merchantId ? merchantId : 0}
            onChange={handleOk}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeDefaultExpandAll
        >
            {renderTreeNodes(subMerchants)}
        </TreeSelect>
    </div>
  );
};

export default SubMerchantDropdown;
