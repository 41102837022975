import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "@/store";
import router from "@/routes";
import CustomApolloProvider from "@/providers/custom-apollo-provider";
import trTR from "antd/es/locale/tr_TR";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "@/store";
import "@/lib/i18n";

const AppProvider = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomApolloProvider>
          <ConfigProvider locale={trTR}>
            <RouterProvider router={router} />
          </ConfigProvider>
        </CustomApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppProvider;
