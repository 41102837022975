import { useTranslation } from "react-i18next";
import { Descriptions, Space, Typography, Divider } from "antd";

const { Title } = Typography;

const CardDetails = ({ data, customerInfoData }: any) => {
  const { t } = useTranslation();
  const { billingFirstName, billingLastName, number } = data.customerInfo || {};
  const { cardType } = data.customerInfo.bin;
  const { bankName, brand, cardCategory, countryCode, currencyCode, domesticIntl, loyalty } = customerInfoData.bin;
  return (
    <Descriptions
      title={
        <Space
          direction={"vertical"}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Title level={4}>{t("transaction.cardDetails")}</Title>
          <Divider style={{ margin: "0 0 4px 0" }} />
        </Space>
      }
      labelStyle={{ fontWeight: "bold" }}
    >
      <Descriptions.Item label={t("transaction.cardDetails")}>
        {`${billingFirstName} ${billingLastName}`}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.cardNumber")}>
        {number}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.cardType")}>
        {cardType || "-"}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.bankName")}>
        {bankName}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.brand")}>
        {brand}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.domesticIntl")}>
        {t(`transaction.domesticIntl.${domesticIntl}`)}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.countryCode")}>
        {countryCode}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.currencyCode")}>
        {currencyCode}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.cardCategory")}>
        {cardCategory}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.cardLoyalty")}>
        {loyalty}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CardDetails;
