import WeeklyPreview from "@/features/dashboard/components/stats/weekly-preview";
import VirtualPosStatistics from '@/features/dashboard/components/stats/virtual-pos-stats';
import PhysicalPosStats from "@/features/dashboard/components/stats/physical-pos-stats";
import useAuthorization from "@/hooks/use-authorization";
import {useEffect, useState} from "react";
import PERMISSIONS from "@/config/permissions";

const Welcome = () => {
  const { checkPermission } = useAuthorization();
  const [isVisibleWeeklyPreview, setIsVisibleWeeklyPreview] = useState(false);
  const [isVisibleVirtualPosStats, setIsVisibleVirtualPosStats] = useState(false);
  const [isVisiblePhysicalPosStats, setIsVisiblePhysicalPosStats] = useState(false);

  useEffect(() => {
      checkPermissions();
  }, []);

  const checkPermissions = async () => {
     //const permissions = [PERMISSIONS.dashboard.weekly_report.show, PERMISSIONS.dashboard.vpos_report.show, PERMISSIONS.dashboard.phypos_report.show];
     //const [weeklyPreview, virtualPosStats, physicalPosStats] = await checkPermission(permissions);
     setIsVisibleWeeklyPreview(true);
     setIsVisibleVirtualPosStats(true);
     setIsVisiblePhysicalPosStats(true);
  }

  return (
    <>
        {isVisibleWeeklyPreview && <WeeklyPreview />}
        {isVisibleVirtualPosStats && <VirtualPosStatistics isResponsive={false} />}
        {isVisiblePhysicalPosStats && <PhysicalPosStats isResponsive={false} />}
    </>
  );
};

export default Welcome;
