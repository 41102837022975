import { gql } from "@apollo/client";

const GET_COMMISSIONS = gql`
    query($merchantId: String!) {
      getCommissions(merchantId: $merchantId) {
          code
          installmentRateList {
            loyaltyId
            name
            rate
          }
          message
          status
            oneTime {
                amex_credit
                blockageDayNumber
                domestic_credit
                domestic_debit
                international_credit
                international_debit
                onus_credit
                onus_debit
            }
        }
    }
`;

export default GET_COMMISSIONS