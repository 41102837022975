const pages = {
  "pages.layouts.userLayout.title": "header title",
  "pages.login.accountLogin.tab": "Account Login",
  "pages.login.accountLogin.errorMessage":
    "Giriş Başarısız. Lütfen Tekrar Deneyin.",
  "pages.login.username.placeholder": "E-Posta Adresi:",
  "pages.login.username.required": "Lütfen E-Posta adresinizi girin!",
  "pages.login.password.placeholder": "Şifre:",
  "pages.login.password.required": "Lütfen şifrenizi girin!",
  "pages.login.phoneLogin.tab": "Phone Login",
  "pages.login.phoneLogin.errorMessage": "Verification Code Error",
  "pages.login.phoneNumber.placeholder": "Phone Number",
  "pages.login.phoneNumber.required": "Please input your phone number!",
  "pages.login.phoneNumber.invalid": "Phone number is invalid!",
  "pages.login.captcha.placeholder": "Verification Code",
  "pages.login.captcha.required": "Please input verification code!",
  "pages.login.phoneLogin.getVerificationCode": "Get Code",
  "pages.getCaptchaSecondText": "sec(s)",
  "pages.login.rememberMe": "Remember me",
  "pages.login.forgotPassword": "Forgot Password ?",
  "pages.login.submit": "Submit",
  "pages.login.loginWith": "Login with :",
  "pages.login.registerAccount": "Register Account",
  "pages.welcome.advancedComponent": "Advanced Component",
  "pages.welcome.link": "Welcome",
  "pages.welcome.advancedLayout": "Advanced Layout",
  "pages.welcome.alertMessage":
    "Faster and stronger heavy-duty components have been released.",
  "pages.admin.subPage.title": "This page can only be viewed by Admin",
  "pages.admin.subPage.alertMessage":
    "Umi ui is now released, welcome to use npm run ui to start the experience.",
  "pages.searchTable.createForm.newRule": "New Rule",
  "pages.searchTable.updateForm.ruleConfig": "Rule configuration",
  "pages.searchTable.updateForm.basicConfig": "Basic Information",
  "pages.searchTable.updateForm.ruleName.nameLabel": "Rule Name",
  "pages.searchTable.updateForm.ruleName.nameRules":
    "Please enter the rule name!",
  "pages.searchTable.updateForm.ruleDesc.descLabel": "Rule Description",
  "pages.searchTable.updateForm.ruleDesc.descPlaceholder":
    "Please enter at least five characters",
  "pages.searchTable.updateForm.ruleDesc.descRules":
    "Please enter a rule description of at least five characters!",
  "pages.searchTable.updateForm.ruleProps.title": "Configure Properties",
  "pages.searchTable.updateForm.object": "Monitoring Object",
  "pages.searchTable.updateForm.ruleProps.templateLabel": "Rule Template",
  "pages.searchTable.updateForm.ruleProps.typeLabel": "Rule Type",
  "pages.searchTable.updateForm.schedulingPeriod.title":
    "Set Scheduling Period",
  "pages.searchTable.updateForm.schedulingPeriod.timeLabel": "Starting Time",
  "pages.searchTable.updateForm.schedulingPeriod.timeRules":
    "Please choose a start time!",
  "pages.searchTable.titleDesc": "Description",
  "pages.searchTable.ruleName": "Rule name is required",
  "pages.searchTable.titleCallNo": "Number of Service Calls",
  "pages.searchTable.titleStatus": "Status",
  "pages.searchTable.nameStatus.default": "default",
  "pages.searchTable.nameStatus.running": "running",
  "pages.searchTable.nameStatus.online": "online",
  "pages.searchTable.nameStatus.abnormal": "abnormal",
  "pages.searchTable.titleUpdatedAt": "Last Scheduled at",
  "pages.searchTable.exception": "Please enter the reason for the exception!",
  "pages.searchTable.titleOption": "Option",
  "pages.searchTable.config": "Configuration",
  "pages.searchTable.subscribeAlert": "Subscribe to alerts",
  "pages.searchTable.title": "Enquiry Form",
  "pages.searchTable.new": "New",
  "pages.searchTable.chosen": "chosen",
  "pages.searchTable.item": "item",
  "pages.searchTable.totalServiceCalls": "Total Number of Service Calls",
  "pages.searchTable.tenThousand": "0000",
  "pages.searchTable.batchDeletion": "bacth deletion",
  "pages.searchTable.batchApproval": "batch approval",
  "pages.tumIslemler.islemNo": "İşlem No",
  "pages.tumIslemler.islemTarihi": "İşlem Tarihi",
  "pages.tumIslemler.tutar": "Tutar",
  "pages.tumIslemler.odemeId": "Ödeme ID",
  "pages.tumIslemler.islemStatusu": "İşem Statüsü",
  "pages.tumIslemler.odemeTipi": "Ödeme Tipi",
  "pages.tumIslemler.adiSoyadi": "Adı Soyadı",
  "pages.tumIslemler.kartNumarasi": "Kart Numarası",
  "pages.tumIslemler.iptalIadeStatusu": "İptal/İade",
  "pages.resetPassword.email.placeholder": "Lütfen E-Posta Adresinizi Giriniz.",
  "pages.resetPassword.email.required":
    "Geçerli Bir E-Posta Adresi Gereklidir.",
  "pages.resetPassword.email": "E-Posta",
  "pages.resetPassword.success":
    "Şifre sıfırlama linki e-posta adresinize gönderildi.",
  "pages.resetPassword.check.email": "Lütfen E-Postanızı kontrol edin.",
  "pages.resetPassword.login.button": "Giriş Yap",
  "pages.resetPassword.reset.password": "Şifremi Sıfırla",
  "pages.password.confirm.password": "Lütfen şifrenizi onaylayın!",
  "pages.password.not.match": "Girdiğiniz iki şifre eşleşmiyor!",
  "pages.password.confirm.password.placeholder": "Şifre Doğrulama:",
  "pages.password.create.password": "Şifre Oluştur",
  "pages.password.changePassword.failed": "Bir hata meydana geldi. ",
  "pages.password.changePassword.failed.subtitle":
    "Lütfen bilgilerinizi kontrol edip, yeni bir istekte bulunun.",
  "pages.password.changePassword.failed.try.again": "Tekrar Deneyin",
};

export default pages