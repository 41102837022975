import { gql } from "@apollo/client";

const UPDATE_MERCHANT_USER_PASSWORD = gql`
  mutation ($id: Int!, $merchantId: String!, $password: String!) {
    updateMerchantUserPassword(
      id: $id
      merchantId: $merchantId
      password: $password
    ) {
      message
      status
    }
  }
`;

export default UPDATE_MERCHANT_USER_PASSWORD;
