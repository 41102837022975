import { gql } from "@apollo/client";

const EXPORT_SETTLEMENTS_CSV = gql`
  mutation exportSettlementsCSV($valorFromDate: String!, $valorToDate: String!, $merchantId: String!, $userRole: String!, $customerExportEmail: String!, $exportType: String!, $selectedFields: String, $status: String) {
  exportSettlementsCSV(valorFromDate: $valorFromDate, valorToDate: $valorToDate, merchantId: $merchantId, userRole: $userRole, customerExportEmail: $customerExportEmail, exportType: $exportType, selectedFields: $selectedFields, status: $status) {
    response
    status
  }
}
`;

export default EXPORT_SETTLEMENTS_CSV;
