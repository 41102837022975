const menu = {
  "menu.ana-sayfa": "Ana sayfa",
  "menu.islemler": "İşlemler",
  "menu.islemler.tum-islemler": "Tüm İşlemler",
  "menu.islemler.tum-islemler.detay": "Detay",
  "menu.islemler.iptal-iade": "İptal/İade",
  "menu.islemler.iptal-iade.detay": "Detay",
  "menu.islemler.harcama-itirazlari": "Harcama İtirazları",
  "menu.profil": "Profil",
  "menu.ayarlar": "Ayarlar",
  "menu.ayarlar.firma-ayarlari": "Firma Ayarları",
  "menu.ayarlar.kullanicilar": "Kullanıcılar",
  "menu.link-ile-odeme": "Link ile Ödeme",
  "menu.finansal-yonetim": "Finansal Yönetim",
  "menu.finansal-yonetim.tamamlanan-odemeler": "Ödemeler",
  "menu.finansal-yonetim.bekleyen-odemeler": "Bekleyen Ödemeler",
  "menu.finansal-yonetim.hesap-dokumu": "Hesap Dökümü",
  "menu.finansal-yonetim.komisyonlar": "Komisyonlar",
  "menu.eklentiler": "Eklentiler",
  "menu.hideMenu": "Menüyü Gizle",
  "menu.islemler.fiziki-pos-islemleri": "Fiziki POS İşlemleri",
};

export default menu
