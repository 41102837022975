const posManagement = {
    "pos.midList": "Mid List",
    "pos.midProfile": "POS Profiles",
    "pos.midProfileGroupName": "Mid Group Name",
    "pos.selectMidProfile": "Select mid group name",
    "pos.mid": "MID",
    "pos.acquirer": "Acquirer",
    "pos.default": "Default",
    "pos.notDefault": "Not Default",
    "pos.createDate": "Create Date",
    "pos.updateDate": "Update Date",
    "pos.blockageDay": "Blockage Day",
    "pos.search": "Search",
    "pos.clear": "Clear",
    "pos.description": "Description",
    "pos.blockageDay.sale.installment": "BLOCKAGE DAY (SALE / INSTALLMENT SALE)",
    "pos.edit": "Edit",
    "pos.delete": "Delete",
    "pos.select": "Please Select",
    "pos.midRates": "Mid Rates",
    "pos.merchantRates": "Merchant Rates",
    "pos.sale.blockage.day": "Sale Blockage Day",
    "pos.installment.blockage.day": "Installment Blockage Day",
    "pos.installment.delay.blockage.day": "Installment Blockage Day",
    "pos.onus.debit": "Onus Debit Cart",
    "pos.onus.credit": "Onus Credit Cart",
    "pos.domestic.debit": "Domestic Debit",
    "pos.domestic.credit": "Domestic Credit",
    "pos.international.debit": "International Debit",
    "pos.international.credit": "International Credit",
    "pos.domestic.amex.credit": "Domestic Amex Credit",
    "pos.international.amex.credit": "Domestic Amex Credit",
    "pos.rates.for.transaction.type.sale": "Rates for Transaction Type Sale",
    "pos.rates.for.transaction.type.installment.sale": "Rates for Transaction Type Installment Sale",
    "pos.add.new.installment.rate": "ADD NEW INSTALLMENT RATE",
    "pos.installment.number": "INSTALLMENT NUMBER",
    "pos.installment": "INSTALLMENT",
    "pos.rate": "Rate",
    "pos.create.mid": "Create Mid",
    "pos.create.midProfile": "Create Mid Profile",
    "pos.create.mid.configuration": "Create Mid Configuration",
    "pos.edit.mid.profile.configuration": "Edit Mid Profile",
    "pos.create.mid.profile.configuration": "Create Mid Profile",
    "pos.mid.tested": "MID tested?",
    "pos.name": "MID Name",
    "pos.tester": "Tester",
    "pos.mid.description": "MID Description",
    "pos.bank.credentials": "Bank Credentials",
    "pos.date.range.apply.plus.installment": "Date Range to apply plus installment",
    "pos.remove": "Remove",
    "pos.add.new.bank.credential": "Add New Bank Credentials",
    "pos.save": "Save",

    "pos.profile.name": "POS Profile Group Name",
    "pos.assigned.poses": "Poses assigned to this group",
}

export default posManagement;