import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { RootState } from "@/store";
import { Descriptions, Divider, Skeleton } from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import GET_MERCHANT_USER from "@/features/user/api/get-merchant-user";

const Profile = () => {
  const { activeMerchantId, merchantUserId } = useSelector(
    (state: RootState) => state.auth.params
  );
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(GET_MERCHANT_USER, {
    variables: {
      merchantId: String(activeMerchantId),
      merchantUserId: String(merchantUserId),
    },
  });

  const [merchantUser, setMerchantUser] = useState({
    name: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    if (data) {
      const { name, email, role } = data.getCurrentMerchantUser?.merchantUser;
      setMerchantUser({ name, email, role });
    }
  }, [data]);


  const labelStyle = {
    fontWeight: "bold",
  };

  if (error) return <p>Hata</p>;

  if (loading) return <p>Loading</p>;
  

  return (
    <PageContainer>
      <Divider />
      <Skeleton loading={loading} active={true}>
        <Descriptions title={t("form.title")}>
          <Descriptions.Item labelStyle={labelStyle} label={t("form.name")}>
            {merchantUser?.name}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={labelStyle} label={t("form.email")}>
            {merchantUser?.email}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={labelStyle} label={t("form.role")}>
            {merchantUser?.role}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
      </Skeleton>
    </PageContainer>
  );
};

export default Profile;
