import {useParams} from "react-router-dom";
import "@/layouts/checkout/index.less";
import {Input, Card, Form, Col, Row, Select, InputNumber, Button} from 'antd';
import TextArea from "antd/es/input/TextArea";
import {RESPONSE_STATUS} from "@/config/constants";
import {useEffect} from "react";

const CustomPayment = () => {
    const {id, lang} = useParams();
    const columnNames = {
        "payment.nameSurname": {
            tr: "İsim Soyisim:",
            en: "Name Surname:"
        },
        "payment.emailAddress": {
            tr: "E-posta Adresi:",
            en: "Email Address:"
        },
        "payment.desc": {
            tr: "Açıklama:",
            en: "Description:"
        },
        "payment.phoneNumber": {
            tr:"Telefon Numarası:",
            en:"Phone Number:"
        },
        "payment.address": {
            tr:"Adres:",
            en:"Address:"
        },
        "payment.address2": {
            tr:"Adres 2:",
            en:"Address 2:"
        },
        "payment.country": {
            tr: "Ülke:",
            en: "Country:"
        },
        "payment.city": {
            tr: "Şehir:",
            en: "City:"
        },
        "payment.postCode": {
            tr: "Posta Kodu:",
            en: "Post Code:"
        },
        "payment.price": {
            tr: "Fiyat:",
            en: "Price:"
        },
        "payment.piece": {
            tr: "Adet:",
            en: "Quantity"
        },
        "payment.pay": {
            tr: "Öde",
            en: "Pay"
        },
        "payment.cargo": {
            tr: "Kargo Bilgileri",
            en: "Cargo Informations"
        },
        "payment.info": {
            tr: "Ödeme Bilgileri",
            en: "Payment Informations"
        },
        "payment.totalPrice": {
            tr: "Ödenecek Tutar",
            en: "Total Price"
        }
    }
    const isValidControl = async () => {
        if (typeof id === "string") {
            console.log(encodeURI(id));
            const response = await fetch(`${process.env.REACT_APP_REST_API}/pay-to-link/custom-payment?id=${encodeURIComponent(id)}`);
            const data = await response.json();
            if (data.status !== RESPONSE_STATUS.APPROVED) {
                window.history.back();
            }
        }
    }

    useEffect(() => {
        isValidControl();
    }, []);

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const initialValues = {
        name: "",
        email: "",
        description: "",
        price: "",
        amount: "",
        currency: ""
    }

    const onFinish = async (values: any) => {
        const { description, email, name, price, currency} = values;
        const splitName = name.split(" ");
        const length = splitName.length;
        const billingFirstName = splitName.slice(0, length - 1).join(" ");
        const billingLastName = splitName[splitName.length - 1];
        const params = {
            id,
            billingFirstName,
            billingLastName,
            email,
            description,
            amount: parseFloat(`${price.amount}.${price.fraction}`),
            currency: price.currency,
            language: lang,
            returnUrl: window.location.origin.toString()+"/checkout/custom-payment-response?id="+id+"%26lang="+lang
        }
        const response = await fetch(`${process.env.REACT_APP_REST_API}/pay-to-link/custom-payment/init`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(params)
            });
        const data = await response.json();
        if (data) {
            const { purchaseUrl } = data;
            window.location.href = purchaseUrl;
        }
    };


    return (
        <div className="page-content checkout-container">
            <Form
                onFinish={onFinish}
                validateMessages={validateMessages}
                labelAlign="left"
                autoComplete={"off"}
                initialValues={initialValues}
                layout={"vertical"}>
                <Row aria-colcount={24} gutter={30}>
                    <Col span={12}>
                        {/*@ts-ignore*/}
                        <Card title={columnNames["payment.info"][lang]}>
                            {/*@ts-ignore*/}
                            <Form.Item name='name' label={columnNames["payment.nameSurname"][lang]} rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            {/*@ts-ignore*/}
                            <Form.Item name='email' label={columnNames["payment.emailAddress"][lang]} rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            {/*@ts-ignore*/}
                            <Form.Item name='description' label={columnNames["payment.desc"][lang]} rules={[{required: true}]}>
                                <TextArea/>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        {/*@ts-ignore*/}
                        <Card title={columnNames["payment.totalPrice"][lang]}>
                            {/*@ts-ignore*/}
                            <Form.Item label={columnNames["payment.price"][lang]}>
                                <Input.Group compact>
                                    <Form.Item noStyle name={['price', 'amount']} rules={[{ required: true }]}>
                                        <InputNumber min={0} controls={false}
                                                     style={{ width: '25%', marginRight: "5px" }} placeholder="0" />
                                    </Form.Item>
                                    .
                                    <Form.Item noStyle name={['price', 'fraction']} rules={[{ required: true }]}>
                                        <InputNumber<string> stringMode min={"00"} max={"99"} minLength={2}
                                                             controls={false}
                                                             style={{ width: '25%', marginLeft: "5px" }}
                                                             placeholder="00"
                                        />
                                    </Form.Item>

                                    <span style={{ padding: "0 10px" }} />
                                    <Form.Item noStyle name={['price', 'currency']}  initialValue={"TRY"}>
                                        <Select style={{ width: '25%' }}>
                                            <Select.Option value="TRY">TRY</Select.Option>
                                            <Select.Option value="EUR">EUR</Select.Option>
                                            <Select.Option value="USD">USD</Select.Option>
                                            <Select.Option value="GBP">GBP</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        </Card>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="payment-button">
                                {/*@ts-ignore*/}
                                {columnNames["payment.pay"][lang]}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CustomPayment