const form = {
  "form.title": "User Information",
  "form.name": "Name Surname",
  "form.email": "Email",
  "form.role": "Role",
  "form.user": "User",
  "form.admin": "Admin",
  "form.changePassword": "Change Password",
  "form.password": "Password",
  "form.passwordConfirm": "Confirm Password",
  "form.message.general": "Please fill this area",
  "form.message.email": "Please input your email",
  "form.message.password": "Please input your password",
  "form.error.passwordMatch":
    "The two passwords that you entered do not match!",
  "form.error.minLength": "At least 6 character",
  "form.submit": "Submit",
  "form.profile": "Profile",
  "form.signout": "Sign out",
  "form.error.weakPassword":
    "Password length must be greater than 8 and include at least 3 lowercase letter, 2 uppercase letter, 2 number and 1 special character.",
};

export default form
