import { Typography, Descriptions, Space, Divider, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { findTransactionStatusColor } from "@/utils/transaction";
import { formatAmount, amountText } from "@/utils/finance";
import { getResponseDescription } from "@/utils/transaction/responseCodes";

const { Title } = Typography;

const PaymentDetails = ({ data, valorDateData }: any) => {
  const { t } = useTranslation();
  const {
    status,
    code,
    updated_at,
    transactionId,
    referenceNo,
    installment,
    operation,
  } = data.transaction.merchant;
  const { originalAmount, originalCurrency, commission } = data.fx.merchant;
  const { provisionNo, mdStatus } = data.transaction.acquirer || "-";
  const { cardType } = data.customerInfo.bin;
  const convertedCommission = amountText(
    parseInt(commission),
    originalCurrency
  );

  const renderValorDates = () => {
    if (!valorDateData || valorDateData.length === 0) return '-';
    return (
      <ol style={{ listStyleType: 'decimal' }} >
        {valorDateData.map((date: string) => (<li key={date}>{date}</li>))}
      </ol>
    ) 
  };

  const findMdStatusType = () => {
    let mdStatusText = '';
    switch (operation) {
      case '3DAUTH':
      case '3D':
        if (mdStatus && [2, 3, 4].includes(mdStatus)) {
          mdStatusText = 'HALF SECURE';
        } else {
          mdStatusText = 'FULL SECURE';
        }
        break;
      case 'DIRECT':
        mdStatusText = 'NON SECURE';
        break;
      default:
        mdStatusText = 'NON SECURE';
        break;
    }

    return mdStatus ? `${mdStatusText} (${mdStatus})` : mdStatusText;
  };

  return (
      <>
        <Descriptions
            title={
              <Space
                  direction={"vertical"}
                  style={{ width: "100%", marginTop: "16px" }}
              >
                <Title level={4}>{t("transaction.paymentDetails")}</Title>
                <Divider style={{ margin: "0 0 4px 0" }} />
              </Space>
            }
            labelStyle={{ fontWeight: "bold" }}
        >
          <Descriptions.Item label={t("transaction.status")}>
            <Tag color={findTransactionStatusColor(status)}>
              {t(`transaction.status.${status.toLowerCase()}`)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.responseDescription")}>
            {getResponseDescription(code)}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.date")}>
            {updated_at}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.amount")}>
            {formatAmount(originalAmount, originalCurrency)}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.paymentType")}>
            {cardType || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.paymentId")}>
            {transactionId}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.paymentSource")}>
            {data.transaction.merchant.doneBy
                ? data.transaction.merchant.doneBy.channel
                : data.transaction.merchant.channel}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.provisionNo")}>
            {provisionNo}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.referenceNo")}>
            {referenceNo}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.merchantName")}>
            {data.merchant.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.installment")}>
            {installment}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.type")}>
            {t(`transaction.operation.${operation}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.mdStatus")}>
            {findMdStatusType()}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.commission")}>
            {convertedCommission}
          </Descriptions.Item>
          <Descriptions.Item label={t("transaction.valorDate")}>
            {renderValorDates()}
          </Descriptions.Item>
        </Descriptions>
      </>
  );
};

export default PaymentDetails;
