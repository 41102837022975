import React from "react";
import { removeAuthParams } from "@/features/auth/store/authSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "@/hooks/use-auth";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Menu } from "antd";
import HeaderDropdown from "@/components/header/right/header-dropdown";
import LoadingIndicator from "@/components/base/loading-indicator";
import "@/components/header/index.less";

const HeaderAvatar = ({ menu }: any) => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Dropdown = () => {
    const menuHeaderDropdown = (
      <Menu className="menu" selectedKeys={[]}>
        {menu && <Menu.Divider />}
        <Menu.Item key="profile">
          <UserOutlined />
          <Link to={"/user/profile"}>{t("form.profile")}</Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <LogoutOutlined />
          <Link
            to={{
              pathname: "/user/login",
              search: "?redirect=" + window.location.href,
            }}
            onClick={() => dispatch(removeAuthParams())}
          >
            {t("form.signout")}
          </Link>
        </Menu.Item>
      </Menu>
    );

    return (
      <HeaderDropdown overlay={menuHeaderDropdown} trigger={["click"]}>
        <span className="action account">
          <Avatar size="small" icon={<UserOutlined />} />
        </span>
      </HeaderDropdown>
    );
  };

  if (isAuthenticated) {
    return <Dropdown />;
  }
  return <LoadingIndicator />;
};

export default HeaderAvatar;
