import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import GET_COMMISSIONS from "@/features/financial-management/api/queries/get-commissions";
import {RootState} from "@/store";
import {PageContainer} from "@ant-design/pro-layout";
import BaseTable from "@/components/base/table";

import {bankLogos} from '@/components/financial-management/commisions/table/bankLogos'
import ActionButtons from "@/components/financial-management/commisions/table/action-buttons";
import TopInfo from "@/components/financial-management/commisions/table/top-info";
import CommissionInput from "@/components/financial-management/commisions/table/commission-input";
import SAVE_INSTALLMENT_RATES from "@/features/financial-management/api/mutations/save-installment-rates";
import {RESPONSE_STATUS} from "@/config/constants";
import useAuthorization from "@/hooks/use-authorization";
import PERMISSIONS from "@/config/permissions";


const Commissions = () => {
    const [generatedColumns, setGeneratedColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [history, setHistory] = useState([]);
    const [isVisibleEditCommissions, setIsVisibleEditCommissions] = useState(false);
    const {activeMerchantId} = useSelector((state: RootState) => state.auth.params);
    const [saveInstallmentRates] = useMutation(SAVE_INSTALLMENT_RATES);
    const {data, error, loading, refetch} = useQuery(GET_COMMISSIONS, {
        variables: {
            merchantId: String(activeMerchantId)
        }
    });

    const {t} = useTranslation();
    const {checkPermission} = useAuthorization();

    const checkPermissions = async () => {
        //const canEditCommissions = await checkPermission(PERMISSIONS.financialManagement.commissions.edit);
        setIsVisibleEditCommissions(true);
    }

    useEffect(() => {
        checkPermissions();
    }, []);

    const createColumns = () => {
        const columns: any = [
            {
                title: t('financialManagement.installment'),
                dataIndex: 'installment',
                key: 'installment',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (val: string) => <div style={{textAlign: 'center'}}>{val}</div>,
            }
        ];
        data?.getCommissions.installmentRateList.forEach((value: any) => {
            columns.push(
                {
                    title: bankLogos(value.loyaltyId),
                    hideInSearch: true,
                    align: 'center',
                    children: [{
                        title: t("financialManagement.interestRate"),
                        dataIndex: `interestRate_${value.loyaltyId}`,
                        key: `interestRate_${value.loyaltyId}`,
                        hideInSearch: true,
                        align: 'center',
                        render: (intRate: any) => {
                            return intRate && intRate !== '-' ? `%${intRate}` : "-";
                        },
                    },
                        {
                            title: t("financialManagement.buyerCost"),
                            dataIndex: `buyerCostInterestRate_${value.loyaltyId}`,
                            key: `buyerCostInterestRate_${value.loyaltyId}`,
                            hideInSearch: true,
                            align: 'center',
                            render: (buyerCostRate: any, row: any) => {
                                const interestRate = value.rate[row.installment]?.interestRate;
                                return(<CommissionInput onChangeCommision={(val:any) => updateCommission(value.loyaltyId, row.installment, val)} buyerCostRate={buyerCostRate} interestRate={interestRate}/>);
                            }
                        }
                    ]
                }
            )
        });
        setGeneratedColumns(columns);
    }

    const updateCommission = (loyaltyId: string, installment: any, val:any) => {
        const tempTableData = [...tableData];
        const selectedIndex = tempTableData.findIndex((item: any) => item.installment == installment);
        // @ts-ignore
        tempTableData[selectedIndex][`buyerCostInterestRate_${loyaltyId}`] = val;
        setTableData(tempTableData);
    }


    const generateRows = () => {
        const tableGeneratedData: any = [];
        const rateKeys = Object.keys(data?.getCommissions.installmentRateList[0].rate)

        rateKeys.forEach(rateKey => {
            let result : any = {key: rateKey, installment: rateKey}

            data?.getCommissions.installmentRateList.forEach(({loyaltyId, rate}: any) => {
                result[`interestRate_${loyaltyId}`] = rate[rateKey]?.interestRate;
                result[`buyerCostInterestRate_${loyaltyId}`] = rate[rateKey]?.buyerCostInterestRate;
            })

            tableGeneratedData.push(result);
        })
        setTableData(tableGeneratedData);
        const tableDataCopy = JSON.parse(JSON.stringify(tableGeneratedData));
        setHistory(tableDataCopy);
    }

    const changeAllInterestRateClick = () => {
        const rates = ["", "002", "003", "004","005", "006", "007","008", "009", "010"];
        const newTableData = tableData.map((item) => {
            rates.forEach((rate) => {
                const selectedInterestRate = item[`interestRate_${rate}`];
                const automatedInterestRate = Number((100 * selectedInterestRate) / (100 - selectedInterestRate)).toFixed(
                    2,
                );
                // @ts-ignore
                item[`buyerCostInterestRate_${rate}`] = String(automatedInterestRate);
            });
            return item;
        });
        setTableData(newTableData);
    }

    useEffect(() => {
        if(data){
            generateRows();
            createColumns();
        }
    }, [data])

    useEffect(() => {
        if (tableData) {
            createColumns();
        }
    },[tableData]);

    const undoChanges = () => {
        setTableData(history);
    }

    const save = () => {
        let updatedFields: any[] = [];
        history.forEach((item: any, index) => {
            const rates = ["", "002", "003", "004","005", "006", "007","008", "009", "010"];
            rates.map((rate:string) => {
               if (item[`buyerCostInterestRate_${rate}`] !== tableData[index][`buyerCostInterestRate_${rate}`]) {
                   const check = updatedFields.find((field: any) => field.loyaltyId === rate);
                   if (check) {
                       check.rates[item.installment] = { buyerCostInterestRate: tableData[index][`buyerCostInterestRate_${rate}`] }
                   }
                   else {
                       updatedFields.push({
                           loyaltyId: rate,
                           rates: { [item.installment]: { buyerCostInterestRate: tableData[index][`buyerCostInterestRate_${rate}`] }  }
                       });
                   }
               }
            });
        });
        saveInstallmentRates({
            variables: {
                merchantId: String(activeMerchantId),
                rates: updatedFields
            }
        }).then((response: any) => {
            const { status } = response.data.saveInstallmentRates;
            if (status === RESPONSE_STATUS.APPROVED) {
                refetch({ merchantId: String(activeMerchantId) })
            }
        })
    }

    if (error) return <p>Error...</p>;
    if (loading) return <p>Loading...</p>;
    return (

        <PageContainer>
            <TopInfo oneTime={data?.getCommissions.oneTime}/>
            <BaseTable
                columns={generatedColumns}
                dataSource={tableData}
                bordered
                search={false}
                pagination={false}
                options={false}
                rowKey={(record: any) => record.key}
            />
            {isVisibleEditCommissions && (
                <ActionButtons
                    save={save}
                    undoChanges={undoChanges}
                    onChangeAllInterestRateClick={changeAllInterestRateClick}/>
            )}
        </PageContainer>
    );
};

export default Commissions;
