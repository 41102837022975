import Awaiting from "@/features/financial-management/routes/awaiting";
import Commissions from "@/features/financial-management/routes/commissions";
import Completed from "@/features/financial-management/routes/completed";
import PosStatement from "@/features/financial-management/routes/pos-statement";
import Undue from "@/features/financial-management/routes/undue";

const financialManagementRoutes = {
  path: "financial-management",
  children: [
    {
      path: "awaiting",
      element: <Awaiting currency="TRY" />,
    },
    {
      path: "undue",
      element: <Undue currency="TRY" />,
    },
    {
      path: "commissions",
      element: <Commissions />,
    },
    {
      path: "completed",
      element: <Completed />,
    },
    {
      path: "pos-statement",
      element: <PosStatement />,
    },
  ],
};

export default financialManagementRoutes;
