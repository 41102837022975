import BasicLayout from "@/layouts/basic";
import financialManagementRoutes from "@/features/financial-management/routes";
import posManagementRoutes from "@/features/pos-management/routes";
import transactionsRoutes from "@/features/transactions/routes";
import settingsRoutes from "@/features/settings/routes";
import dashboardRoutes from "@/features/dashboard/routes";
import userRoutes from "@/features/user/routes";
import PaymentItemsRoutes from "@/features/payment-items/routes";
import payWithLinkRoutes from "@/features/pay-with-link";

const mergedRoutes = [
  dashboardRoutes,
  financialManagementRoutes,
  transactionsRoutes,
  payWithLinkRoutes,
  settingsRoutes,
  userRoutes,
  PaymentItemsRoutes,
  posManagementRoutes
];



const protectedRoutes = {
  path: "/",
  element: <BasicLayout />,
  children: mergedRoutes,
};

export default protectedRoutes;
