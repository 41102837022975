import { gql } from "@apollo/client";

const GET_WEEKLY_PREVIEW_DATA = gql`
  query (
    $merchantId: String!
    $fromDate: String!
    $status: [String]!
    $settlementStatus: [String]
    $toDate: String!
  ) {
    getRefundBalance(merchantId: $merchantId) {
      response {
        refundBalance
        voidBalance
      }
      status
    }
    getTransactionCountByDate(
      fromDate: $fromDate
      status: $status
      toDate: $toDate
    ) {
      response {
        count
        date
        hour
        percentage
        total
      }
    }
    getSettlements(merchantId: $merchantId, status: $settlementStatus) {
      data {
        totals {
          gross
        }
      }
    }
  }
`;

export default GET_WEEKLY_PREVIEW_DATA;
