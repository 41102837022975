import { gql } from "@apollo/client";

const GET_MID_PROFILE_DETAIL = gql`
    query($merchantId: Int!, $configGroupId: Int!) {
      getMidProfileDetail(merchantId: $merchantId, configGroupId: $configGroupId) {
        status
        message
        midProfile
      }
    }
`;

export default GET_MID_PROFILE_DETAIL