import component from "./en-US/component";
import globalHeader from "./en-US/globalHeader";
import menu from "./en-US/menu";
import pwa from "./en-US/pwa";
import settingDrawer from "./en-US/settingDrawer";
import settings from "./en-US/settings";
import pages from "./en-US/pages";
import others from "./en-US/others";
import createPassword from './en-US/createPassword';
import transactions from "./en-US/transactions";
import settlements from "./en-US/settlements";
import form from "./en-US/form";
import modals from "./en-US/modals";
import results from "./en-US/results";
import { posStatement } from "./tr-TR/posStatement";
import payment from "./en-US/payment";
import posManagement from "./en-US/posManagement"

const EN = {
  ...others,
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...transactions,
  ...createPassword,
  ...settlements,
  ...form,
  ...modals,
  ...results,
  ...posStatement,
  ...payment,
  ...posManagement
};

export default EN
