import Merchant from "@/features/settings/routes/merchant";
import Users from "@/features/settings/routes/users";

const settingsRoutes = {
  path: "settings",
  children: [
    {
      path: "merchant",
      element: <Merchant />,
    },
    {
      path: "users",
      element: <Users />,
    },
  ],
};

export default settingsRoutes;
