// Libraries
import {useEffect, useState} from "react";
import { useQuery } from "@apollo/client";

// Components
import { PageContainer } from "@ant-design/pro-layout";
import ModalUserCreate from "@/features/settings/components/modal/create";
import ModalUserEdit from "@/features/settings/components/modal/edit";
import ListUsers from "../../components/list-users";

// Utils
import GET_MERCHANT_USERS from "@/features/settings/api/get-merchant-users";
import { RootState } from "@/store";

// Hooks
import { useSelector } from "react-redux";

// Styles
import "./index.less";
import { User } from "../../components/card-user";
import useAuthorization from "@/hooks/use-authorization";
import PERMISSIONS from "@/config/permissions";

const Users = () => {
  const { activeMerchantId, role } = useSelector(
    (state: RootState) => state.auth.params
  );
  const { data, error, loading, refetch } = useQuery(GET_MERCHANT_USERS, {
    variables: {
      merchantId: String(activeMerchantId),
      userRole: role,
    },
  });
  const { checkPermission } = useAuthorization();

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isVisibleEditUser, setIsVisibleEditUser] = useState(false);
  const [isVisibleCreateUser, setIsVisibleCreateUser] = useState(false);
  const [isModalUserCreateVisible, setModalUserCreateVisible] =
  useState<boolean>(false);

    useEffect(() => {
        checkPermissions();
    }, []);

    const checkPermissions = async () => {
        //const permissions = [PERMISSIONS.settings.user.edit, PERMISSIONS.settings.user.create];
        //const [canEditUsers, canCreateUsers] = await checkPermission(permissions);
        setIsVisibleEditUser(true);
        setIsVisibleCreateUser(true);
    }

  const resetComponent = () => {
    setModalUserCreateVisible(false);
    setSelectedUser(null);
    refetch();
  };

  if (error) return <p>Hata</p>;
  if (loading) return <p>loading</p>;
  return (
    <PageContainer>
      <ListUsers
        users={data?.getMerchantUsers?.merchantUsers}
        isEditable={isVisibleEditUser}
        isCreateVisible={isVisibleCreateUser}
        onEditClick={(selectedUser) => setSelectedUser(selectedUser)}
        onAddClick={() => setModalUserCreateVisible(true)}
      />
      <ModalUserEdit
        visible={selectedUser ? true : false}
        user={selectedUser}
        onProcessEnd={resetComponent}
        external={{ activeMerchantId, role }}
      />
      <ModalUserCreate
        visible={isModalUserCreateVisible}
        onProcessEnd={resetComponent}
        external={{ activeMerchantId, role }}
      />
    </PageContainer>
  );
};

export default Users;
