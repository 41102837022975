import React from "react";
import ReactDOM from "react-dom/client";
import AppProvider from "@/providers/app";
import "@/global.less";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<AppProvider />);
