import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import { Divider, Space, Typography, Button } from "antd";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import CREATE_KEY from "@/features/settings/api/create-key";

const { Title } = Typography;

const KeyInfo = () => {
  const { t } = useTranslation();
  const [getKeyInfo] = useMutation(CREATE_KEY);
  const activeMerchantId = useSelector(
    (state: RootState) => state.auth.params.activeMerchantId
  );
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const getKey = async (keyType: string) => {
    getKeyInfo({
      variables: {
        merchantId: String(activeMerchantId),
        keyType: keyType,
      },
    }).then((res) => {
      const { key } = res.data.createKey;
      if (keyType === "apiKey") {
        setApiKey(key);
      } else {
        setSecretKey(key);
      }
    });
  };
  return (
    <Descriptions
      title={
        <Space
          direction={"vertical"}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Title level={4}> {t("pages.merchant.settings.apiKeys")}</Title>
          <Divider style={{ margin: "0 0 4px 0" }} />
        </Space>
      }
      labelStyle={{ fontWeight: "bold" }}
    >
      <Descriptions.Item label={t("pages.merchant.settings.apiKey")}>
        {apiKey ? (
          <span>{apiKey}</span>
        ) : (
          <Button onClick={() => getKey("apiKey")}>Görüntüle</Button>
        )}
      </Descriptions.Item>
      <Descriptions.Item label={t("pages.merchant.settings.secretKey")}>
        {secretKey ? (
          <span>{secretKey}</span>
        ) : (
          <Button onClick={(e) => getKey("secretKey")}>Görüntüle</Button>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default KeyInfo;
