import {useParams} from "react-router-dom";
import {Alert, Input, Card, Form, Col, Row, Select, InputNumber, Button} from 'antd';
import {useEffect, useState} from "react";
import {RESPONSE_STATUS} from "@/config/constants";

import { useTranslation } from "react-i18next";
import "@/layouts/checkout/index.less";

const Payment = () => {
    const {id, lang} = useParams();
    const currentLanguage = (lang && lang !== 'undefined') ? lang : 'tr';
    const columnNames = {
        "payment.nameSurname": {
            tr: "İsim Soyisim:",
            en: "Name Surname:"
        },
        "payment.emailAddress": {
            tr: "E-posta Adresi:",
            en: "Email Address:"
        },
        "payment.phoneNumber": {
            tr:"Telefon Numarası:",
            en:"Phone Number:"
        },
        "payment.address": {
            tr:"Adres:",
            en:"Address:"
        },
        "payment.address2": {
            tr:"Adres 2:",
            en:"Address 2:"
        },
        "payment.country": {
            tr: "Ülke:",
            en: "Country:"
        },
        "payment.city": {
            tr: "Şehir:",
            en: "City:"
        },
        "payment.postCode": {
            tr: "Posta Kodu:",
            en: "Post Code:"
        },
        "payment.price": {
            tr: "Fiyat:",
            en: "Price:"
        },
        "payment.piece": {
            tr: "Adet:",
            en: "Quantity"
        },
        "payment.pay": {
            tr: "Öde",
            en: "Pay"
        },
        "payment.cargo": {
            tr: "Kargo Bilgileri",
            en: "Cargo Informations"
        },
        "payment.info": {
            tr: "Ödeme Bilgileri",
            en: "Payment Informations"
        }
    }
    const EnumPaymentErrorCode = {
        OUT_OF_STOCK: {
            tr: "Maalesef, oluşturulan linkin stoğu bulunmamaktadır. Satıcınızla iletişime geçerek yeni bir link talep edebilirsiniz",
            en: "Unfortunately, the generated link is out of stock. You can contact your seller to request a new link."
        },
        MERCHANT_3D_NOT_VALID: {
            tr: "3D ödeme tanımınızda hata mevcut. Lütfen posoperasyon@ozan.com adresiyle iletişime geçin.",
            en: "There is an error in your 3D payment definition. Please contact posoperation@ozan.com for support."
        },
        LINK_EXPIRED: {
            tr: "Linkin geçerlilik süresi dolmuştur. Satıcınızla iletişime geçerek yeni bir link talep edebilirsiniz.",
            en: "The link has expired. You can contact your seller to request a new link."
        },
        LINK_NOT_FOUND: {
            tr: "Link bulunamadı.",
            en: "Link not found."
        },
        SERVER_ERROR: {
            tr: "Sunucu hatası!",
            en: "Server error!"
        },
    }
    const [hasError, setHasError] = useState(false);
    const [internalError, setInternalError] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [details, setDetails] = useState({
        name: "",
        desc: "",
        currency: "",
        id: "",
        imageUrlLarge: "",
        imageUrlMedium: "",
        stock: 1,
        amount: "",
        cargo: ""
    });
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const getCountries = async () => {
        const response = await fetch(`${process.env.REACT_APP_REST_API}/resource/countries?prior=TR&sort=name:asc`);
        const data = await response.json();
        setCountries(data);
    }
    const getCities = async (countryCode: string) => {
        const response = await fetch(`${process.env.REACT_APP_REST_API}/resource/states?countryCode=${countryCode}&sort=name:asc`);
        const data = await response.json();
        setStates(data);
    }

    const isValidControl = async () => {
        const response = await fetch(`${process.env.REACT_APP_REST_API}/pay-to-link/payment?id=${id}`);
        const data = await response.json();
        if (data.status === RESPONSE_STATUS.APPROVED) {
            setDetails(data.result);
            if (data.result.cargo) {
                getCountries();
            }
        }
        if (data.status === RESPONSE_STATUS.ERROR) {
            // @ts-ignore
            const message = EnumPaymentErrorCode[data.errorCode][currentLanguage];
            setHasError(true);
            setErrorMessage(message);
        }
    }

    useEffect(() => {
        isValidControl();
    }, []);

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const initialValues = {
        name: "",
        email: "",
        quantity: 1,
    }

    const onFinish = async (values: any) => {
       const { name, email, billingPhone, quantity, billingAddress1, billingAddress2, billingCity, billingCountry, billingPostcode } = values;
       const splitName = name.split(" ");
       const length = splitName.length;
       const billingFirstName = splitName.slice(0, length - 1).join(" ");
       const billingLastName = splitName[splitName.length - 1];
       let params = {
           email,
           billingFirstName,
           billingLastName,
           billingPhone,
           quantity,
           id,
           language: currentLanguage,
           returnUrl: window.location.origin.toString()+"/checkout/payment-response?id="+id+"%26lang="+currentLanguage
       };
       if (details.cargo) {
           // @ts-ignore
           params = { ...params, billingAddress1, billingAddress2, billingCity, billingCountry, billingPostcode}
       }

        try {
            const response = await fetch(`${process.env.REACT_APP_REST_API}/pay-to-link/payment/init`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "POST",
                    body: JSON.stringify(params)
                });
            if (!response.ok) {
                let errorMessage = `Failed to fetch: ${response.status} ${response.statusText}`;
                try {
                    const errorData = await response.json();
                    if (errorData && errorData.message) {
                        errorMessage = errorData.message;
                    }
                } catch (jsonError) {
                    // JSON parse hatası oluşursa, mevcut errorMessage kullanılacak
                }
                throw new Error(errorMessage);
            }

            const data = await response.json();
            if (data) {
                const { purchaseUrl } = data;

                if (purchaseUrl && !purchaseUrl.includes('undefined')) {
                    window.location.href = purchaseUrl;
                }
            }
        } catch (error: any) {
            if (error && error.message) {
                setInternalError(error.message);
            } else {
                const genericMessage = currentLanguage === 'tr' ? 'Ödeme işlemi sırasında bir hata oluştu, lütfen tekrar deneyiniz.' : 'An error occurred during the payment process, please try again.';
                setInternalError(genericMessage);
            }
        }
    };


    return (
        <div className="page-content checkout-container">
            {internalError && (
                <Alert message={internalError} type="error" showIcon style={{ marginBottom: 20 }} />
            )}
            { !hasError ? (
                <Form
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    labelAlign="left"
                    autoComplete={"off"}
                    initialValues={initialValues}
                    layout={"vertical"}>
                    <Row aria-colcount={24} gutter={30}>
                        <Col span={12}>
                            <Card>
                                {/*@ts-ignore*/}
                                <Form.Item name='name' label={columnNames["payment.nameSurname"][currentLanguage]} rules={[{required: true}]}>
                                    <Input/>
                                </Form.Item>
                                {/*@ts-ignore*/}
                                <Form.Item name='email' label={columnNames["payment.emailAddress"][currentLanguage]} rules={[{ required: true, message: 'Email is required!' }, { type: 'email', message: 'Please enter a valid email address!' }]}>
                                    <Input/>
                                </Form.Item>
                                {/*@ts-ignore*/}
                                <Form.Item name='billingPhone' label={columnNames["payment.phoneNumber"][currentLanguage]} rules={[{required: true}]}>
                                    <Input/>
                                </Form.Item>
                            </Card>
                            {/*@ts-ignore*/}
                            {details.cargo && (<Card title={columnNames["payment.cargo"][currentLanguage]} style={{marginTop: 30}}>
                                    {/*@ts-ignore*/}
                                    <Form.Item name='billingAddress1' label={columnNames["payment.address"][currentLanguage]} rules={[{required: true}]}>
                                        <Input/>
                                    </Form.Item>
                                    {/*@ts-ignore*/}
                                    <Form.Item name='billingAddress2' label={columnNames["payment.address2"][currentLanguage]} rules={[{required: false}]}>
                                        <Input/>
                                    </Form.Item>
                                    {/*@ts-ignore*/}
                                    <Form.Item name='billingCountry' label={columnNames["payment.country"][currentLanguage]} rules={[{required: true}]}>
                                        <Select onChange={(value) => {
                                            getCities(value);
                                        }}>
                                            {countries.map((country: any) => (
                                                <Select.Option value={country.code}>{country.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Row aria-colcount={24} gutter={15}>
                                        <Col span={12}>
                                            {/*@ts-ignore*/}
                                            <Form.Item name='billingCity' label={columnNames["payment.city"][currentLanguage]} rules={[{required: true}]}>
                                                <Select>
                                                    {states.map((state: any) => (
                                                        <Select.Option value={state.code}>{state.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            {/*@ts-ignore*/}
                                            <Form.Item name='billingPostcode' label={columnNames["payment.postCode"][currentLanguage]} rules={[{required: true}]}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </Col>
                        {!!details && (
                            <Col span={12}>
                                <Card>
                                    <div className="product-img">
                                        <img alt={details.name} src={details.imageUrlLarge}/>
                                    </div>
                                    <div className="product-name">
                                        {details.name}
                                    </div>
                                    <div className="product-description">
                                        {details.desc}
                                    </div>
                                    <ul className="product-ul">
                                        <li>
                                            {/*@ts-ignore*/}
                                            <span className="label">{columnNames["payment.price"][currentLanguage]}</span>
                                            <span className="product-price">{details.amount} {details.currency}</span>
                                        </li>
                                        <li>
                                            {/*@ts-ignore*/}
                                            <span className="label">{columnNames["payment.piece"][currentLanguage]}</span>
                                            <span>
                                        <Form.Item name='quantity' rules={[{required: true}]} initialValue={1}>
                                            <InputNumber min={1} max={details.stock}/>
                                        </Form.Item>
                                    </span>
                                        </li>
                                    </ul>
                                </Card>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="payment-button">
                                        {/*@ts-ignore*/}
                                        {columnNames["payment.pay"][currentLanguage]}
                                    </Button>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            ) : (
                <div className="payment-status-page">
                    <div className="payment-status error">
                        <div>
                            <div className="payment-status-title">
                                {errorMessage}
                            </div>
                        </div>
                    </div>
                </div>
            ) }
        </div>
    )
}

export default Payment