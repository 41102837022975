import React, { useState } from "react";
import Logo from "@/assets/logo.png";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { useTranslation } from "react-i18next";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {Button, ButtonProps, Result} from "antd";
import {RESPONSE_STATUS} from "@/config/constants";
import {Link} from "react-router-dom";

const CreatePassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState<string>("");

    const submitButtonProps: Partial<ButtonProps> = {
        loading: loading,
        size: 'large',
        style: { width: '100%' },
    };

    const EmailInput = () => {
        const placeHolder = t('createPassword.email');

        const rules = [
            {
                required: true,
                message: t("createPassword.email.required"),
            },
            {
                type: 'email' as const, // TypeScript'in doğru türü algılaması için 'as const' ekledik
                message: t("createPassword.email.invalid"),
            },
        ];

        return (
            <ProFormText
                name="email"
                fieldProps={{
                    autoComplete: "off",
                    size: "large",
                    prefix: <UserOutlined className="prefixIcon" />,
                }}
                placeholder={placeHolder}
                rules={rules}
            />
        );
    };

    const PasswordInput = () => {
        const placeHolder = t('createPassword.password');

        const rules = [
            {
                required: true,
                message: t('createPassword.password.required'),
            },
            {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: <span className="error">Şifreniz yeterli güvenliğe sahip değil.<br/>
              <ul className="password-error-text">
                <li>En az 8 karakterden oluşmalıdır.</li>
                <li>En az 1 rakam ve 1 büyük harf içermelidir.</li>
                <li>En az 1 özel karakter içermelidir. İzin verilen özel karanterler <div className="special-character-error">{`!"#$%&'()*+,-./:;<=>?@[\]^\`{|}~`}</div></li>
              </ul>
            </span>,
            }
        ];

        return (
            <ProFormText.Password
                name="password"
                fieldProps={{
                    size: "large",
                    prefix: <LockOutlined className="prefixIcon" />,
                }}
                placeholder={placeHolder}
                rules={rules}
            />
        );
    };

    const ConfirmPasswordInput = () => {
        const placeHolder = t('createPassword.passwordConfirm');

        const rules = [
            {
                required: true,
                message: t('createPassword.passwordConfirm.required'),
            },
            ({ getFieldValue }: any) => ({
                validator(_: any, value: any) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(t('createPassword.passwordConfirm.mismatch'));
                },
            })
        ];

        return (
            <ProFormText.Password
                name="passwordConfirm"
                fieldProps={{
                    size: "large",
                    prefix: <LockOutlined className="prefixIcon" />,
                }}
                placeholder={placeHolder}
                rules={rules}
            />
        );
    };

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const nodeApiURL = process.env.REACT_APP_NODE_API ? process.env.REACT_APP_NODE_API : "";
            const url = nodeApiURL.replace("/graphql", "") + '/api/v1/reset-password/set';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: values.email,
                    password: values.password,
                    token: new URLSearchParams(window.location.search).get('id')
                })
            });

            const data = await response.json();
            setLoading(false);
            if (data.status === RESPONSE_STATUS.APPROVED) {
                window.location.href = '/';
                setResponseStatus(RESPONSE_STATUS.APPROVED);
            } else {
                setResponseStatus(RESPONSE_STATUS.DECLINED);
                throw new Error(data.message || 'Password change failed');
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    return (
        <div className="main">
            <img src={Logo} className="logo" alt="Logo" />
            {responseStatus !== RESPONSE_STATUS.APPROVED && (
                <h2 className="header">
                    {t('createPassword.title')}
                </h2>
            )}
            {responseStatus === RESPONSE_STATUS.DECLINED && (
                <Result
                    title={t("createPassword.error")}
                    subTitle={""}
                    status="error"
                />
            )}
            {responseStatus === RESPONSE_STATUS.APPROVED ? (
                    <Result
                        title={t("createPassword.success")}
                        subTitle={""}
                        status="success"
                        extra={
                            <Button type="primary" size="large">
                                <Link to="/user/login">
                                    {t('pages.resetPassword.login.button')}
                                </Link>
                            </Button>
                        }
                    />
                ) : (
                    <ProForm
                        submitter={{
                            render: (_, dom) => dom.pop(),
                            submitButtonProps,
                        }}
                        isKeyPressSubmit={true}
                        onFinish={handleSubmit}
                    >
                        <EmailInput />
                        <PasswordInput />
                        <ConfirmPasswordInput />
                    </ProForm>
                    )}
        </div>
    );
}

export default CreatePassword;
