import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GET_PAY_TO_LINK from "@/features/pay-with-link/api/queries/getPayToLink";
import UPDATE_PAY_TO_LINK from "@/features/pay-with-link/api/mutations/updatePayToLink";

import { useSelector } from "react-redux";
import { RootState } from "@/store";
import CREATE_KEY from "@/features/settings/api/create-key";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import FileUpload from "@/features/pay-with-link/components/pay-with-link/file-upload";
import GET_MERCHANT_3D_STATUS from "@/features/pay-with-link/api/queries/getMerchant3dStatus";
import { RESPONSE_STATUS } from "@/config/constants";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const EditPayWithLink = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const [getKeyInfo] = useMutation(CREATE_KEY);
    const [updatePayToLink] = useMutation(UPDATE_PAY_TO_LINK);

    const [apiKey, setApiKey] = useState("");
    const [paymentStatus, setpaymentStatus] = useState<any>();

    const { data, loading, error } = useQuery(GET_PAY_TO_LINK, {
        variables: {
            getPayToLinkId: id
        },
        onCompleted(data) {
            getKeyInfo({
                variables: {
                    merchantId: String(activeMerchantId),
                    keyType: "apiKey",
                },
            }).then((res) => {
                const { key } = res.data.createKey;
                setApiKey(key);
            });

            const { getPayToLink: { result } } = data;

            form.setFields([
                {
                    name: "apiKey",
                    value: apiKey
                },
                {
                    name: "check3D",
                    value: result.check3D
                },
                {
                    name: "name",
                    value: result.name
                },
                {
                    name: "desc",
                    value: result.desc
                },
                {
                    name: ["price", "amount"],
                    value: result.amount.split('.')[0],
                },
                {
                    name: ["price", "fraction"],
                    value: result.amount.split('.')[1],
                },
                {
                    name: ["price", "currency"],
                    value: result.currency
                },
                {
                    name: "stock",
                    value: result.stock
                },
                {
                    name: "limit",
                    value: result.limit
                },
                {
                    name: "cargo",
                    value: result.cargo
                },
                {
                    name: "status",
                    value: result.status
                },
                {
                    name: "maxInstallmentNumber",
                    value: result.maxInstallmentNumber
                }
            ])


        }
    })
    useQuery(GET_MERCHANT_3D_STATUS, {
        variables: {
            merchantId: String(activeMerchantId)
        },
        onCompleted(data) {
            setpaymentStatus(data?.getMerchant3dStatus);
            form.setFieldValue("check3D", data?.getMerchant3dStatus?.result.checkbox.value);
            console.log(data?.getMerchant3dStatus)
        }
    });

    const onFinish = (values: any) => {
        const { price, ...rest } = values
        const requestParams = {
            updatePayToLinkId: id,
            updatePayToLinkInput: {
                ...rest,
                currency: price.currency,
                amount: `${price.amount}.${price.fraction}`,
                apiKey: apiKey
            }
        };

        updatePayToLink({
            variables: requestParams
        }).then((response) => {
            const { status, message: responseMessage } = response.data.updatePayToLink;
            if (status !== RESPONSE_STATUS.APPROVED) {
                throw new Error(responseMessage);
            }
            message.success(responseMessage);
        }).catch((error) => {
            message.error(error.message);
        });
    }


    return (
        <>
            <div style={{ fontSize: 15, fontWeight: "bold", padding: "20px 0", cursor: "pointer", display: "inline-block" }} onClick={() => { navigate('/pay-with-link') }}>
                <ArrowLeftOutlined /> Geri Dön
            </div>
            <Card title="Link Düzenle">
                <Form {...layout}
                    form={form}
                    name="nest-messages"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    labelAlign="left"
                    autoComplete={"off"}
                >
                    <Row gutter={30} wrap={true}>
                        <Col span={12}>
                            <Form.Item name={'name'} label="Ürün Adı" rules={[{ required: true }]}>
                                <Input placeholder="Ürün adı gir" />
                            </Form.Item>

                            <Form.Item label="Tutar">
                                <Input.Group compact>
                                    <Form.Item noStyle name={['price', 'amount']} rules={[{ required: true }]}>
                                        <InputNumber min={0} controls={false}
                                            style={{ width: '25%', marginRight: "5px" }} placeholder="0" />
                                    </Form.Item>
                                    .
                                    <Form.Item noStyle name={['price', 'fraction']} rules={[{ required: true }]}>
                                        <InputNumber<string> stringMode min={"00"} max={"99"} minLength={2}
                                            controls={false}
                                            style={{ width: '25%', marginLeft: "5px" }}
                                            placeholder="00"
                                        />
                                    </Form.Item>

                                    <span style={{ padding: "0 10px" }} />
                                    <Form.Item noStyle name={['price', 'currency']}>
                                        <Select style={{ width: '25%' }}>
                                            <Select.Option value="TRY">TRY</Select.Option>
                                            <Select.Option value="EUR">EUR</Select.Option>
                                            <Select.Option value="USD">USD</Select.Option>
                                            <Select.Option value="GBP">GBP</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>

                            <Form.Item name={'desc'} label="Açıklama" rules={[{ required: true }]}>
                                <Input placeholder="Açıklama gir" />
                            </Form.Item>

                            <Form.Item name={'maxInstallmentNumber'} label="Taksit Sayısı"
                                rules={[{ required: true }]}>
                                <Select style={{ width: '50%' }}>
                                    {Array.from(Array(12).keys()).map((value: number) => (
                                        <Select.Option value={value + 1} key={value + 1}>{value + 1}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item name={'stock'} label="Stok Limiti" rules={[{ required: true }]}>
                                <InputNumber min={0} controls={false} placeholder="Stok Limiti gir" style={{ width: '50%' }} />
                            </Form.Item>

                            <Form.Item name={'cargo'} label="Alıcıdan Kargo Talep Et" style={{ marginBottom: "5px" }}
                                valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>

                            <Form.Item name={'check3D'} label="3D Secure" valuePropName={'checked'}>
                                <Checkbox disabled={paymentStatus?.result?.checkbox.disabled} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    <EditOutlined /> Güncelle
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingBottom: 20 }}>
                            <Form.Item name={"file"} valuePropName={'fileList'}>
                                <FileUpload onDone={(file: string | null) => form.setFieldValue("file", file)} url={data?.getPayToLink?.result?.imageUrlLarge} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

        </>
    );
};

export default EditPayWithLink;
