import {Button, Result} from 'antd';
import {ResultStatusType} from "antd/es/result";

interface BaseResultProps {
    title: string;
    subTitle: string;
    status: ResultStatusType;
    buttonText: string;
    buttonOnClick: any;
}

const BaseResult = ({
                        title,
                        subTitle,
                        status,
                        buttonText,
                        buttonOnClick
                    }: BaseResultProps) => {

    return (
        <Result
            status={status}
            title={title}
            subTitle={subTitle}
            extra={[
                <Button type='primary' key='close' onClick={buttonOnClick}>
                    {buttonText}
                </Button>,
            ]}
        />
    );
}
export default BaseResult;
