import {Button, Select, Input, message} from "antd";
import {useState} from "react";
import {useMutation} from "@apollo/client";
import SEND_CUSTOM_MAIL_WITH_PAY_TO_LINK from "@/features/pay-with-link/api/mutations/sendCustomMailWithPayToLink";
import {RESPONSE_STATUS} from "@/config/constants";
import SEND_MAIL_WITH_PAY_TO_LINK from "@/features/pay-with-link/api/mutations/sendMailWithPayToLink";
import {useSelector} from "react-redux";
import {RootState} from "@/store";

interface LinkInfoProps {
    url: string
    type: string
    id?: string
}

const LinkInfo = ({url, type, id}: LinkInfoProps) => {
    const [sendCustomPaymentEmail] = useMutation(SEND_CUSTOM_MAIL_WITH_PAY_TO_LINK);
    const [sendPaymentEmail] = useMutation(SEND_MAIL_WITH_PAY_TO_LINK);
    const activeMerchantId = useSelector(
        (state: RootState) => state.auth.params.activeMerchantId
    );
    const [lang, setLang] = useState("tr");
    const [email, setEmail] = useState("");
    const handleChange = (value: string) => {
        setLang(value);
    };

    const sendEmail = () => {
        if (type === "custom-payment") {
            sendCustomPaymentEmail({
                variables: {
                    sendCustomMailWithPayToLinkInput: {
                        emails: [email],
                        language: lang,
                        paymentLink: `${url}/${lang}`,
                        merchantId: String(activeMerchantId)
                    }
                }
            }).then((res) => {
                const { status } = res.data.sendCustomMailWithPayToLink;
                if (status === RESPONSE_STATUS.APPROVED) {
                    message.success("Mail gönderildi!");
                    setEmail("");
                }
            }).catch((err) => {
                console.log(err.data);
            })
        } else {
            sendPaymentEmail({
                variables: {
                    sendMailWithPayToLinkInput: {
                        id,
                        emails: [email],
                        language: lang,
                        paymentLink: `${url}/${lang}`,
                        merchantId: String(activeMerchantId)
                    }
                }
            }).then((res) => {
                const { status } = res.data.sendMailWithPayToLink;
                if (status === RESPONSE_STATUS.APPROVED) {
                    message.success("Mail gönderildi!");
                    setEmail("");
                }
            }).catch((err) => {
                console.log(err.data);
            })
        }

    }

    return (
        <>
            <div style={{
                width: "95%",
                margin: "30px auto",
                border: "1px solid #00aeef",
                padding: "35px",
                borderRadius: "8px"
            }}>
                <Input.Group compact>
                    <Select
                        defaultValue="TR"
                        style={{width: 65}}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'tr',
                                label: 'tr',
                            },
                            {
                                value: 'en',
                                label: 'en',
                            }
                        ]}
                    />
                    <div style={{
                        width: 'calc(100% - 175px)',
                        padding: "5px 15px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        height: 32,
                        border: "1px solid #ccc"
                    }}>
                        <a href={`${url}/${lang}`}
                           rel="noreferrer"
                           target="_blank">{`${url}/${lang}`}</a>
                    </div>
                    <Button
                        onClick={() => {
                            navigator.clipboard.writeText(`${url}/${lang}`);
                            message.success("Link kopyalandı!");
                        }}
                        type="primary"
                        style={{height: 32}}>Link Kopyala</Button>
                </Input.Group>
            </div>
            <div style={{
                width: "95%",
                margin: "30px auto",
                border: "1px dashed #CCCCCC",
                padding: "35px",
                borderRadius: "8px"
            }}>
                <Input.Group compact>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        addonBefore="Linki Paylaş :" style={{width: 'calc(100% - 80px)'}}
                        placeholder={"E-mail adresi giriniz"}/>
                    <Button
                        onClick={() => {
                            sendEmail();
                        }}
                        type="primary"
                        style={{height: "32px"}}>Gönder</Button>
                </Input.Group>
            </div>
        </>
    );
};

export default LinkInfo;
