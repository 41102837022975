import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, message } from "antd";
import camelCase from 'camelcase';

import { RootState } from "@/store";
import { RESPONSE_STATUS } from "@/config/constants";
import TypeSale from "@/features/pos-management/components/mid-rates/type-sale";
import TypeInstallmentSale from "@/features/pos-management/components/mid-rates/type-installment-sale";
import GET_MID_RATES from "@/features/pos-management/api/queries/get-mid-rates";
import SAVE_MID_RATES from "@/features/pos-management/api/mutations/save-mid-rates";
import {useTranslation} from "react-i18next";

const MidRates = () => {
    const { mid } = useParams();
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const { t } = useTranslation()
    const { data, error, loading, refetch } = useQuery(GET_MID_RATES, {
        variables: {
            merchantId: String(activeMerchantId),
            midId: String(mid)
        }
    });
    const [saveMidRates] = useMutation(SAVE_MID_RATES)

    const [receivedValues, setReceivedValues] = useState<any>(null);

    const formatSubmitData = (requestData: any) => {
        const { sale, installment_sale: { blockageDayNumberForInstallmentSale, rates } } = requestData;

        const installmentNumber: any = [];
        const installmentRate: any = [];
        const saleData: any = {};

        rates.forEach((rate: any, index: number) => {
            installmentNumber.push(rate.installmentNumber);
            installmentRate.push(rate.rate);
        });

        sale.forEach((item: any, index: number) => {
            const { id, title, ...rest } = item;

            Object.keys(rest).forEach((key) => {
                let camelCaseKey = key;

                if (index === 0) {
                    camelCaseKey = camelCase(key);
                    Object.assign(saleData, { [camelCaseKey]: rest[key] });
                } else {
                    camelCaseKey = camelCase(`blockageDayNumberFor_${key}_sale`);
                    Object.assign(saleData, { [camelCaseKey]: String(rest[key]) });
                }
            });

        });

        return {
            blockageDayNumberForInstallmentSale: String(blockageDayNumberForInstallmentSale),
            installmentNumber,
            installmentRate,
            ...saleData
        };
    };

    const handleSubmit = () => {
        const requestParams = {
            merchantId: Number(activeMerchantId),
            midId: Number(mid),
            ...formatSubmitData(receivedValues),
        };
        
        saveMidRates({
            variables: requestParams
        }).then((response) => {
            const { status, message: responseMessage } = response.data.saveMidRates;
            if (status !== RESPONSE_STATUS.APPROVED) {
                throw new Error(responseMessage);
            }

            message.success(responseMessage);

            refetch();
        }).catch((error) => {
            message.error(error.message);
        });
    };

    if (loading) return <p>Loading</p>;

    if (error) return <p>Hata oluştu!</p>

    const styleButtonPanel: object = { textAlign: "right", padding: "20px 40px", marginTop: 20, background: 'white', borderRadius: 3 }
    return (
        <PageContainer>
            <h1>{t('pos.midList')}</h1>

            <TypeSale data={data.getMidRates.result?.sale} receivedValues={setReceivedValues} />
            <TypeInstallmentSale data={data.getMidRates.result?.installment_sale} receivedValues={setReceivedValues} />

            <div style={styleButtonPanel}>
                <Button onClick={handleSubmit}>{t('pos.save')}</Button>
            </div>
        </PageContainer>
    )
}

export default MidRates