import { useTranslation } from "react-i18next";
import { Descriptions, Space, Typography, Divider } from "antd";
import { findDummyDataValue } from "@/utils/findDummyDataValue";

const { Title } = Typography;
const AddressDetails = ({ data }: any) => {
  const { t } = useTranslation();
  const {
    billingAddress1,
    billingAddress2,
    billingCountry,
    billingCity,
    billingPhone,
    billingPostcode,

    shippingAddress1,
    shippingAddress2,
    shippingCountry,
    shippingCity,
    shippingPhone,
    shippingPostcode,
  } = data?.customerInfo || {};
  return (
    <Descriptions
      title={
        <Space
          direction={"vertical"}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Title level={4}>{t("transaction.productServiceInfo")}</Title>
          <Divider style={{ margin: "0 0 4px 0" }} />
        </Space>
      }
      labelStyle={{ fontWeight: "bold" }}
    >
      <Descriptions.Item label={t("transaction.shippingAddress")}>
        {`
              ${shippingAddress1 ? findDummyDataValue(shippingAddress1) : "-"}
              ${shippingAddress2 ? findDummyDataValue(shippingAddress2) : "-"}
            `}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.shippingCountry")}>
        {shippingCountry}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.shippingCity")}>
        {shippingCity}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.shippingPhone")}>
        {shippingPhone}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.shippingPostcode")}>
        {shippingPostcode}
      </Descriptions.Item>
      <Descriptions.Item label={""}>{""}</Descriptions.Item>
      <Descriptions.Item label={t("transaction.billingAddress")}>
        {`
            ${billingAddress1 ? findDummyDataValue(billingAddress1) : "-"}
            ${billingAddress2 ? findDummyDataValue(billingAddress2) : "-"}
          `}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.billingCountry")}>
        {billingCountry}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.billingCity")}>
        {billingCity}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.billingPhone")}>
        {billingPhone}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.billingPostcode")}>
        {billingPostcode}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default AddressDetails;
