import {PageContainer} from "@ant-design/pro-layout";
import {Button, Col, Row, Radio, Card, Form, Space} from 'antd';
import {ProFormText, ProFormSelect, ProFormTextArea} from "@ant-design/pro-form";
import {SaveOutlined} from "@ant-design/icons";

const CreatePaymentItem = () => {
    return (
        <PageContainer>
            <Form
                layout="vertical"
            >
                <Card title={"Ödeme Kalemi Oluştur :"} style={{marginTop: "15px", maxWidth: "70%"}}>
                    <Row aria-colcount={24}>
                        <Col span={12} style={{padding: 10}}>
                            <ProFormText
                                name="name"
                                label="İsim"
                                placeholder="İsim"
                                initialValue={""}
                            />
                            <ProFormText
                                name="unitPrice"
                                label="Birim Fiyatı"
                                placeholder="Birim Fiyatı"
                                initialValue={""}
                            />
                            <ProFormSelect
                                options={["TRY", "USD", "EUR", "GBP"]}
                                name="currency"
                                label="Para Birimi"
                                initialValue={"TRY"}
                            />
                        </Col>
                        <Col span={12} style={{padding: 10}}>
                            <ProFormTextArea
                                name="description"
                                label="Açıklama"
                                placeholder="Açıklama"
                            />
                        </Col>

                    </Row>

                    <h3 style={{marginTop: 30, marginBottom: 30, borderTop: "1px solid #CCC", paddingTop: 30}}>Ödeme
                        Kalemi Ayarları :</h3>

                    <Row aria-colcount={24}>
                        <Col span={6}>
                            <Form.Item name="radio-group" label="Tutar Değiştirilebilir mi?">
                                <Radio.Group>
                                    <Radio value="a" style={{width: "100%"}}>Aktif</Radio>
                                    <Radio value="b" style={{width: "100%"}}>Pasif</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="radio-group" label="Sunulan Ödeme Şekli">
                                <Radio.Group>
                                    <Radio value="a" style={{width: "100%"}}>Tek Çekim</Radio>
                                    <Radio value="b" style={{width: "100%"}}>Taksitli</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="radio-group" label="Ödeme Kalemi Geçerlilik">
                                <Radio.Group>
                                    <Radio value="a" style={{width: "100%"}}>Süresiz</Radio>
                                    <Radio value="b" style={{width: "100%"}}>Tarih Aralığı Belirle</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{
                        marginTop: 30,
                        marginBottom: 30,
                        borderTop: "1px solid #CCC",
                        paddingTop: 30,
                        textAlign: "left"
                    }}>
                        <Button
                            htmlType='button' type={"primary"} icon={<SaveOutlined/>}>
                            Ödeme Kalemini Oluştur
                        </Button>

                    </div>
                </Card>
            </Form>


        </PageContainer>
    );
};

export default CreatePaymentItem;
