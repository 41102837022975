import { gql } from "@apollo/client";

const PREAUTH = gql`
    mutation Mutation($amount :Float!,$currency: String, $merchantId: Int!, $referenceNo: String!,$transactionId: String!,$apiKey: String){
        preAuth(amount: $amount,currency:$currency, merchantId:$merchantId,referenceNo: $referenceNo, transactionId: $transactionId, apiKey: $apiKey){
        amount
        currency
        date
        message
        operation
        referenceNo
        status
        transactionId
        type
        },
    }
`
const PREAUTH_CANCEL = gql`
    mutation Mutation($amount :Float!,$currency: String, $merchantId: Int!, $referenceNo: String!,$transactionId: String!,$apiKey: String){
        preAuthCancel(amount: $amount,currency:$currency, merchantId:$merchantId,referenceNo: $referenceNo, transactionId: $transactionId, apiKey: $apiKey){
        amount
        currency
        date
        message
        operation
        referenceNo
        status
        transactionId
        type
        },
    }
`
export  { PREAUTH, PREAUTH_CANCEL }