import { gql } from "@apollo/client";

const GET_MERCHANT_RATES = gql`
  query ($merchantId: String!) {
    getMerchantRates(merchantId: $merchantId) {
      status
      message
      result {
        loyaltyId
        loyaltyName
        banks {
          acquirerId
          bankName
          bankCode
          blockageDayNumberForSale
          blockageDayNumberForInstallmentSale
          delayBlockageDayNumberForInstallmentSale
          onus_debit
          onus_credit
          domestic_debit
          domestic_credit
          international_debit
          international_credit
          domestic_amex_credit
          international_amex_credit
          rates
        }
      }
    }
  }
`;

export default GET_MERCHANT_RATES;
