import {Select, DatePicker, Button, Col, Row, Input} from 'antd';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import IconCard from "@/components/base/icon-card";
import {CreditCardOutlined} from "@ant-design/icons";

const TableTopSearch = ({ mids, search, clear, acquirers }: any) => {
    const [selectedMid, setSelectedMid] = useState("");
    const [selectedAcquirer, setSelectedAcquirer] = useState("");
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [selectedBlockageDay, setSelectedBlockageDay] = useState("");
    const { t } = useTranslation()

    const { RangePicker } = DatePicker;

    const midOptions = mids.map((mid : any) => ({ value: mid.id, label: mid.name }));
    const acquirersOptions = acquirers.map((acquirer: any) => ({ value: acquirer.id, label: acquirer.name }))
    return (
        <>
            <Row aria-colspan={24} style={{marginBottom: 40, padding: "0 20px"}}>
                <Col span={4}>
                    <h4>{t("pos.mid")}</h4>
                    <Select
                        defaultValue={t("pos.mid")}
                        value={selectedMid}
                        style={{ width: "90%" }}
                        onChange={(value: string) => setSelectedMid(value)}
                        options={midOptions}
                    />
                </Col>
                <Col span={4}>
                    <h4>{t("pos.acquirer")}</h4>
                    <Select
                        defaultValue={t("pos.acquirer")}
                        value={selectedAcquirer}
                        style={{ width: "90%" }}
                        onChange={(value: string) => setSelectedAcquirer(value)}
                        options={acquirersOptions}
                    />
                </Col>
                <Col span={5}>
                    <h4>{t("pos.createDate")}</h4>
                    <RangePicker
                        onChange={(values: any) => setSelectedDateRange(values)}
                        style={{ width: "90%" }} />
                </Col>
                <Col span={4}>
                    <h4>{t("pos.blockageDay")}</h4>
                    <Input
                        value={selectedBlockageDay}
                        onChange={(e:any) => setSelectedBlockageDay(e.target.value)}
                        style={{ width: "90%" }} />
                </Col>
                <Col span={3}>
                    <div style={{display: "flex", alignItems: "end", justifyContent: "center", marginTop: 32, gap: 10}}>
                        <Button
                            onClick={() => {
                                const values = {
                                    ...(!!selectedMid && { id: selectedMid }),
                                    ...(!!selectedAcquirer && { acquirer: selectedAcquirer }),
                                    ...(!!selectedDateRange && { dateRange: selectedDateRange }),
                                    ...(!!selectedBlockageDay && { blockageDayNumber: selectedBlockageDay })
                                };
                                search(values);
                            }}
                            style={{border: "1px solid #1677ff", color: "#1677ff", boxShadow: "none", width: "50%"}}>{t("pos.search")}</Button>
                        <Button
                            onClick={() =>
                            {
                                setSelectedMid("");
                                setSelectedAcquirer("");
                                setSelectedBlockageDay("");
                                setSelectedDateRange([]);
                                clear();
                            }}
                            style={{border: "1px solid #cd3d64", color: "#cd3d64", boxShadow: "none", width: "50%"}}>{t("pos.clear")}</Button>
                    </div>
                </Col>
                <Col span={4}>
                    <div style={{display: "flex", alignItems: "end", justifyContent: "end", marginTop: 32, gap: 10, width: "100%"}}>
                        <Link to={`/pos-management/virtual-pos/new`}> <Button type={"default"} icon={<CreditCardOutlined/>}>{t("pos.create.mid")}</Button> </Link>
                    </div>
                </Col>
            </Row>
        </>

    )
}

export default TableTopSearch