import CheckoutLayout from "@/layouts/checkout";
import checkoutRoutes from "@/features/checkout/routes";
import BlankLayout from "@/layouts/blank";
import createPasswordRoutes from "@/features/create-password/routes";

const externalRoutes = [
    {
        path: "/checkout",
        element: <CheckoutLayout/>,
        children: checkoutRoutes,
    },
    {
       path: "/sifre-olustur",
       element: <BlankLayout />,
       children: createPasswordRoutes
    }];

export default externalRoutes;
