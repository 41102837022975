const createPassword = {
    "createPassword.title": "Şifre Oluştur",
    "createPassword.email": "E-posta",
    "createPassword.password": "Şifre",
    "createPassword.passwordConfirm": "Şifre Doğrulama",
    "createPassword.submit": "Oluştur",
    "createPassword.email.required": "Lütfen e-posta adresinizi girin!",
    "createPassword.email.invalid": "Lütfen geçerli bir e-posta adresinizi girin!",
    "createPassword.password.required": "Lütfen şifrenizi girin!",
    "createPassword.passwordConfirm.required": "Lütfen şifrenizi girin!",
    "createPassword.passwordConfirm.mismatch": "Şifreleriniz uyuşmuyor!",
    "createPassword.error": "Kayıt işlemi başarısız. Lütfen yeni bir istekte bulunun.",
    "createPassword.success": "Kayıt işlemi başarılı. Yönlendiriliyorsunuz...",
};

export default createPassword
