const form = {
  "form.title": "Kullanıcı Bilgileri",
  "form.name": "Ad Soyad",
  "form.email": "Email",
  "form.role": "Yetki",
  "form.user": "Kullanıcı",
  "form.admin": "Admin",
  "form.changePassword": "Şifre Değiştir",
  "form.password": "Şifre",
  "form.passwordConfirm": "Şifre Tekrar",
  "form.message.general": "Lütfen bu alanı doldurunuz",
  "form.message.email": "Mailinizi giriniz",
  "form.message.password": "Şifre giriniz",
  "form.error.passwordMatch": "Şifreler eşleşmiyor",
  "form.error.minLength": "En az 6 karakter giriniz",
  "form.submit": "Gönder",
  "form.profile": "Profil",
  "form.signout": "Çıkış yap",
  "form.error.weakPassword":
    "Şifre en az 8 karakterden oluşup en az 3 küçük harf, 2 büyük harf, 2 rakam, 1 özel karakter içermelidir.",
};

export default form
