import { gql } from "@apollo/client";

const SAVE_MID_PROFILE_INPUT = gql`
  mutation SaveMidProfile($saveMidProfileInput: SaveMidProfileInput!) {
  saveMidProfile(saveMidProfileInput: $saveMidProfileInput) {
    message
    result
    status
  }
}
`;

export default SAVE_MID_PROFILE_INPUT;
