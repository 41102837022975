import { RESPONSE_STATUS } from "@/config/constants";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


import Logo from "@/assets/logo.png";

//@ts-ignore
import "./index.less"
import { useState } from "react";
import { useMutation } from "@apollo/client";
import RESETPASSWORD from "../../api/mutations/resetPassword";




const ResetPassword = () => {
  const { t } = useTranslation();
  const [responseStatus, setResponseStatus] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mutateResetPassword, { loading, error }] = useMutation(RESETPASSWORD)

  const handleSubmit = async (values: any) => {
    try {
      const response = await mutateResetPassword({ variables: values });
      //@ts-ignore
      setResponseStatus(response.data.resetPassword.status);
    } catch (e) {
      setResponseStatus(RESPONSE_STATUS.DECLINED);
    }
    await setIsLoading(false);
  };



  const submitButtonProps = {
    loading: loading,
    size: 'large',
    style: { width: '100%' },
  };

  const EmailInput = () => {
    const placeHolder = t('pages.resetPassword.email.placeholder');

    const rules = [
      {
        required: true,
        type: 'email',
        message: t('pages.resetPassword.email.required'),
      },
    ];

    return (
      <ProFormText
        name="email"
        fieldProps={{ size: 'large' }}
        placeholder={placeHolder}
        //@ts-ignore
        rules={rules}
      />
    );
  };

  return (
    <>
      {/* @ts-ignore */}
      {responseStatus === RESPONSE_STATUS.APPROVED ? (

        <Result
          title={t("pages.resetPassword.success")}
          subTitle={t('pages.resetPassword.check.email')}
          status="success"
          extra={
            <Button type="primary" size="large">
              <Link to="/user/login">
                {t('pages.resetPassword.login.button')}
              </Link>
            </Button>
          }
        />
      ) : (
        <div
          className={"main"}
        >
          <img src={Logo} className="logo" alt="Logo" />

          <h2
            className={"header"}
          >
            {t('pages.resetPassword.reset.password')}
          </h2>
          <ProForm
            submitter={{
              render: (_, dom) => dom.pop(),
              //@ts-ignore
              submitButtonProps,
            }}
            isKeyPressSubmit={true}
            onFinish={(values) => {
              handleSubmit(values);
              return Promise.resolve();
            }}
          >
            <EmailInput />
          </ProForm>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
