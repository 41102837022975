import { Area } from '@ant-design/plots';
import getChartConfig from "@/config/charts";

export const CHART_TYPES = {
    TINY: 'TINY',
    DEFAULT: 'DEFAULT',
};

interface TinyChartProps {
    type: string,
    customConfig: any
}

const TinyChart = ({ type, customConfig }: TinyChartProps) => {
    const chartConfig = getChartConfig(type, customConfig);
    return <Area {...chartConfig} />;
}

export default TinyChart