import * as React from "react";

const SvgTransaction = (props) => (
  <svg
    className="transaction_svg__SVGInline-svg transaction_svg__SVGInline--cleaned-svg transaction_svg__SVG-svg transaction_svg__db-SideNav-icon-svg transaction_svg__db-SideNav-icon--payouts-svg"
    style={{
      width: 16,
      height: 16,
    }}
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <g fill="none">
      <path
        d="M5.538 3.4v5.64c0 .55-.444.996-1 .996-.552 0-1-.455-1-.995V3.414L1.712 5.241a.999.999 0 0 1-1.417.002.994.994 0 0 1 .002-1.416L3.83.295A.999.999 0 0 1 4.546 0c.259-.002.518.097.717.295l3.53 3.532c.4.4.394 1.026.003 1.416a.999.999 0 0 1-1.416-.002l-1.84-1.84z"
        fill="#A3ACB9"
        className="transaction_svg__db-SideNav-iconFill--secondary"
      />
      <path
        d="M12.123 12.722v-5.64a.995.995 0 0 0-1-.995c-.552 0-1 .455-1 .995v5.626l-1.826-1.826a.999.999 0 0 0-1.416-.002.994.994 0 0 0 .002 1.416l3.531 3.531c.198.199.458.297.716.296a1 1 0 0 0 .717-.296l3.531-3.531c.4-.4.393-1.026.002-1.416a.999.999 0 0 0-1.416.002l-1.84 1.84z"
        fill="#4F566B"
        className="transaction_svg__db-SideNav-iconFill--primary"
      />
    </g>
  </svg>
);

export default SvgTransaction;
