import moment from 'moment';
import _ from 'lodash';

function createNDaysData(fromDate: any, toDate: any) {
  let startOfRange = moment(fromDate);
  const endOfRange = moment(toDate);

  const days = [];

  while (startOfRange <= endOfRange) {
    days.push(startOfRange.toDate());
    startOfRange = startOfRange.clone().add(1, 'd');
  }

  return days;
}

function create24HoursData() {
  const hours = [];
  let formattedTime;
  for (let i = 0; i < 24; i++) {
    formattedTime = moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .add(i, 'hours')
      .format('H:mm');
    hours.push(formattedTime);
  }

  return hours;
}

const populateNDaysData = (data: any, fromDate: any, toDate: any) => {
  const result: { date: string; total: any; }[] = [];

  const days = createNDaysData(fromDate, toDate);
  days.forEach((day) => {
    const dataDate = _.find(data, (d) => {
      return moment(day).isSame(moment(d.date, 'M/D/YYYY'), 'day');
    });
    if (dataDate) {
      result.push({ date: day.toString(), total: dataDate.total });
    } else {
      result.push({ date: day.toString(), total: 0 });
    }
  });

  return result;
};

const populate24HoursData = (data: any) => {
  const result: { date: string; total: any; }[] = [];
  const hours = create24HoursData();
  hours.forEach((hour) => {
    const hourPosition = _.indexOf(data?.hour, hour);
    if (hourPosition > -1) {
      result.push({ date: hour, total: data.count[hourPosition] });
    } else {
      result.push({ date: hour, total: 0 });
    }
  });

  return result;
};

const exportDateFixer = (date: any, suffix: any) => {
  let newDate = date;

  if (!moment.isMoment(date)) {
    newDate = moment(date);
  }
  return `${newDate.format('YYYY-MM-DD')}${suffix ? ` ${suffix}` : ''}`;
};

export { createNDaysData, populateNDaysData, populate24HoursData, exportDateFixer };
