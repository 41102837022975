import {Button, Col, Row, Tooltip} from "antd";
import {ProFormText} from "@ant-design/pro-components";
import {CloseCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const BankCredentials = ({ acquirerReference, setAcquirerReference }: any) => {
    const { t } = useTranslation();

    const removeAcquirerReferenceInput = (index: number) => {
        // @ts-ignore
        setAcquirerReference((acquirerReference : any) => acquirerReference.filter((_, i) => i !== index));
    };

    const changeAcquirerReferenceInput = (name: string, value: string, index: number) => {
        setAcquirerReference((acquirerReference : any) => {
            const list = [...acquirerReference];
            list[index][name] = value;
            return list;
        });
    };
    return(
        <>
            <h3 style={{ marginTop: 30, marginBottom: 30, borderTop: "1px solid #CCC", paddingTop: 30 }}>{t("pos.bank.credentials")}</h3>
            {acquirerReference?.map(({ id, code, referenceNo, readOnly, tooltip, readOnlyRefNo }: any, index:any) => (
                <Row aria-colcount={24} key={index}>
                    <Col span={7} style={{ paddingRight: 20 }}>
                        <ProFormText
                            rules={[{ required: true }]}
                            name={`code_${id}`}
                            placeholder="Code"
                            initialValue={code}
                            fieldProps={{
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    changeAcquirerReferenceInput('code', e.target.value, index);
                                },
                                disabled: readOnly,
                                suffix: <Tooltip title={tooltip || "Code"} placement="top" > <QuestionCircleOutlined style={{ cursor: "help" }} />
                                </Tooltip>,

                            }}
                        />
                    </Col>
                    <Col span={7} style={{ paddingRight: 20 }}>
                        <ProFormText
                            name={`refNo_${id}`}
                            placeholder="Reference No"
                            disabled={readOnlyRefNo}
                            initialValue={referenceNo} style={{ width: "100%" }}
                            fieldProps={{
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    changeAcquirerReferenceInput('referenceNo', e.target.value, index)
                                }
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        {
                            !readOnly &&
                            <Button
                                onClick={() => {
                                    removeAcquirerReferenceInput(index)
                                }}
                                icon={<CloseCircleOutlined />} danger>
                                {t("pos.remove")}
                            </Button>
                        }
                    </Col>
                </Row>
            ))}
        </>
    )
}

export default BankCredentials;