const getUndue = async (
    params: any,
    getValorDateReport: any,
    initialVariables: any
) => {
    let parameters = {
        ...initialVariables,
        current: params.current,
        pageSize: params.pageSize,
        page: params.current,
    };
    if (params.dateRange) {
        parameters = {
            ...parameters,
            valorFromDate: params.dateRange[0],
            valorToDate: params.dateRange[1],
        };
    }

    const data = await getValorDateReport({
        variables: parameters,
    });

    const newArr = Object.entries(data.data.getValorDateReport.response).map(
        ([key, value]) => ({ date: key, merchant: value })
    );

    return {
        data: newArr,
        success: true,
        total: data.data.getValorDateReport.status,
    };
};

export { getUndue };
