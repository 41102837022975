// Libraries
import { useTranslation } from "react-i18next";

// Components
import { Modal } from "antd";

interface BaseModalProps {
	visible: boolean;
	footer?: boolean;
	title: string;
	cancelText?: string;
	okText?: string;

	onOk: () => void;
	onCancel: () => void;

	children?: JSX.Element;
}

export default function BaseModal(props: BaseModalProps) {
	const { t } = useTranslation();
	const {
		cancelText = t("modals.buttons.cancel"),
		okText = t("modals.buttons.save"),
		children,
	} = props;

	return (
		<Modal {...props} cancelText={cancelText} okText={okText}>
			{children}
		</Modal>
	);
}
