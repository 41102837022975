import React from "react";
import { RESPONSE_STATUS } from "@/config/constants";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

export const AlertComp = ({ message = "", requestStatus = "" }) => {
  const { t } = useTranslation()
  const alertObj: any = {
    [RESPONSE_STATUS.APPROVED]: {
      type: 'success',
      alertTitle: t("transaction.refundSuccess"),
    },
    [RESPONSE_STATUS.DECLINED]: {
      type: 'error',
      alertTitle: t("transaction.errorMessage")
    },
    message,
  }
  return (
    <Alert
      //@ts-ignore
      title={t('transaction.preAuth')}
      description={message}
      message={alertObj[requestStatus]?.alertTitle}
      type={alertObj[requestStatus]?.type}
    />
  )
};
