import { useSearchParams } from "react-router-dom";
import "@/layouts/checkout/index.less";
import {useEffect, useState} from "react";
import {RESPONSE_STATUS} from "@/config/constants";
import { useTranslation } from "react-i18next";

const ResponseStatus = ({ type }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const status = searchParams.get("status");
    const id = searchParams.get("id");
    const lang = searchParams.get("lang");
    const [payment, setPayment] = useState({
        name: "",
        desc: "",
        currency: "",
        id: "",
        imageUrlLarge: "",
        imageUrlMedium: "",
        stock: "",
        amount: "",
        cargo: ""
    });

    const messages = {
        [RESPONSE_STATUS.APPROVED]: {
            tr: "ÖDEMENİZ ALINDI",
            en: "PAYMENT SUCCESS"
        },
        [RESPONSE_STATUS.ERROR]: {
            tr: "ÖDEME HATASI",
            en: "PAYMENT ERROR"
        },
        [RESPONSE_STATUS.DECLINED]: {
            tr: "ÖDEME HATASI",
            en: "PAYMENT ERROR"
        },
        [RESPONSE_STATUS.PENDING]: {
            tr: "ÖDEMENİZ BEKLEMEDE",
            en: "PAYMENT WAITING"
        },
        [RESPONSE_STATUS.WAITING]: {
            tr: "ÖDEMENİZ BEKLEMEDE",
            en: "PAYMENT WAITING"
        }
    };

    const getPayment = async () => {
        const response = await fetch(`${process.env.REACT_APP_REST_API}/pay-to-link/payment?id=${id}`);
        const data = await response.json();
        setPayment(data.result);
    }

    useEffect(() => {
        if(type === "payment") {
            getPayment();
        }
    }, []);
    return (
        <div className="payment-status-page">
                <div className={`payment-status 
                ${status === RESPONSE_STATUS.APPROVED ? "success" :
                    ((status === RESPONSE_STATUS.ERROR || status === RESPONSE_STATUS.DECLINED) ? "error" 
                        : "waiting")}`}>
                    <div>
                        <div className="payment-status-title">
                            {/*@ts-ignore*/}
                            {messages[status][lang]}
                        </div>
                        {type === "payment" && (
                            <div className="payment-status-product">
                                <div className="product-img">
                                    <img src={payment.imageUrlLarge}/>
                                </div>
                                <div>
                                    <div className="product-name">
                                        {payment.name}
                                    </div>
                                    <div className="product-description">
                                        {payment.desc}
                                    </div>
                                    <div  className="product-price-wrapper">
                                        <span className="product-price">{payment.amount} {payment.currency}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
        </div>
    )
}

export default ResponseStatus