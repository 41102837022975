import EXPORT_SETTLEMENTS from "@/features/financial-management/api/mutations/export-settlements";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useRef } from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { ProForm, ProFormDatePicker } from "@ant-design/pro-form";
import moment from "moment";

const PosStatement = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { email, activeMerchantId } = useSelector(
    (state: RootState) => state.auth.params
  );
  const [exportSettlements] =
    useMutation(EXPORT_SETTLEMENTS);
  const success = () => {
    Modal.success({
      title: <p> {t("transaction.export")} </p>,
      content: <p>{t("transaction.export.approved")}</p>,
      okText: <p> {t("transaction.export.okText")} </p>,
    });
  };

  const errorModal = () => {
    Modal.error({
      title: <p> {t("transaction.export")} </p>,
      content: <p>{t("transaction.export.error")}</p>,
      okText: <p> {t("transaction.export.okText")} </p>,
    });
  };

  const dateRangeError = () => {
    Modal.error({
      title: <p> {t("transaction.export")} </p>,
      content: <p>{t("transaction.export.dateError")}</p>,
      okText: <p> {t("transaction.export.okText")} </p>,
    });
  };

  const onExport = async (exportType: any) => {
    // @ts-ignore
    const dateRange = formRef.current.getFieldValue("dateRange");

    const date = dateRange?._d || dateRange?.$d

    if (date) {

      const fromDate = moment(date).startOf('month').format('YYYY-MM-DD')
      const toDate = moment(date).endOf('month').format('YYYY-MM-DD')

      await exportSettlements({
        variables: {
          customerExportEmail: email,
          fromDate,
          toDate,
          merchantId: String(activeMerchantId),
          exportType
        },
      }).then((res) => {
        const { status } = res.data.exportSettlements;
        if (status === "APPROVED") {
          success();
        } else {
          errorModal();
        }
      });
    } else {
      dateRangeError();
    }
  };

  return (
    <PageContainer>
      <ProForm
        formRef={formRef}
        //@ts-ignore
        onFinish={() => {
          onExport("xlsx");
        }}
      >
        <ProFormDatePicker name="dateRange"
          dataFormat="dateRange"
          width={250}
          fieldProps={{
            picker: "month",
            format: 'YYYY/MM',
          }}
          label={t("pos-statement.label")} />
      </ProForm>
    </PageContainer>
  );
};

export default PosStatement;
