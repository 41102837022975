import {gql} from "@apollo/client";

const GET_MERCHANT = gql`
  query ($merchantId: String!) {
    getMerchant(merchantId: $merchantId) {
      message
      status
      merchant {
        status3D
        tradeName
        type
        email
        iban
        name
        merchantType
      }
    }
  }
`;

export default GET_MERCHANT