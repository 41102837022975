import { gql } from "@apollo/client";

const DELETE_PAY_TO_LINK = gql`
  mutation DeletePayToLink($deletePayToLinkId: ID!) {
  deletePayToLink(id: $deletePayToLinkId) {
    status
    message
  }
}
`;

export default DELETE_PAY_TO_LINK;
