import { gql } from "@apollo/client";

const SAVE_MID_RATES = gql`
  mutation SaveMidRates(
    $merchantId: Int!
    $midId: Int!
    $onusCredit: Float
    $domesticDebit: Float
    $domesticCredit: Float
    $internationalDebit: Float
    $internationalCredit: Float
    $domesticAmexCredit: Float
    $internationalAmexCredit: Float
    $blockageDayNumberForInstallmentSale: String
    $blockageDayNumberForOnusDebitSale: String
    $blockageDayNumberForOnusCreditSale: String
    $blockageDayNumberForDomesticDebitSale: String
    $blockageDayNumberForDomesticCreditSale: String
    $blockageDayNumberForInternationalDebitSale: String
    $blockageDayNumberForInternationalCreditSale: String
    $blockageDayNumberForDomesticAmexCreditSale: String
    $blockageDayNumberForInternationalAmexCreditSale: String
    $installmentNumber: [Int]
    $installmentRate: [Float]
    $onusDebit: Float
  ) {
    saveMidRates(
      merchantId: $merchantId
      midId: $midId
      onus_credit: $onusCredit
      domestic_debit: $domesticDebit
      domestic_credit: $domesticCredit
      international_debit: $internationalDebit
      international_credit: $internationalCredit
      domestic_amex_credit: $domesticAmexCredit
      international_amex_credit: $internationalAmexCredit
      blockageDayNumberForInstallmentSale: $blockageDayNumberForInstallmentSale
      blockageDayNumberForOnusDebitSale: $blockageDayNumberForOnusDebitSale
      blockageDayNumberForOnusCreditSale: $blockageDayNumberForOnusCreditSale
      blockageDayNumberForDomesticDebitSale: $blockageDayNumberForDomesticDebitSale
      blockageDayNumberForDomesticCreditSale: $blockageDayNumberForDomesticCreditSale
      blockageDayNumberForInternationalDebitSale: $blockageDayNumberForInternationalDebitSale
      blockageDayNumberForInternationalCreditSale: $blockageDayNumberForInternationalCreditSale
      blockageDayNumberForDomesticAmexCreditSale: $blockageDayNumberForDomesticAmexCreditSale
      blockageDayNumberForInternationalAmexCreditSale: $blockageDayNumberForInternationalAmexCreditSale
      installmentNumber: $installmentNumber
      installmentRate: $installmentRate
      onus_debit: $onusDebit
    ) {
      message
      result
      status
    }
  }
`;

export default SAVE_MID_RATES;
