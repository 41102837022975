import { useTranslation } from "react-i18next";
import { toPath, get } from "lodash";
import { findTransactionStatusColor } from "@/utils/transaction";
import { Tag } from "antd";
import { formatAmount } from "@/utils/finance";
import DateCell from "@/components/base/table/date-cell";
import { PageContainer } from "@ant-design/pro-layout";
import BaseTable from "@/components/base/table";
import { getChargebacks } from "@/features/transactions/routes/chargebacks/helper";
import GET_CHARGEBACKS from "@/features/transactions/api/queries/get-chargebacks";
import {useLazyQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import {useState} from "react";
const getLastValueFromArray = (arr: any, key: any) => arr[arr.length - 1]?.[key];

const Chargebacks = () => {
  const { t } = useTranslation();
  const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
  const [getChargebacksGQL] = useLazyQuery(GET_CHARGEBACKS);
  const [isVisibleDetails, setIsVisibleDetails] = useState(false);
  const initialVariables = {
    current: 1,
    merchantId: String(activeMerchantId),
    page: 1,
    pageSize: 50
  }

  const TABLE_COLUMNS = [
    {
      title: t('transaction.chargebacks.transactionId'),
      hideInSearch: true,
      dataIndex: 'merchantTransactionId',
    },
    {
      title: t('transaction.chargebacks.chargeback'),
      hideInSearch: true,

      dataIndex: toPath('chargeBacks'),
      renderText: (val: any) => {
        const status = getLastValueFromArray(val, 'status');
        const color = findTransactionStatusColor(status);
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: t('transaction.chargebacks.amount'),
      hideInSearch: true,
      dataIndex: toPath('chargeBacks[0]'),
      renderText: (val: any) => formatAmount(get(val, 'settledAmount'), get(val, 'settledCurrency')),
    },
    {
      title: t('transaction.chargebacks.referenceNo'),
      hideInSearch: true,
      dataIndex: toPath('chargeBacks[0].merchantReferenceNo'),
    },
    {
      title: t('transaction.chargebacks.date'),
      hideInSearch: true,
      dataIndex: toPath('chargeBacks[0].created_at'),
    },
    {
      title: t('transaction.chargebacks.updatedDate'),
      dataIndex: toPath(`chargeBacks`),
      render: (val: any) => <DateCell date={getLastValueFromArray(val, 'updated_at')} />,
      hideInSearch: true,
    },
    {
      title: t('transaction.chargebacks.dueDate'),
      hideInSearch: true,
      dataIndex: 'chargeBacks',
      sorter: true,
      render: (val: any) => <DateCell date={getLastValueFromArray(val, 'dueDate')} />,
    },
  ];
  return (
      <PageContainer>
        <BaseTable
            columns={TABLE_COLUMNS}
            request={(params: any, filter: any) =>
                getChargebacks(
                    { ...params, filter },
                    getChargebacksGQL,
                    initialVariables
                )
            }
            rowKey={(record: any) => record.merchantTransactionId}
            search={false}
        />
      </PageContainer>
  )
};

export default Chargebacks;
