import { useEffect, useRef, useState } from 'react'
import { ProForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Button, Col, Row, Space, Card, message } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GET_MID_CONFIGURATION_DETAIL from "@/features/pos-management/api/queries/get-mid-configuration-detail";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import GET_ACQUIRER_LIST from "@/features/pos-management/api/queries/get-acquirer-list";
import { ProFormDateRangePicker } from "@ant-design/pro-form";
import SAVE_MID_CONFIGURATION from "@/features/pos-management/api/mutations/save-mid-configuration";

import { bankCredentialsMock } from '@/config/bankCredentialsMock';

import { SaveOutlined, PlusOutlined } from "@ant-design/icons";
import { RESPONSE_STATUS } from '@/config/constants';
import {useTranslation} from "react-i18next";
import BankCredentials from "@/features/pos-management/components/virtual-pos/bank-credentials";

const EditMidConfiguration = () => {
    const formRef = useRef();
    const { mid } = useParams();
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const { t } = useTranslation();

    const { data: acquirersData, loading: acquirersLoading, error: acquirersError } = useQuery(GET_ACQUIRER_LIST, {
        variables: {
            merchantId: String(activeMerchantId)
        }
    })
    const { data, loading, error } = useQuery(GET_MID_CONFIGURATION_DETAIL, {
        variables: {
            midId: mid ? parseInt(mid) : mid,
            merchantId: activeMerchantId
        }
    });

    const [saveMidConfiguration] = useMutation(SAVE_MID_CONFIGURATION);

    const [acquirerReference, setAcquirerReference] = useState<Array<any>>([]);

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    }

    const midTestedOptions = [
        {
            label: "Yes",
            value: "yes"
        },
        {
            label: "No",
            value: "no"
        }
    ]
    const addAcquirerReferenceInput = () => {
        setAcquirerReference((acquirerReference : any) => [...acquirerReference, {
            id: Math.random().toString(36).slice(2),
            code: '',
            referenceNo: '',
        }]);
    };


    const onChangeAcquirerDropdown = (row: any) => {

        const selectedAcquirer = bankCredentialsMock[row.label]

        const acquirerCodes = selectedAcquirer ? [...selectedAcquirer.map((code: any) => ({
            ...code,
            id: Math.random().toString(36).slice(2),
            readOnly: true
        }))] : []
        setAcquirerReference(acquirerCodes)

    }

    useEffect(() => {
        if (data) {
            setAcquirerReference(data.getMidConfigurationDetail?.acquirerReference);
        }
    }, [data]);

    if (error || acquirersError) return <p>Error...</p>;

    if (loading || acquirersLoading) return <p>Loading...</p>;

    return (
        <PageContainer>
            <Card title={mid !== "new" ? t("pos.edit.mid.configuration") : t("pos.create.mid.configuration")} style={{ marginTop: "15px" }}>

                <ProForm
                    layout='vertical'
                    formRef={formRef}
                    {...formItemLayout}
                    submitter={{
                        render: (props, doms) => {
                            return (
                                <Row style={{ margin: "20px 0 20px 0" }}>
                                    <Col>
                                        <Space>
                                            <Button
                                                icon={<PlusOutlined />}
                                                onClick={addAcquirerReferenceInput}
                                                htmlType='button'>
                                                {t("pos.add.new.bank.credential")}
                                            </Button>
                                            <Button
                                                htmlType='submit' icon={<SaveOutlined />}>
                                                {t("pos.save")}
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            )
                        },
                    }}
                    onFinish={async (values) => {
                        const { acquirer, descriptor, midTested, name, plusInstallment, tester } = values;
                        let isValid = true;
                        const readOnlyReferences = acquirerReference.filter((ref: any) => ref.readOnly);
                        readOnlyReferences.forEach((ref: any) => {
                           if (!ref.referenceNo) {
                               isValid = false;
                           }
                        });
                        if (acquirer && descriptor && name && isValid) {
                            const requestParams = {
                                merchantId: String(activeMerchantId),
                                acquirer,
                                descriptor,
                                midTested: midTested === "yes" ? 1 : 0,
                                name,
                                plusInstallment: +plusInstallment,
                                tester,
                                acquirerReferences: acquirerReference.map((ref: any) => ({ [ref.code]: ref.referenceNo }))
                                    .reduce((a: any, b: any) => ({ ...a, ...b }), {}),
                                fromDateForPlusInstallment: values.dateRange[0],
                                toDateForPlusInstallment: values.dateRange[1],
                            };
                            console.log(acquirerReference);
                            if(mid !== "new"){
                                // @ts-ignore
                                requestParams["id"] = parseInt(mid);
                                console.log(requestParams);
                            }
                            saveMidConfiguration({
                                variables: requestParams
                            }).then((response) => {
                                const { status, message: responseMessage } = response.data.createMidConfiguration;
                                if (status !== RESPONSE_STATUS.APPROVED) {
                                    throw new Error(responseMessage);
                                }

                                message.success(responseMessage);
                            }).catch((error) => {
                                message.error(error.message);
                            });
                        } else {
                            message.error("Lütfen gerekli tüm alanları doldurun!");
                        }



                    }}
                >
                    <Row aria-colcount={24}>
                        <Col span={8} style={{ paddingRight: 20 }}>
                            <ProFormSelect
                                name="acquirer"
                                label={t("pos.acquirer")}
                                tooltip="Acquirer Seçim"
                                placeholder={t("pos.acquirer")}
                                options={acquirersData.getAcquirerList.result.map((acquirer: any) => ({
                                    label: acquirer.name,
                                    value: acquirer.id
                                }))}
                                required={true}
                                initialValue={data.getMidConfigurationDetail.acquirer}
                                fieldProps={{ onChange: (_, row) => onChangeAcquirerDropdown(row) }}
                            />
                            <ProFormText
                                name="name"
                                label={t("pos.name")}
                                placeholder={t("pos.name")}
                                required={true}
                                initialValue={data.getMidConfigurationDetail.name}
                            />
                            <ProFormText
                                name="descriptor"
                                required={true}
                                label={t("pos.description")}
                                placeholder={t("pos.description")}
                                initialValue={data.getMidConfigurationDetail.descriptor}
                            />

                        </Col>
                        <Col span={8} style={{ paddingRight: 20 }}>
                            <ProFormSelect
                                options={midTestedOptions}
                                name="midTested"
                                label={t("pos.mid.tested")}
                                initialValue={data.getMidConfigurationDetail.midTested}
                            />
                            <ProFormText
                                name="tester"
                                label={t("pos.tester")}
                                placeholder={t("pos.tester")}
                                initialValue={data.getMidConfigurationDetail.tester}
                            />
                            <ProFormText
                                name="plusInstallment"
                                label={`+ ${t("pos.installment.number")}`}
                                placeholder={`+ ${t("pos.installment.number")}`}
                                initialValue={data.getMidConfigurationDetail.plusInstallment}
                            />
                           
                        </Col>
                        <Col span={20} style={{ paddingRight: 20 }}>
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <ProFormDateRangePicker
                                    width={"lg"}
                                    name="dateRange"
                                    initialValue={[data.getMidConfigurationDetail.fromDateForPlusInstallment, data.getMidConfigurationDetail.toDateForPlusInstallment]}
                                    label={`+ ${t("pos.date.range.apply.plus.installment")}`}
                                />
                            </Space>
                        </Col>
                    </Row>

                    <BankCredentials
                        acquirerReference={acquirerReference}
                        setAcquirerReference={setAcquirerReference}
                    />
                </ProForm>

            </Card>
        </PageContainer>

    )
}

export default EditMidConfiguration