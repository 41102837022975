const SYSTEM_COLORS = {
  orange: "#f56a00",
  purple: "#7265e6",
  yellow: "#ffbf00",
  turquoise: "#00a2ae",
};

const ROLES = {
  admin: "admin",
  user: "user",
};

const ENDPOINTS = {
  auth: "/api/v3/merchant/user/login",
  transactionList: "/api/v3/transaction/list",
  subMerchantList: "/api/v3/merchant/sub/list",
  merchantData: "/api/v3/merchant/get",
  availableBalance: "/api/v3/balance/list",
  exportTransactionList: "/api/v3/merchant/asyncExport",
  exportPosStatementList: "/api/v3/merchant/asyncSettlementExport",
  transaction: "/api/v3/transaction",
  settlement: "/api/v3/settlement/list",
  refund: "/api/refund",
  export: "/api/v3/merchant/asyncExport",
  chargebackList: "/api/v3/chargeback/list",
  user: {
    create: "/api/v3/merchant/user/create",
    edit: "/api/v3/merchant/user/update",
    list: "/api/v3/merchant/user/getList",
    editPassword: "/api/v3/merchant/user/changePassword",
  },
  userProfile: "/api/v3/merchant/user/show",
  changePassword: "/api/v3/merchant/user/update",
  financialDetails: "/api/v3/settlement/config/detail",
  installmentRates: "/api/v3/settlement/save/installmentRates",
  resetPassword: "/api/v1/reset-password",
  balanceList: "/api/v3/balance/list",
  successfulTransactionsReport: "/api/v3/transactions/report",
  refundTransactionsReport: "/api/v3/refunds/report",
  voidTransactionsReport: "/api/v3/voids/report",
  transactionsCount: "/api/v3/transactions/count/byDate",
  refundBalance: "/api/v3/balance/refund",
  physicalPos: "/api/v3/agreement/merchant/transaction/list",
  capture: "/api/v3/capture",
  cancel: "/api/v3/cancel",
};

const RESPONSE_STATUS = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  ERROR: "ERROR",
  PENDING: "PENDING",
  WAITING: "WAITING",
};

const AUTH_STATUS = {
  LOGGED_IN: "LOGGED_IN",
  NOT_LOGGED_IN: "NOT_LOGGED_IN",
  FAILED: "FAILED",
};

const DEFAULT_VALUES = {
  TRANSACTIONS: {
    SELECTED_FIELDS: [
      "parents",
      "company",
      "originalAmount",
      "netAmount",
      "commission",
      "merchantRate",
      "created_at",
      "valorDate",
      "originalCurrency",
      "transactionUUID",
      "responseCode",
      "responseMessage",
      "status",
      "relatedTransactionId",
      "creditcard",
      "transactionType",
      "installment",
      "referenceNo",
      "cardCategory",
      "issuerBank",
      "customerName",
      "customerEmail",
      "cardType",
      "refunded",
    ].join("&"),
  },
  SETTLEMENTS: {
    EXPORT_FIELDS: [
      "psp=on",
      "parents=on",
      "company=on",
      "originalAmount=on",
      "netAmount=on",
      "commission=on",
      "created_at=on",
      "valorDate=on",
      "originalCurrency=on",
      "transactionUUID=on",
      "responseCode=on",
      "responseMessage=on",
      "relatedTransactionId=on",
      "creditcard=on",
      "transactionType=on",
      "installment=on",
      "referenceNo=on",
      "issuerBank=on",
      "loyalty=on",
    ].join("&"),
  },
};

export {
  SYSTEM_COLORS,
  ROLES,
  ENDPOINTS,
  AUTH_STATUS,
  DEFAULT_VALUES,
  RESPONSE_STATUS,
};
