import {Card, Table, message} from 'antd';
import "../index.less"
import {useQuery} from "@apollo/client";
import FIND_PAY_TO_LINK from "@/features/pay-with-link/api/queries/findPayToLink";
import {Modal} from "antd";
import {useState} from "react";
import ProductInfo from "@/features/pay-with-link/components/pay-with-link/modal/product-info";
import LinkInfo from "@/features/pay-with-link/components/pay-with-link/modal/link-info";

const PastLinks = ({createdLink, setCreatedLink, activeMerchantId, apiKey, links}: any) => {
    const [modalStatus, setModalStatus] = useState(false);
    const [selectedLink, setSelectedLink] = useState<any | undefined>([]);
    const [activeLink, setActiveLink] = useState("");

    const showDetailModal = (record: any) => {
        setSelectedLink(record);
        setModalStatus(true);
        const pathnamme = window.location.origin.toString();
        setActiveLink(`${pathnamme}/checkout/payment/${record.id}`)
    }
    const closeDetailModal = () => {
        setSelectedLink([]);
        setModalStatus(false);
        setActiveLink("");
        setCreatedLink(null);
    }
    const columns = [
        {
            title: 'ÜRÜN ADI',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'LİNK DURUMU',
            dataIndex: 'status',
            key: 'linkStatus',
            render: (status: boolean, record: any) =>
                    <span className={`status ${record.stock > record.limit && "active"}`}>
                        {record.stock > record.limit ? "Aktif" : "İnaktif"}
                    </span>
        },
        {
            title: 'STOK DURUMU',
            dataIndex: 'stock',
            key: 'stockStatus',
            render: (text: any, record: any, index: any) => {
                return (<> {record.limit}/{text}</>);
            }
        },
        {
            title: 'FİYAT',
            dataIndex: 'amount',
            key: 'price',
            render: (text: any, record: any, index: any) => {
                return (<> {text} <strong style={{color: "#00bda1"}}>{record.currency}</strong></>);
            }
        }
    ];

    return (
        <>
            <Card title="Geçmiş Linklerim" style={{marginTop: "15px"}}>
                {links &&
                    <Table
                        dataSource={links.findPayToLink.result}
                        columns={columns}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => showDetailModal(record), // click row
                            };
                        }}
                    />
                }
            </Card>

            <Modal
                visible={(modalStatus && !!activeLink) || (!!createdLink)}
                centered
                onCancel={() => closeDetailModal()}
                footer={false}
                width={"90%"}
                style={{maxWidth: "900px"}}
            >
                {(modalStatus && !!activeLink) && (
                    <>
                        <ProductInfo selectedLink={selectedLink}/>
                        <LinkInfo
                            id={selectedLink.id}
                            type="payment"
                            url={activeLink}/>
                    </>
                )}
                {(!!createdLink) && (
                    <>
                        <ProductInfo selectedLink={createdLink}/>
                        <LinkInfo
                            id={createdLink.id}
                            type="payment"
                            url={`${window.location.origin.toString()}/checkout/payment/${createdLink.id}`}/>
                    </>
                )}
            </Modal>
        </>
    );
};

export default PastLinks;
