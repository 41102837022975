import { useTranslation } from "react-i18next";
import { Descriptions, Space, Typography, Divider } from "antd";
import { findDummyDataValue } from "@/utils/findDummyDataValue";
const { Title } = Typography;

const CustomerDetails = ({ data }: any) => {
  const { t } = useTranslation();
  const {
    billingFirstName,
    billingLastName,
    email,
    billingPhone,
    shippingAddress1,
    shippingAddress2,
    shippingCity,
    shippingCountry,
  } = data?.customerInfo || {};

  const { customerIp } = data.transaction?.merchant?.agent || "-";
  return (
    <Descriptions
      title={
        <Space
          direction={"vertical"}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Title level={4}>{t("transaction.customerDetails")}</Title>
          <Divider style={{ margin: "0 0 4px 0" }} />
        </Space>
      }
      labelStyle={{ fontWeight: "bold" }}
    >
      <Descriptions.Item label={t("transaction.fullName")}>
        {`${billingFirstName} ${billingLastName}`}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.email")}>
        {email}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.phone")}>
        {billingPhone}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.ip")}>
        {customerIp}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.address")}>
        {`
            ${shippingAddress1 ? findDummyDataValue(shippingAddress1) : "-"}
            ${shippingAddress2 ? findDummyDataValue(shippingAddress2) : "-"}
          `}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.country")}>
        {shippingCountry}
      </Descriptions.Item>
      <Descriptions.Item label={t("transaction.city")}>
        {`${shippingCity ? findDummyDataValue(shippingCity) : "-"}`}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CustomerDetails;
