import React from "react";
import ProForm from "@ant-design/pro-form";
import EmailInput from "@/features/auth/components/inputs/email-input";
import PasswordInput from "@/features/auth/components/inputs/password-input";

interface LoginFormProps {
  onSubmit: Function;
  isLoading: boolean;
  children: React.ReactNode;
}

const LoginForm = ({ onSubmit, isLoading, children }: LoginFormProps) => {
    const submitButtonProps = {
      size: "large",
      loading: isLoading,
      style: { width: "100%" },
    };

    return (
    <ProForm
      submitter={{
        render: (_, dom) => dom.pop(),
          // @ts-ignore
          submitButtonProps,
      }}
      isKeyPressSubmit={true}
      onFinish={async (values) => {
        onSubmit(values);
      }}
    >
      {children}
      <EmailInput />
      <PasswordInput />
    </ProForm>
  );
};

export default LoginForm;
