import All from "@/features/transactions/routes/all";
import TransactionDetail from "@/features/transactions/routes/detail/transaction";
import PhysicalPos from "@/features/transactions/routes/physical-pos";
import Refund from "@/features/transactions/routes/refund";
import Chargebacks from "@/features/transactions/routes/chargebacks";

const transactionsRoutes = {
  path: "transactions",
  children: [
    {
      path: "all",
      children: [
        {
          path: "",
          element: <All />,
        },
        {
          path: ":id",
          element: <TransactionDetail />,
        },
      ],
    },
    {
      path: "physical-pos",
      element: <PhysicalPos />,
    },
    {
      path: "refund",
      children: [
        {
          path: "",
          element: <Refund />,
        },
        {
          path: ":id",
          element: <TransactionDetail />,
        },
      ],
    },
    {
      path: "chargebacks",
      element: <Chargebacks />,
    },
  ],
};

export default transactionsRoutes;
