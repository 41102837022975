import {useLazyQuery, useMutation} from "@apollo/client";
import GET_PHYSICAL_POS_TRANSACTIONS from "@/features/transactions/api/queries/get-physical-pos-transactions";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import {Button, Dropdown, Menu, Modal, Tag} from "antd";
import { useTranslation } from "react-i18next";
import { FILTER_FIELDS } from "@/utils/input";
import DateCell from "@/components/base/table/date-cell";
import { toPath, get } from "lodash";
import { findTransactionStatusColor } from "@/utils/transaction";
import { formatAmount } from "@/utils/finance";
import { PageContainer } from "@ant-design/pro-layout";
import {useEffect, useRef, useState} from "react";
import BaseTable from "@/components/base/table";
import { getPhysicalPosTransactions } from "@/features/transactions/routes/physical-pos/helper";
import {RESPONSE_STATUS} from "@/config/constants";
import EXPORT_PHYPOS_LIST from "@/features/transactions/api/mutations/export-phypos-list";
import CustomSelect from "@/components/custom-select";
import PERMISSIONS from "@/config/permissions";
import useAuthorization from "@/hooks/use-authorization";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";

const PhysicalPos = () => {
  const { t } = useTranslation();
  const { checkPermission } = useAuthorization();
  const formRef = useRef();
  const actionRef = useRef();
  const auth = useSelector((state: RootState) => state.auth.params);

  const { activeMerchantId } = useSelector((state: RootState) =>  state.auth.params);
  const [getPosTransactions] = useLazyQuery(GET_PHYSICAL_POS_TRANSACTIONS);
  const [exportPhyPosList] = useMutation(EXPORT_PHYPOS_LIST);

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isExportable, setIsExportable] = useState(false);

  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    //const [phyPosFilter, canExport] = await checkPermission([PERMISSIONS.transactions.phypos_trx.filter, PERMISSIONS.transactions.phypos_trx.export]);
    setIsExportable(true);
    setIsVisibleFilter(true);
  }

  const initialVariables = {
    current: 1,
    filterField: "pspRefNo",
    filterValue: "",
    merchantId: String(activeMerchantId),
    page: 1,
    pageSize: 50,
    fromDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  }

  const columnsStateMap = {
    amount: {
      visible: true,
    },
    cardNumber: {
      visible: false,
    },
    fullname: {
      visible: false,
    },
    customField1: {
      visible: false,
    },
    customField2: {
      visible: false,
    },
    customField3: {
      visible: false,
    },
  };

  const filters = isVisibleFilter ? [
    {
      title: t('transaction.search'),
      valueType: 'select',
      dataIndex: 'filterField',
      hideInTable: true,
      initialValue: { filterField: FILTER_FIELDS.PSP_REF_NO, filterValue: '' },
      request: async () => [
        {
          label: t('transaction.amount'),
          value: FILTER_FIELDS.ORIGINAL_AMOUNT,
        },
        {
          label: t('transaction.pspRefNo'),
          value: FILTER_FIELDS.PSP_REF_NO,
        },
        {
          label: t('transaction.transactionType'),
          value: FILTER_FIELDS.TRANSACTION_TYPE,
        },
        {
          label: t('transaction.cardNumber'),
          value: FILTER_FIELDS.CARD_PAN,
        },
        {
          label: t('transaction.referenceNo'),
          value: FILTER_FIELDS.REFERENCE_NO,
        },
        {
          label: t('transaction.rrn'),
          value: FILTER_FIELDS.RRN,
        },
        {
          label: t('transaction.terminalId'),
          value: FILTER_FIELDS.TERMINAL_ID,
        },
      ],
      renderFormItem: ({ initialValue }: any, { fieldProps, options }: any, form: any) => {
        return (
            <CustomSelect
                {...fieldProps}
                options={options}
                initialValue={initialValue}
                // Custom configuration
                placeholder="Please enter test"
            />
        );
      },
    },
    {
      title: t('transaction.date'),
      dataIndex: 'dateRange',
      valueType: 'dateRange',
      sorter: true,
      hideInTable: true,
      initialValue: [moment().subtract(1, 'days'), moment()],
      render: (value: any) => <DateCell date={value} />,
    },
    {
      title: t('transaction.status'),
      valueType: 'select',
      dataIndex: 'status',
      hideInTable: true,
      fieldProps: {
        mode: 'multiple',
        style: {
          maxWidth: '90%',
        },
        showArrow: true,
      },
      request: async () => [
        {
          label: t('transaction.status.waiting'),
          value: 'WAITING',
        },
        {
          label: t('transaction.status.approved'),
          value: 'APPROVED',
        },
        {
          label: t('transaction.status.declined'),
          value: 'DECLINED',
        },
        {
          label: t('transaction.status.error'),
          value: 'ERROR',
        },
      ],
    },
  ] : [];

  const columns = [
    {
      title: t('transaction.merchantName'),
      hideInSearch: true,
      key: 'merchantName',
      dataIndex: toPath('merchant.name'),
    },
    {
      title: t('transaction.transactionId'),
      dataIndex: toPath('pspRefNo'),
      key: 'pspRefNo',
      hideInSearch: true,
    },
    {
      title: t('transaction.rrn'),
      hideInSearch: true,
      key: 'rrn',
      dataIndex: toPath('rrn'),
    },
    {
      title: t('transaction.referenceNo'),
      hideInSearch: true,
      key: 'referenceNo',
      dataIndex: toPath('refNo'),
    },
    {
      title: t('transaction.terminalId'),
      hideInSearch: true,
      key: 'terminalId',
      dataIndex: toPath('terminalId'),
    },
    {
      title: t('transaction.date'),
      dataIndex: 'date',
      key: 'date',
      hideInSearch: true,
      render: (value: any) => {
        return <DateCell date={value} />;
      },
    },
    {
      title: t('transaction.type'),
      hideInSearch: true,
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: t('transaction.status'),
      dataIndex: 'status',
      key: 'status',
      hideInSearch: true,
      render: (value: any) => {
        const color = findTransactionStatusColor(value);
        const lowerCaseStatus = value?.toLowerCase();
        return (
            <Tag color={color}>
              {t(`transaction.status.${lowerCaseStatus}`)}{' '}
            </Tag>
        );
      },
    },
    {
      title: t('transaction.message'),
      key: 'message',
      hideInSearch: true,
      dataIndex: toPath('transaction.merchant.message'),
    },
    {
      title: t('transaction.installment'),
      key: 'installment',
      hideInSearch: true,
      dataIndex: toPath('installment'),
    },
    {
      title: t('transaction.amount'),
      hideInSearch: true,
      key: 'amount',
      dataIndex: toPath('amount'),
      renderText: (val: any, row: any) => formatAmount(val, get(row, 'currency')),
    },
    {
      title: t('transaction.fullName'),
      hideInSearch: true,
      key: 'fullname',
      dataIndex: toPath('customerInfo'),
      renderText: (val: any) => (
          <>
            {val?.billingFirstName} {val?.billingLastName}{' '}
          </>
      ),
    },
    {
      title: t('transaction.cardNumber'),
      hideInSearch: true,
      key: 'cardNumber',
      dataIndex: toPath('customerInfo.number'),
      renderText: (cardNumber: any) => cardNumber?.replaceAll('X', 'x'),
    },
  ];

  const TABLE_COLUMNS = [...filters, ...columns];



  const toolBarRender = () => {
    const menu = (
      <Menu>
        <Menu.Item onClick={() => onExport('csv')} key="1">
          CSV
        </Menu.Item>
        <Menu.Item onClick={() => onExport('xlsx')} key="2">
          Excel
        </Menu.Item>
      </Menu>
    );
    if (isExportable) {
      return [
        <Dropdown key=" menu " overlay={menu} arrow={true} trigger={["click"]}>
          <Button>
            {t("transaction.export")}
            <DownOutlined />
          </Button>
        </Dropdown>,
      ];
    }
    return null;
  };

  const onExport = async (exportType: string) => {
    //@ts-ignore
    const dateRange = formRef.current.getFieldValue('dateRange');
    if (dateRange) {
      const [fromDate, toDate] = dateRange;
      const variables = {
        fromDate,
        toDate,
        merchantId: String(auth.activeMerchantId),
        customerExportEmail: auth.email,
        exportType,
      }
      exportPhyPosList({
        variables
      }).then((response) => {
        const { status } = response.data.exportPhysicalPosList;
        if(status === RESPONSE_STATUS.APPROVED) {
          Modal.success({
            title: t("transaction.status.approved"),
            content: t("transaction.export.approved")
          });
        }
      })
    } else {
      Modal.error({
        title: t("transaction.status.error"),
        content: t("transaction.export.dateError")
      });
    }
  };


  return (
      <PageContainer>
        <BaseTable
            columns={TABLE_COLUMNS}
            formRef={formRef}
            actionRef={actionRef}
            toolBarRender={toolBarRender}
            columnsState={{
              defaultValue: columnsStateMap
            }}
            request={(params: any, filter: any) =>
                getPhysicalPosTransactions(
                    { ...params, filter },
                    getPosTransactions,
                    initialVariables
                )
            }
        />
      </PageContainer>
  )
};

export default PhysicalPos;
