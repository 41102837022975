import {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { get, toPath } from "lodash";
import { Link } from "react-router-dom";
import {Button, Dropdown, Menu, Modal, Tag} from "antd";
import DateCell from "@/components/base/table/date-cell";
import { findTransactionStatusColor } from "@/utils/transaction";
import { formatAmount } from "@/utils/finance";
import {exportFile, getTransactions} from "@/features/transactions/routes/all/helper";
import GET_TRANSACTIONS from "@/features/transactions/api/queries/get-transactions";
import BaseTable from "@/components/base/table";
import {useLazyQuery, useMutation} from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { FILTER_FIELDS } from "@/utils/input";
import EXPORT_TRANSACTION_LIST from "@/features/transactions/api/mutations/export-transaction-list";
import {DownOutlined} from "@ant-design/icons";
import {RESPONSE_STATUS} from "@/config/constants";
import CustomSelect from "@/components/custom-select";
import PERMISSIONS from "@/config/permissions";
import useAuthorization from "@/hooks/use-authorization";

const Refund = () => {
  const { t } = useTranslation();
  const { checkPermission } = useAuthorization();
  const auth = useSelector((state: RootState) => state.auth.params);
  const [getTransactionsGQL] =
    useLazyQuery(GET_TRANSACTIONS);
  const [exportTransactionList] = useMutation((EXPORT_TRANSACTION_LIST));
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleDetails, setIsVisibleDetails] = useState(false);
  const [isExportable, setIsExportable] = useState(false);

  const formRef = useRef();

  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    //const [refundFilter, refundDetails, canExport] = await checkPermission([PERMISSIONS.transactions.refund.filter, PERMISSIONS.transactions.refund.detail.show, PERMISSIONS.transactions.refund.export])
    setIsVisibleFilter(true);
    setIsVisibleDetails(true);
    setIsExportable(true);
  }

  const initialVariables = {
    pageSize: 50,
    page: 1,
    current: 1,
    merchantId: String(auth.activeMerchantId),
    operation: ["REFUND", "VOID"],
  };
  const filters = isVisibleFilter ? [
    {
      title: t("transaction.search"),
      hideInTable: true,
      dataIndex: "filterField",
      valueType: "select",
      initialValue: {
        filterField: "transactionId",
        filterValue: "",
      },
      request: async () => {
        return [
          {
            label: t("transaction.amount"),
            value: FILTER_FIELDS.ORIGINAL_AMOUNT,
          },
          {
            label: t("transaction.transactionId"),
            value: FILTER_FIELDS.TRANSACTION_ID,
          },
          {
            label: t("transaction.cardNumber"),
            value: FILTER_FIELDS.CARD_PAN,
          },
          {
            label: t("transaction.email"),
            value: FILTER_FIELDS.CUSTOMER_EMAIL,
          },
          {
            label: t("transaction.referenceNo"),
            value: FILTER_FIELDS.REFERENCE_NO,
          },
        ];
      },
      renderFormItem: ({ initialValue }: any, { fieldProps, options }: any) => {
        return (
            <CustomSelect
                {...fieldProps}
                options={options}
                initialValue={initialValue}
                placeholder="Please enter test"
            />
        );
      }
    },
    {
      title: t("transaction.date"),
      valueType: "dateRange",
      dataIndex: "dateRange",
      hideInTable: true,
    },
    {
      title: t("transaction.status"),
      valueType: "select",
      dataIndex: "status",
      hideInTable: true,
      fieldProps: {
        mode: "multiple",
        style: {
          maxWidth: "90%",
        },
      },
      request: async () => [
        {
          label: t("transaction.status.waiting"),
          value: "WAITING",
        },
        {
          label: t("transaction.status.approved"),
          value: "APPROVED",
        },
        {
          label: t("transaction.status.declined"),
          value: "DECLINED",
        },
        {
          label: t("transaction.status.error"),
          value: "ERROR",
        },
      ],
    },
    {
      title: t("transaction.transactionType"),
      dataIndex: "operation",
      hideInTable: true,
      fieldProps: {
        mode: "multiple",
        style: {
          maxWidth: "90%",
        },
        showArrow: true,
      },
      request: async () => [
        {
          label: t("transaction.refund"),
          value: "REFUND",
        },
        {
          label: t("transaction.void"),
          value: "VOID",
        },
      ],
    },
  ] : [];
  const columns = [
    {
      title: t("transaction.name"),
      dataIndex: toPath("merchant.name"),
      hideInSearch: true,
    },
    {
      title: t("transaction.transactionId"),
      dataIndex: toPath("transaction.merchant.transactionId"),
      hideInSearch: true,
      render: (value: string,transaction:any) => {
        if (isVisibleDetails) {
          return (
          <Link to={`/transactions/refund/${value}`}
          state={{
          selectedTransactionValorDate: transaction?.valorDate,
          selectedCustomerInfo: transaction?.customerInfo
          }} >
            {value}
          </Link>
          )
        }
        return value;
      }
    },
    {
      title: t("transaction.referenceNo"),
      dataIndex: toPath("transaction.merchant.referenceNo"),
      hideInSearch: true,
    },
    {
      title: t("transaction.date"),
      dataIndex: toPath("transaction.merchant.created_at"),
      hideInSearch: true,
      render: (value: any) => {
        return <DateCell date={value} />;
      },
    },
    {
      title: t("transaction.type"),
      hideInSearch: true,
      dataIndex: toPath("transaction.merchant.operation"),
      render: (value: string) => {
        return t(`transaction.operation.${value}`);
      },
    },
    {
      title: t("transaction.status"),
      dataIndex: toPath("transaction"),
      hideInSearch: true,
      render: (value: any) => {
        const { status } = value?.merchant;
        const color = findTransactionStatusColor(status);
        const lowerCaseStatus = status.toLowerCase();
        return (
          <Tag color={color}>{t(`transaction.status.${lowerCaseStatus}`)}</Tag>
        );
      },
    },
    {
      title: t("transaction.message"),
      hideInSearch: true,
      dataIndex: toPath("transaction.merchant.message"),
    },
    {
      title: t("transaction.installment"),
      dataIndex: toPath("transaction.merchant.installment"),
      hideInSearch: true,
    },
    {
      title: t("transaction.amount"),
      hideInSearch: true,
      key: "amount",
      dataIndex: toPath("fx.merchant.originalAmount"),
      renderText: (val: any, row: any) =>
        formatAmount(val, get(row, "fx.merchant.originalCurrency")),
    },
    {
      title: t("transaction.fullName"),
      dataIndex: toPath("customerInfo.billingFirstName"),
      hideInSearch: true,
      key: "fullName",
      renderText: (val: any, row: any) => {
        return `${val} ${get(row, "customerInfo.billingLastName")}`;
      },
    },
    {
      title: t("transaction.cardNumber"),
      dataIndex: toPath("customerInfo.number"),
      hideInSearch: true,
      key: "cardNumber",
    },
  ];

  const toolBarRender = () => {
    const menu = (
        <Menu>
          <Menu.Item onClick={() => onExport('csv')} key=" 1 ">
            CSV
          </Menu.Item>
          <Menu.Item onClick={() => onExport('xlsx')} key=" 2 ">
            Excel
          </Menu.Item>
        </Menu>
    );
    if (isExportable) {
      return [
        <Dropdown key="menu" overlay={menu} arrow={true} trigger={["click"]}>
          <Button>
            { t("transaction.export") }
            <DownOutlined />
          </Button>
        </Dropdown>,
      ];
    }
    return null;
  };

  const onExport = async (exportType: string) => {
    //@ts-ignore
    const dateRange = formRef.current.getFieldValue('dateRange');
    //@ts-ignore
    const status = formRef.current.getFieldValue('status');
    //@ts-ignore
    const operation = formRef.current.getFieldValue('operation');
    //@ts-ignore
    const trxListType = formRef.current.getFieldValue('trxListType');
    if (dateRange) {
      const requestParams = {
        exportType,
        dateRange,
        status,
        operation,
        trxListType,
        email: auth.email
      }
      const variables = exportFile(requestParams);
      //@ts-ignore
      variables.merchantId = String(auth.activeMerchantId);
      exportTransactionList({
        variables
      }).then((response) => {
        const { status } = response.data.exportTransactionList;
        if(status === RESPONSE_STATUS.APPROVED) {
          Modal.success({
            title: t("transaction.status.approved"),
            content: t("transaction.export.approved")
          });
        }
      })
    } else {
      Modal.error({
        title: t("transaction.status.error"),
        content: t("transaction.export.dateError")
      });
    }
  };


  const TABLE_COLUMNS = [...filters, ...columns];
  return (
    <BaseTable
      //@ts-ignore
      request={(params: any, filter: any) =>
        getTransactions(
          { ...params, filter },
          getTransactionsGQL,
          initialVariables
        )
      }
      columns={TABLE_COLUMNS}
      formRef={formRef}
      rowKey={(record: any) => record.transaction.merchant.transactionId}
      toolBarRender={toolBarRender}
    />
  );
};

export default Refund;
