import {Button, Card} from "antd";
import {useTranslation} from "react-i18next";
import ProfileFilters from "@/features/pos-management/routes/mid-profile/filters";
import BaseTable from "@/components/base/table";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import {useQuery} from "@apollo/client";
import GET_MID_PROFILE_LIST from "@/features/pos-management/api/queries/get-mid-profile";
import {CreditCardOutlined} from "@ant-design/icons";
import moment from "moment/moment";

const MidProfile = () => {
    const {t} = useTranslation()

    const [result, setResult] = useState([]);
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);

    const { data, error, loading } = useQuery(GET_MID_PROFILE_LIST, {
        variables: {
            merchantId: String(activeMerchantId)
        }
    });

    const columns = [
        {
            title: t("Profil Adı"),
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: t("Varsayılan"),
            dataIndex: 'default',
            key: 'name',
            align: 'center',
            render: (value: number) => {
                return <label>{value === 1 ? t("pos.default") : t("pos.notDefault")}</label>
            },
        },
        {
            title: t("Oluşturulma Tarihi"),
            dataIndex: 'created_at',
            key: 'name',
            align: 'center'
        },
        {
            title: t("Güncellenme Tarihi"),
            dataIndex: 'updated_at',
            key: 'name',
            align: 'center'
        },
        {
            dataIndex: 'edit',
            key: 'edit',
            width:'200px',
            align: 'center',
            render: (intRate: any, item: any) => {
                return (
                    <div style={{display:"flex", gap: "5px", justifyContent: "center"}}>
                        <Link to={`/pos-management/pos-profile/${item.merchantId}/${item.id}`}>
                            <Button style={{border: "1px solid #1677ff", color: "#1677ff", boxShadow: "none"}}> {t("pos.edit")} </Button>
                        </Link>
                    </div>
                );
            }
        }
    ];
    const clearSearch = () => {
        setResult(data.getMidProfileList.configGroups)
    }

    useEffect(() => {
        if (data) {
            if (data.getMidProfileList.configGroups.length) {
                setResult(data.getMidProfileList.configGroups);
            }
        }
    }, [data]);


    const search = (values: any) => {
        const filterItem = (item: any, values: any) => {
            const keys = Object.keys(values);
            for (const key of keys) {
                if (key === "default") {
                    const selectedDefault = item["default"];
                    if (values[key] !== null && values[key].toString() !== selectedDefault.toString() && values[key].toString() !== item.default.toString()) {
                        return false;
                    }
                }
                else if (key === "created_at" || key === "updated_at") {
                    const [beginDate, endDate] = values[key];

                    if (beginDate && endDate) {
                        const dateField = key === "created_at" ? "created_at" : "updated_at";
                        const selectedDate = moment(item[dateField]);
                        if (!selectedDate.isBetween(beginDate, endDate)) {
                            return false;
                        }
                    }
                } else {
                    if (item[key].toString() !== values[key].toString()) {
                        return false;
                    }
                }
            }
            return true;
        };

        const filteredResult = data.getMidProfileList.configGroups.filter((item: any) => filterItem(item, values));
        setResult(filteredResult);
    }
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error...</p>;
    return (
        <Card title={t("pos.midProfile")} style={{marginTop: "15px"}} extra={<Link to={`/pos-management/pos-profile/${activeMerchantId}/new`}> <Button type={"default"} icon={<CreditCardOutlined/>}>{t("pos.create.midProfile")}</Button> </Link>}>
            <ProfileFilters profiles={result} search={search} clear={clearSearch}/>
            <BaseTable
                columns={columns}
                dataSource={result}
                bordered
                search={false}
                pagination={false}
                options={false}
            />
        </Card>


    )
}

export default MidProfile