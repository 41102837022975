import Welcome from "@/features/dashboard/routes/welcome";

const dashboardRoutes = {
  path: "/",
  children: [
    {
      path: "welcome",
      element: <Welcome />,
    }
  ]
};

export default dashboardRoutes;
