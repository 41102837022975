import moment from "moment";

const getAwaiting = async (
  params: any,
  getAwaitingSettlements: any,
  initialVariables: any
) => {
  let parameters = {
    ...initialVariables,
    current: params.current,
    pageSize: params.pageSize,
    page: params.current,
  };

  if (params.dateRange) {
    let startOfRange = moment(params.dateRange[0]).subtract(1, "d").format("YYYY-MM-DD");
    let endOfRange = moment(params.dateRange[1]).subtract(1, "d").format("YYYY-MM-DD");

      parameters = {
        ...parameters,
        valorFromDate: startOfRange,
        valorToDate: endOfRange,
      };
  }

  const data = await getAwaitingSettlements({
    variables: parameters,
  });

  const newArr = Object.entries(data.data.getAwaitingSettlements.response).map(
    ([key, value]) => ({ date: key, merchant: value })
  );

  return {
    data: newArr,
    success: true,
    total: data.data.getAwaitingSettlements.status,
  };
};

export { getAwaiting };
