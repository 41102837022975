import PayWithLink from "@/features/pay-with-link/routes/pay-with-link";
import EditPayWithLink from "@/features/pay-with-link/routes/edit";

const payWithLinkRoutes = {
  path: "pay-with-link",
  children: [
    {
      path: '',
      element: <PayWithLink/>
    },
    {
      path: "edit/:id",
      element: <EditPayWithLink/>
    }
  ]
};

export default payWithLinkRoutes;