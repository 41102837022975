import { MenuDataItem, ProLayout } from "@ant-design/pro-layout";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import logo from "@/assets/logo.png";
import {Link, Outlet, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import HeaderRight from "@/components/header/right";
import Authenticated from "@/hoc/authenticated";
import { getDefaultMenus, IconMap } from "@/config/dashboard/menus";
import { useTranslation } from "react-i18next";
import useAuthorization from "@/hooks/use-authorization";
import useAuth from "@/hooks/use-auth";
import {useEffect, useState} from "react";
import GET_MERCHANT from "@/api/queries/get-merchant";
import {useSelector} from "react-redux";
import {RootState} from "@/store";

interface DashboardProps {
  children?: any;
}

const Dashboard = ({ children }: DashboardProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { permissions } = useAuthorization();
  const [searchParams, setSearchParams] = useSearchParams();
  const isIFrame = searchParams.get("isIFrame");
  const [getMerchantDetail] = useLazyQuery(GET_MERCHANT);
  const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
  const [menus, setMenus] = useState<any>();
  const [isWaiting, setIsWaiting] = useState(true);
  const [isVisiblePosManagement, setIsVisiblePosManagement] = useState<any>();

  const renderMenuItem = (menuItemProps: any, defaultDom: any) => {
    const { isUrl, path } = menuItemProps;
    if (isUrl || !path || window.location.pathname === path) {
      return defaultDom;
    }
    return <Link to={path}>{defaultDom}</Link>;
  };
  const loopMenuItem = (menus: any[]): MenuDataItem[] =>
    menus.map(({ icon, routes, ...item }) => ({
      ...item,
      // @ts-ignore
      icon: icon && IconMap[icon as string],
      children: routes && loopMenuItem(routes),
    }));

  const renderHideMenuButton = (collapsed?: boolean) =>
    collapsed ? (
      <MenuUnfoldOutlined />
    ) : (
      <>
        <MenuFoldOutlined />
        <span className="hide-menu">{t("menu.hideMenu")}</span>
      </>
    );

  useEffect(() => {
      if (isAuthenticated) {
          getMerchantDetail({
              variables: {
                  merchantId: String(activeMerchantId)
              }
          }).then((response) => {
              const { merchantType } = response.data.getMerchant.merchant;
              const merchantTypes = JSON.parse(merchantType);
              if (merchantTypes.includes("TSP")) {
                  setIsVisiblePosManagement(true);
              } else {
                  setIsVisiblePosManagement("none");
              }
          }).catch((error) => {
              console.error(error);
          })
      } else {
          setIsWaiting(false);
      }
  }, [isAuthenticated]);

  useEffect(() => {
      if (!!isVisiblePosManagement) {
          if (isVisiblePosManagement === "none") {
              setMenus(getDefaultMenus([], false));
          } else {
              setMenus(getDefaultMenus([], isVisiblePosManagement));
          }
          setIsWaiting(false);
      }
  }, [isVisiblePosManagement]);

  if(isWaiting) return <p>Loading...</p>;

  return (
    <Authenticated>
      <ProLayout
        colorWeak={false}
        contentWidth="Fluid"
        fixSiderbar={true}
        fixedHeader={false}
        hasSiderMenu={!isIFrame}
        siderWidth={isIFrame ? 0 : 270}
        layout="side"
        navTheme="light"
        primaryColor="#5469d4"
        defaultSelectedKeys={[location.pathname]}
        title=""
        onMenuHeaderClick={() => navigate("/welcome")}
        logo={logo}
        menu={{ request: async () => loopMenuItem(menus) }}
        menuItemRender={renderMenuItem}
        collapsedButtonRender={renderHideMenuButton}
        headerRender={isIFrame ? false : () => <HeaderRight />}
      >
        <Outlet />
        {children}
      </ProLayout>
    </Authenticated>
  );
};

export default Dashboard;
