import { gql } from "@apollo/client";

const GET_MERCHANT_USER = gql`
  query($merchantUserId: String!, $merchantId: String!) {
    getCurrentMerchantUser(id: $merchantUserId, merchantId: $merchantId) {
      merchantUser {
        chargeBackNotification
        email
        id
        merchantId
        name
        needPasswordChange
        role
        secretKey
        sendSettlementReport
        permissions
      }
      status
    }
  }
`;

export default GET_MERCHANT_USER;
