import Payment from "@/features/checkout/routes/payment";
import CustomPayment from "@/features/checkout/routes/custom-payment";
import ResponseStatus from "@/features/checkout/routes/response-status";


const checkoutRoutes = [
  {
    path: "payment/:id/:lang",
    element: <Payment />
  },
  {
    path: "custom-payment/:id/:lang",
    element: <CustomPayment />
  },
  {
    path: "payment-response",
    element: <ResponseStatus type="payment" />
  },
  {
    path: "custom-payment-response",
    element: <ResponseStatus type="custom-payment" />
  }
];

export default checkoutRoutes;
