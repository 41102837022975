import { gql } from "@apollo/client";

const GET_PAY_TO_LINK = gql `
    query GetPayToLink($getPayToLinkId: ID!) {
      getPayToLink(id: $getPayToLinkId) {
        status
        message
        result {
          id
          check3D
          name
          desc
          amount
          currency
          imageUrlMedium
          imageUrlLarge
          stock
          limit
          cargo
          status
          maxInstallmentNumber
        }
      }
    }
    `;
export default GET_PAY_TO_LINK;