import {Select, DatePicker, Button, Col, Row} from 'antd';
import {useState} from "react";
import {useTranslation} from "react-i18next";

const ProfileFilters = ({ profiles, search, clear }: any) => {
    const { t } = useTranslation();
    const [selectedProfile, setSelectedProfile] = useState('');
    const [selectedDefault, setSelectedDefault] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [selectedUpdatedRange, setSelectedUpdatedRange] = useState([]);

    const profileOptions = [{ value: 0, label: t('pos.selectMidProfile') }]
    profiles.map((profile : any) => profileOptions.push({ value: profile.id, label: profile.name }));

    const { RangePicker } = DatePicker;

    const defaultOptions = [
        { value: '1', label: t('pos.default') },
        { value: '0', label: t('pos.notDefault') },
    ];

    const handleSearch = () => {
        const values = {
            ...(!!selectedProfile && { id: selectedProfile }),
            ...(!!selectedDefault && { default: selectedDefault }),
            ...(!!selectedDateRange && { created_at: selectedDateRange }),
            ...(!!selectedUpdatedRange && { updated_at: selectedUpdatedRange }),
        };
        search(values);
    };

    const handleClear = () => {
        setSelectedProfile('');
        setSelectedDefault('');
        setSelectedUpdatedRange([]);
        setSelectedDateRange([]);
        clear();
    };


    return (
        <>
            <Row aria-colspan={24} style={{marginBottom: 40, padding: "0 20px"}}>
                <Col span={4}>
                    <h4>{t("pos.midProfileGroupName")}</h4>
                    <Select
                        defaultValue={t("pos.selectMidProfile")}
                        value={selectedProfile}
                        style={{ width: "90%" }}
                        onChange={(value: string) => setSelectedProfile(value)}
                        options={profileOptions}
                    />
                </Col>
                <Col span={4}>
                    <h4>{t("pos.default")}</h4>
                    <Select
                        defaultValue={t('pos.default')}
                        value={selectedDefault}
                        style={{ width: "90%" }}
                        onChange={(value: string) => setSelectedDefault(value)}
                        options={defaultOptions}
                    />
                </Col>
                <Col span={5}>
                    <h4>{t("pos.createDate")}</h4>
                    <RangePicker
                        onChange={(values: any) => setSelectedDateRange(values)}
                        style={{ width: "90%" }} />
                </Col>
                <Col span={5}>
                    <h4>{t("pos.updateDate")}</h4>
                    <RangePicker
                        onChange={(values: any) => setSelectedUpdatedRange(values)}
                        style={{ width: "90%" }} />
                </Col>
                <Col span={3}>
                    <div style={{display: "flex", alignItems: "end", justifyContent: "center", marginTop: 32, gap: 10}}>
                        <Button
                            onClick={handleSearch}
                            style={{border: "1px solid #1677ff", color: "#1677ff", boxShadow: "none", width: "50%"}}>{t("pos.search")}</Button>
                        <Button
                            onClick={handleClear}
                            style={{border: "1px solid #cd3d64", color: "#cd3d64", boxShadow: "none", width: "50%"}}>{t("pos.clear")}</Button>
                    </div>
                </Col>
            </Row>
        </>

    )
}

export default ProfileFilters