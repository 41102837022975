import { useTranslation } from "react-i18next";
import { toPath } from "lodash";
import { formatAmount } from "@/utils/finance";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import GET_VALOR_DATE_REPORT from "@/features/financial-management/api/queries/get-valor-date-report";
import BaseTable from "@/components/base/table";
import { getUndue } from "@/features/financial-management/routes/undue/helper";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useRef } from "react";
import moment from "moment";

interface UndueProps {
    currency: string;
}

const Undue = ({ currency }: UndueProps) => {
    const [getValorDateReport] = useLazyQuery(GET_VALOR_DATE_REPORT);
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const { t } = useTranslation();
    const formRef = useRef();
    const COLUMNS = [
        {
            title: t("settlement.paymentDate"),
            dataIndex: "date",
            key: "paymentDate",
            hideInSearch: true,
        },
        {
            title: t("settlement.date"),
            valueType: "dateRange",
            dataIndex: "dateRange",
            key: "dateRange",
            hideInTable: true,
        },
        {
            title: t("settlement.totalAmount"),
            dataIndex: toPath(`merchant.${currency}.total`),
            render: (value: any) => <>{formatAmount(value, currency)}</>,
            key: "totalAmount",
            hideInSearch: true,
        },
        {
            title: t("settlement.totalCount"),
            dataIndex: toPath(`merchant.${currency}.count`),
            key: "totalCount",
            hideInSearch: true,
        },
        {
            title: t("settlement.actions"),
            hideInSearch: true,
            dataIndex: "date",
            key: "actions",
            renderText: (value: any) => (
                <Link
                    to={{
                        pathname: "/transactions/all/",
                        search: `?${new URLSearchParams([
                            ["trxListType", "valorDate"],
                            ["dateRange", value],
                            ["dateRange", value],
                        ])}`,
                    }}
                >
                    İşlemler
                </Link>
            ),
        },
    ];
    const tomorrow = moment().add(1, 'days');
    const monthsLater = moment().add(18, 'months');

    const initialVariables = {
        current: 1,
        merchantId: String(activeMerchantId),
        valorFromDate: tomorrow.format("YYYY-MM-DD"),
        valorToDate: monthsLater.format("YYYY-MM-DD"),
        page: 1,
        pageSize: 50,
    };
    return (
        <BaseTable
            //@ts-ignore
            request={(params: any, filter: any) =>
                getUndue({ ...params, filter }, getValorDateReport, initialVariables)
            }
            columns={COLUMNS}
            formRef={formRef}
        />
    );
};

export default Undue;
