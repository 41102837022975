import Login from "@/features/auth/routes/login";
import ResetPassword from "@/features/auth/routes/reset-password";

const authRoutes = [
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  }
];

export default authRoutes;
