const settlements = {
  "settlement.date": "Tarih Aralığı",
  "settlement.paymentDate": "Ödeme Tarihi",
  "settlement.paymentAmount": "Ödenecek Tutar",
  "settlement.paidAmount": "Ödenen Tutar",
  "settlement.fee": "Komisyon Tutarı",
  "settlement.totalRefund": "İade Toplamı",
  "settlement.totalChargebackAmount": "Harcama İtirazı Toplamı",
  "settlement.actions": "Aksiyonlar",
  "settlement.checkMail": "Mailinizi kontrol edin",
  "settlement.unknownError": "Bir hata meydana geldi",
  "settlement.csvSentEmail": "CSV dosyanız mail adresinize yollandı",
  "settlement.tryAgain": "Tekrar deneyin",
  "settlement.totalAmount": "Toplam Tutar",
  "settlement.totalCount": "Toplam İşlem",
  "settlement.export": "Dışa Aktar",
  "settlement.paidDate": "Ödeme Yapıldığı Tarih",
  'settlement.blockedAmount': 'Bloke Tutarı(Brüt)',
  "settlement.unblockedAmount": "Blokesi Çözülmüş Tutar(Brüt)",
  "settlement.unblockedDate": "Bloke Çözülme Tarih",
  "settlement.awaitingDate": "Tarih",
};

export default settlements