const posManagement = {
    "pos.midList": "POS Listesi",
    "pos.midProfile": "POS Profilleri",
    "pos.midProfileGroupName": "POS Profil Grubu Adı",
    "pos.selectMidProfile": "Profil grubu seçiniz",
    "pos.mid": "POS",
    "pos.acquirer": "POS Bankası",
    "pos.default": "Varsayılan",
    "pos.notDefault": "Varsayılan Değil",
    "pos.createDate": "Oluşturulma Tarihi",
    "pos.updateDate": "Güncelleme Tarihi",
    "pos.create.midProfile": "Profil Oluştur",
    "pos.blockageDay": "Blokeli Gün Sayısı",
    "pos.search": "Filtrele",
    "pos.clear": "Temizle",
    "pos.description": "Açıklama",
    "pos.blockageDay.sale.installment": "Blokeli Gün (Satış/Taksitli Satış)",
    "pos.edit": "Düzenle",
    "pos.delete": "Sil",
    "pos.select": "Seçiniz",
    "pos.midRates": "POS Oranları",
    "pos.merchantRates": "İşyeri Oranları",
    "pos.sale.blockage.day": "Satış Bloke Gün Sayısı",
    "pos.installment.blockage.day": "Taksitli Satış Blokeli Gün Sayısı",
    "pos.installment.delay.blockage.day": "Taksit Geciktirme Blokeli Gün",
    "pos.onus.debit": "Onus Banka Kartı",
    "pos.onus.credit": "Onus Kredi Kartı",
    "pos.domestic.debit": "Yurtiçi Banka Kartı",
    "pos.domestic.credit": "Yurtiçi Kredi Kartı",
    "pos.international.debit": "Yurtdışı Banka Kartı",
    "pos.international.credit": "Yurtdışı Kredi Kartı",
    "pos.domestic.amex.credit": "Yurtiçi Amex Kredi Kartı",
    "pos.international.amex.credit": "Yurtdışı Amex Kredi Kartı",
    "pos.rates.for.transaction.type.sale": "Kart Tipine Göre Oranlar",
    "pos.rates.for.transaction.type.installment.sale": "Taksit Sayısına Göre Oranlar",
    "pos.add.new.installment.rate": "Yeni Taksit Oranı Ekle",
    "pos.installment.number": "Taksit Sayısı",
    "pos.installment": "Taksit",
    "pos.rate": "Oran",
    "pos.create.mid": "Sanal POS Tanımlama",
    "pos.create.mid.configuration": "POS Bilgilerini Tanımla",
    "pos.edit.mid.configuration": "POS Bilgilerini Düzenle",
    "pos.edit.mid.profile.configuration": "POS Profilini Düzenle",
    "pos.create.mid.profile.configuration": "POS Profili Tanımla",
    "pos.mid.tested": "POS test edildi mi?",
    "pos.name": "POS Adı",
    "pos.tester": "Test Eden",
    "pos.mid.description": "POS Açıklaması",
    "pos.date.range.apply.plus.installment": "Taksit Kampanyası Aralığı",
    "pos.bank.credentials": "Banka Bilgileri",
    "pos.remove": "Kaldır",
    "pos.add.new.bank.credential": "Yeni Banka Bilgisi Ekle",
    "pos.save": "Kaydet",
    "pos.yes": "Evet",
    "pos.no": "Hayır",

    "pos.profile.name": "POS Profil Grubu Adı",
    "pos.assigned.poses": "Bu gruba atanmış POS'lar",
}

export default posManagement;