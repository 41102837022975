import { gql } from "@apollo/client";

const UPDATE_MERCHANT_USER = gql`
  mutation (
    $id: Int!
    $email: String!
    $merchantId: String!
    $name: String!
    $role: UserRole!
  ) {
    updateMerchantUser(
      id: $id
      email: $email
      merchantId: $merchantId
      name: $name
      role: $role
    ) {
      message
      status
    }
  }
`;

export default UPDATE_MERCHANT_USER;
