import { gql } from "@apollo/client";

const GET_MID_PROFILE_LIST = gql`
    query($merchantId: String!) {
      getMidProfileList(merchantId: $merchantId) {
        status
        message
        configGroups
      }
    }
`;

export default GET_MID_PROFILE_LIST