import { useSelector } from "react-redux";
import { RootState } from "@/store";

const useAuth = () => {
  const auth = useSelector((state: RootState) => state.auth.params);
  return {
    auth,
    isAuthenticated: !!auth.token,
  };
};

export default useAuth;
