import {Card, Modal, Table} from 'antd';
import GET_CUSTOM_PAY_TO_LINK from "@/features/pay-with-link/api/queries/getCustomPayToLink";
import "../index.less"
import {useQuery} from "@apollo/client";
import {useState} from "react";
import ProductInfo from "@/features/pay-with-link/components/pay-with-link/modal/product-info";
import LinkInfo from "@/features/pay-with-link/components/pay-with-link/modal/link-info";

const CurrentLinks = ({ apiKey }: any) => {
    const { data, loading, error } = useQuery(GET_CUSTOM_PAY_TO_LINK, {
        variables: {
            apiKey
        }
    });
    const [modalStatus, setModalStatus] = useState(false);
    const [selectedLink, setSelectedLink] = useState<any | undefined>([]);
    const [activeLink, setActiveLink] = useState("");

    const showDetailModal = (record: any) => {
        setSelectedLink(record);
        setModalStatus(true);
        const pathnamme = window.location.origin.toString();
        setActiveLink(`${pathnamme}/checkout/custom-payment/${record.id}`)
    }
    const closeDetailModal = () => {
        setSelectedLink([]);
        setModalStatus(false);
        setActiveLink("");
    }

    const columns = [
        {
            title: 'ÜRÜN ADI',
            dataIndex: 'productName',
            key: 'name',
        },
        {
            title: 'LİNK DURUMU',
            dataIndex: 'linkStatus',
            key: 'linkStatus',
            render: (status: boolean) =>
                <span className={`status  ${status && "active"}`}>
                    {status ? "Aktif" : "İnaktif"}
                </span>,
        }
    ];

    const dataSource = [
        {
            key: '1',
            productName: 'Serbest Ödeme',
            linkStatus: true,
        }
    ];

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error...</p>;

    return (
        <>
            <Card title="Sabit Linklerim" style={{marginTop: "15px"}}>
                <Table
                    dataSource={[{
                        id: data?.getCustomPayToLink?.result.id,
                        productName: 'Serbest Ödeme',
                        linkStatus: true,
                    }]}
                    columns={columns}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => showDetailModal(record), // click row
                        };
                    }}
                />
            </Card>
            <Modal
                visible={modalStatus && !!activeLink}
                centered
                onCancel={() => closeDetailModal()}
                footer={false}
                width={"90%"}
                style={{maxWidth: "900px"}}
            >
                {selectedLink.id && (
                    <LinkInfo
                        id={selectedLink.id}
                        type="custom-payment"
                        url={activeLink}/>
                )}

            </Modal>
        </>
    );
};

export default CurrentLinks;
