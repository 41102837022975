import { gql } from "@apollo/client";

const CREATE_MERCHANT_USER = gql`
  mutation (
    $email: String!
    $merchantId: String!
    $name: String!
    $password: String!
    $role: UserRole!
  ) {
    createMerchantUser(
      email: $email
      merchantId: $merchantId
      name: $name
      password: $password
      role: $role
    ) {
      id
      message
      status
    }
  }
`;

export default CREATE_MERCHANT_USER;
