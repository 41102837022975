import { gql } from "@apollo/client";

const SAVE_MERCHANT_RATES = gql`
  mutation SaveMerchantRates($merchantId: Int!, $rates: [JSON]) {
    saveMerchantRates(merchantId: $merchantId, rates: $rates) {
      status
      message
    }
  }
`;

export default SAVE_MERCHANT_RATES;
