import { useState } from "react";
import {InputNumber, Popover} from "antd";
import {useTranslation} from "react-i18next";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import { InputStatus } from "antd/lib/_util/statusUtils";

const calculateTotalIncome = (rates:any) => {
    const buyerCostRate = Number(rates?.buyerCostRate);
    const interestRate = Number(rates?.interestRate);
    const x = 100 + buyerCostRate;
    const result = x - (x * interestRate) / 100;

    return result.toFixed(2);
};

const CommissionInput = ({onChangeCommision, buyerCostRate, interestRate}: any) => {

    const {t} = useTranslation();
    const totalIncome: any = calculateTotalIncome({ buyerCostRate, interestRate });

    const [initialValue, setInitialValue] = useState(buyerCostRate);
    const [status, setStatus] = useState<InputStatus>('');

    return (
        <div style={{width: "150px"}}>
            {interestRate ? (
                <InputNumber
                    value={buyerCostRate !== '-' && !isNaN(buyerCostRate) ? buyerCostRate : ''}
                    status={status}
                    prefix="%"
                    step="0.01"
                    min={0}
                    parser={(val) => Number(val).toFixed(2)}
                    addonBefore={
                        <Popover
                            title="Uyarı"
                            content={
                                <div style={{width: '250px'}}>
                                    {
                                        t('financialManagement.rates.caution', {
                                            buyerCostRate: buyerCostRate || '-',
                                            totalIncome: isNaN(totalIncome) ? '-' : totalIncome,
                                        })
                                    }
                                </div>
                            }
                        >
                            <ExclamationCircleOutlined/>
                        </Popover>
                    }
                    onChange={(value) => {
                        if (value !== initialValue) {
                            setStatus("warning");
                        }
                        onChangeCommision(value);
                    }}
                />
            ) : (
                <InputNumber disabled/>
            )}
        </div>
    )
}

export default CommissionInput;