/**
 *
 * Represents dummy fields returning from API.
 */
export const DUMMY_ADDRESS_DATA = {
  "pay-link address": "-",
  "pay-link billingAddress2": "-",

  "pay-link city": "Şehir bilgisi verilmedi",
};

export function findDummyDataValue(value: string) {
  // @ts-ignore
  return DUMMY_ADDRESS_DATA[value] ?? value;
}

export const tableFilterArrayFixer = (data: any) => {
  if (data) {
    if (Array.isArray(data)) {
      return data.reduce((arr, item) => [...arr, ...item.split(",")], []);
    }
    return data.split(",");
  }
  return undefined;
};
