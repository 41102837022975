import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/use-auth";
import { useLocation } from "react-router-dom";

interface AuthenticatedProps {
  children: React.ReactNode;
}

const Authenticated = ({ children }: AuthenticatedProps) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/user/login" replace />;
  }
  else if(location.pathname === "/") return <Navigate to="/welcome" replace />;
  return <>{children}</>;
};

export default Authenticated;
