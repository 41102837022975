import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import { setAuthParams } from "@/features/auth/store/authSlice";

import LoginForm from "@/features/auth/components/login-form";
import LOGIN from "@/features/auth/api/mutations/login";
import login, {getCurrentMerchantsData} from "@/features/auth/routes/login/helper";
import GET_CURRENT_MERCHANTS from "@/api/queries/get-current-merchants";

import { Alert } from "antd";
import Logo from "@/assets/logo.png";
import "@/features/auth/routes/login/index.less";
import {useTranslation} from "react-i18next";

const Login = () => {
  const [mutateLogin, { loading, error }] = useMutation(LOGIN);
  const [getCurrentMerchants] = useLazyQuery(GET_CURRENT_MERCHANTS);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (values: any) => {
      try {
          const loginResponse = await login(mutateLogin, values);
          if (loginResponse.loginStatus) {
              const { merchantId, token } = loginResponse;
              const currentMerchants = await getCurrentMerchantsData(getCurrentMerchants, merchantId, token);
              const authParams = {
                  ...loginResponse,
                  subMerchants: currentMerchants,
                  activeMerchantId: merchantId,
                  email: values.email
              };
              dispatch(setAuthParams(authParams));
              navigate("/welcome");
          }
      }catch (error: any) {
          const message = t("pages.login.accountLogin.errorMessage");
          setErrorMessage(message);
      }
  }

  const ResetPassword = () => {
    return (
      <div className="resetPassword">
        <Link to="/user/reset-password">Şifremi Unuttum</Link>
      </div>
    );
  };

  const LoginMessage = ({ content }: any) => (
    <Alert
      style={{ marginBottom: 24 }}
      message={content}
      type="error"
      showIcon
    />
  );

  const ErrorMessage = () => {
    if (error) {
      return <LoginMessage content={errorMessage} />;
    }
    return <div />;
  };

  return (
    <div className="main">
      <img src={Logo} className="logo" alt="Logo" />
      <LoginForm
        isLoading={loading}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        <ErrorMessage />
      </LoginForm>
      <ResetPassword />
    </div>
  );

};

export default Login;
