import { Dropdown } from "antd";
import classNames from "classnames";
import "@/components/header/right/header-dropdown/index.less";

const HeaderDropdown = ({ overlayClassName, ...restProps }: any) => (
  <Dropdown
    overlayClassName={classNames("container", overlayClassName)}
    {...restProps}
  />
);

export default HeaderDropdown
