import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Descriptions,
  InputNumber,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';

import { useTranslation, Trans } from "react-i18next";
import { currencyFormatter, currencyParser, formatAmount } from '@/utils/finance';
import { RESPONSE_STATUS } from "@/config/constants";
import CREATE_KEY from "@/features/settings/api/create-key";
import { useMutation } from '@apollo/client';
import { PREAUTH, PREAUTH_CANCEL } from '../../api/mutations/preAuth';
import { useSelector } from 'react-redux';
import { AlertComp } from './util';

const { Text } = Typography;

export const PreAuthModal = (props: any) => {

  const { transactionId, created_at, referenceNo } = props?.transaction?.transaction?.merchant;
  const { allowPartialCapture } = props?.transaction?.merchant;
  const { billingFirstName, billingLastName } = props?.transaction?.customerInfo || "";
  const { originalAmount, originalCurrency } = props?.transaction?.fx.merchant;
  const { preAuthType } = props?.transaction;

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [activeType, setActiveType] = useState<any>(preAuthType);
  const [amount, setAmount] = useState<any>(0);
  const [apiKey, setApiKey] = useState('');
  const [requestStatus, setRequestStatus] = useState<any>(null);
  const [resMessage, setResMessage] = useState(null);

  const [preAuthMutation] = useMutation(PREAUTH);
  const [preAuthCancelMutation] = useMutation(PREAUTH_CANCEL);
  const [createKey] = useMutation(CREATE_KEY);


  const { t } = useTranslation();
  const { activeMerchantId } = useSelector((state
    // @ts-ignore
  ) => state.auth.params);

  useEffect(() => {
    (async () => {
      const apiK = await createKey({
        variables: {
          merchantId: String(activeMerchantId),
          keyType: "apiKey",
        },
      })
      const { key } = apiK.data.createKey;
      setApiKey(key);
    })();
  }, []);

  const onChangeCbox = (e: any) => {
    setActiveType(e.target.value);
  };

  const amountParser = (val: any) => {
    const reversedVal = currencyParser(val);
    if (reversedVal > originalAmount / 100) {
      return originalAmount / 100;
    }
    return reversedVal;
  };

  const captureTypeObj: any = {
    partial: {
      amount: amount,
      request: async (body: any) => preAuthMutation({ variables: { ...body } }),
      cautionComponent: () => (
        <>
          <InputNumber
            parser={(val) => amountParser(val)}
            value={amount}
            addonBefore={'Kapama Tutarı'}
            onChange={(e) => setAmount(e)}
            max={originalAmount / 100}
            step="0.01"
            min={0}
            formatter={(val) => currencyFormatter(val)}
          />
          <Text>{cautionMessage}</Text>
        </>
      ),
    },
    total: {
      amount: originalAmount / 100,
      request: async (body: any) => preAuthMutation({ variables: { ...body } }),
      cautionComponent: () => <Text >{cautionMessage}</Text>,
    },
    cancel: {
      amount: originalAmount / 100,
      request: async (body: any) => preAuthCancelMutation({ variables: { ...body } }), 
      cautionComponent: () => <Text  >{cautionMessage}</Text>,
    },
  };

  const cautionMessage =
    <Trans i18nKey={"transaction.preAuth.caution"}
      values={{
        amount: `${currencyFormatter(
          captureTypeObj[activeType].amount,
          originalCurrency,
        )} ${originalCurrency}`
      }
      } />


  const handleOk = async () => {

    const body = {
      apiKey,
      transactionId,
      referenceNo,
      amount: captureTypeObj[activeType].amount * 100,
      currency: originalCurrency,
      merchantId: activeMerchantId
    };
    setConfirmLoading(true);
    try {
      const res = await captureTypeObj[activeType].request(body);
      const data = res.data
      const preAuthCancelStatus =  data?.preAuthCancel?.status
      const preAuthCancelResMessage = data?.preAuthCancel?.message
      const preAuthStatus = data?.preAuth?.status
      const preAuthResMessage = data?.preAuth?.message

      setResMessage(preAuthResMessage || preAuthCancelResMessage)
      setRequestStatus(preAuthStatus || preAuthCancelStatus);
      setConfirmLoading(false);
    } catch (e) {
      setRequestStatus(RESPONSE_STATUS.DECLINED)
      setResMessage(t('results.error.title'));
      setConfirmLoading(false);
    }
  };

  const descriptionMapper = () => {
    const descriptionItems = [
      { text: `${billingFirstName} ${billingLastName}`, localizationId: 'transaction.fullName' },
      { text: transactionId, localizationId: 'transaction.transactionId' },
      {
        text: formatAmount(originalAmount, originalCurrency),
        localizationId: 'transaction.amount',
      },
      { text: created_at, localizationId: 'transaction.date' },
    ];
    return descriptionItems.map((item) => (
      <Descriptions.Item label={t(item.localizationId)}>
        {item.text}
      </Descriptions.Item>
    ));
  };

  return (
    <Modal
      title={t(`transaction.preAuthType.${preAuthType}`)}
      visible={props.openCaptureModal}
      onOk={requestStatus === RESPONSE_STATUS.APPROVED ? () => props.closePreAuthModal(requestStatus) : handleOk}
      onCancel={props.closePreAuthModal}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Descriptions column={1} bordered>
          {descriptionMapper()}
        </Descriptions>
      </Space>

      <Row align={'middle'} style={{ background: '#fafafa', borderRadius: 8, padding: 16 }}>
        {preAuthType !== 'cancel' && (
          <Col span={8}>
            <Space direction="vertical">
              <Checkbox value={'total'} checked={activeType === 'total'} onChange={onChangeCbox}>
                {t('transaction.preAuth.total')}
              </Checkbox>
              <Checkbox
                value={'partial'}
                checked={activeType === 'partial'}
                onChange={onChangeCbox}
                disabled={!allowPartialCapture}
              >
                {t('transaction.preAuth.partial')}
              </Checkbox>
            </Space>
          </Col>
        )}
        <Col span={preAuthType === 'cancel' ? 32 : 16}>
          <Space direction="vertical">{captureTypeObj[activeType].cautionComponent()}</Space>
        </Col>
      </Row>
      {resMessage &&
        <AlertComp message={resMessage} requestStatus={requestStatus} />
      }
    </Modal>
  );
};
export default PreAuthModal