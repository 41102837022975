import { gql } from "@apollo/client";

const GET_VALOR_DATE_REPORT = gql`
  query ($pageSize: Int!, $page: Int!, $merchantId: String!, $current: Int!, $valorFromDate: String, $valorToDate: String) {
    getValorDateReport(
      pageSize: $pageSize
      page: $page
      merchantId: $merchantId
      current: $current
      valorFromDate: $valorFromDate
      valorToDate: $valorToDate
    ) {
      response
      status
    }
  }
`;

export default GET_VALOR_DATE_REPORT;
