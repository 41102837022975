import {Col, Row, Statistic} from "antd";
import {useTranslation} from "react-i18next";


const TopInfo = ({oneTime}: any) => {
    const {t} = useTranslation();
    return (
        <>
            <Row gutter={16} style={{padding: 20}}>
                <Col span={6}>
                    <Statistic
                        title={t("financialManagement.oneTime")}
                        value={oneTime.domestic_credit}
                        prefix={'%'}
                    />
                </Col>
                <Col span={6}>
                    <Statistic
                        title={t("financialManagement.internationalCard")}
                        value={oneTime.international_credit}
                        prefix={'%'}
                    />
                </Col>
                <Col span={6}>
                    <Statistic
                        title={t("financialManagement.blockageDay")}
                        value={`${oneTime.blockageDayNumber+" "+t('financialManagement.day')}`}
                    />
                </Col>
            </Row>
        </>
    )
}

export default TopInfo;