import { gql } from "@apollo/client";

const CREATE_PAY_TO_LINK = gql`
  mutation CreatePayToLink($createPayToLinkInput: CreatePayToLinkInput!) {
  createPayToLink(createPayToLinkInput: $createPayToLinkInput) {
    status
    message
    result {
      id
      check3D
      name
      desc
      amount
      currency
      imageUrlMedium
      imageUrlLarge
      stock
      limit
      cargo
      status
      maxInstallmentNumber
    }
  }
}
`;

export default CREATE_PAY_TO_LINK;
