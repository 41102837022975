import PosManagement from "@/features/pos-management/routes/mid-list";
import MerchantRates from "./merchant-rates";
import VirtualPosConfiguration from "@/features/pos-management/routes/virtual-pos";
import MidRates from "@/features/pos-management/routes/mid-rates";
import MidProfile from "@/features/pos-management/routes/mid-profile";
import PosProfile from "@/features/pos-management/routes/pos-profile";

const posManagementRoutes = {
  path: "pos-management",
  children: [
    {
      path: "mid-list",
      element: <PosManagement />,
    },
    {
      path: "mid-rates/:mid",
      element: <MidRates />,
    },
    {
      path: "merchant-rates",
      element: <MerchantRates />,
    },
    {
      path: "virtual-pos/:mid",
      element: <VirtualPosConfiguration />,
    },
    {
      path: "mid-profile",
      element: <MidProfile />,
    },
    {
      path: "pos-profile/:mid/:configGroup",
      element: <PosProfile />,
    },
  ],
};

export default posManagementRoutes