import { gql } from "@apollo/client";

const GET_TRANSACTION_DETAIL = gql`
  query ($merchantId: String!, $transactionId: String!) {
    getTransactionDetail(
      merchantId: $merchantId
      transactionId: $transactionId
    ) {
      transaction {
        acquirer {
          provisionNo
          mdStatus
        }
        merchant {
          agent {
            customerIp
          }
          basketItems {
            category
            description
            name
            quantity
            unitPrice
          }
          status
          transactionId
          operation
          code
          updated_at
          referenceNo
          installment
          channel
          doneBy {
            channel
          }
        }
      }
      acquirer {
        type
      }
      fx {
        merchant {
          originalAmount
          netAmount
          commission
          originalCurrency
        }
      }
      merchant {
        name
      }
      customerInfo {
        billingFirstName
        billingLastName
        billingAddress1
        billingAddress2
        billingCountry
        billingCity
        billingPhone
        billingPostcode
        shippingAddress1
        shippingAddress2
        shippingCountry
        shippingCity
        shippingPhone
        shippingPostcode
        email
        number
        bin {
            cardType
            bankName
            brand
            cardCategory
            countryCode
            currencyCode
            domesticIntl
            loyalty
        }
      }
    }
  }
`;

export default GET_TRANSACTION_DETAIL;
