import { gql } from "@apollo/client";

const EXPORT_TRANSACTION_LIST = gql `
    mutation ExportTransactionList($customerExportEmail: String!, $exportType: String!, $fromDate: String!, $errorCode: String, $filterField: String, $filterValue: String, $merchantId: String, $page: Int, $selectedFields: String, $toDate: String) {
        exportTransactionList(customerExportEmail: $customerExportEmail, exportType: $exportType, fromDate: $fromDate, errorCode: $errorCode, filterField: $filterField, filterValue: $filterValue, merchantId: $merchantId, page: $page, selectedFields: $selectedFields, toDate: $toDate) {
            response
            status
        }
    }
`

export default EXPORT_TRANSACTION_LIST