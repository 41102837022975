const TRANSACTION_STATUS_LIST = {
  APPROVED: "APPROVED",
  AWAITING_FRAUD_RESPONSE: "AWAITING_FRAUD_RESPONSE",
  CANCELLED: "CANCELLED",
  ERROR: "ERROR",
  COMPLETED: "COMPLETED",
  DECLINED: "DECLINED",
  FAILED: "FAILED",
  INITIATED: "INITIATED",
  PENDING: "PENDING",
  REVIEW: "REVIEW",
  PROCESSING: "PROCESSING",
  REJECTED: "REJECTED",
  RELEASED: "RELEASED",
  THREE_D_REQUIRED: "THREE_D_REQUIRED",
  THREE_D_VERIFIED: "THREE_D_VERIFIED",
  UNCLAIMED: "UNCLAIMED",
  WAITING: "WAITING",
  WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
  WAITING_FOR_APPROVAL_CLIENT: "WAITING_FOR_APPROVAL_CLIENT",
  WAITING_FOR_CANCELLATION: "WAITING_FOR_CANCELLATION",
  WAITING_FOR_REJECTION: "WAITING_FOR_REJECTION",
  COMPLETED_PENDING_APPROVAL: "COMPLETED_PENDING_APPROVAL",
  FAILED_PENDING_APPROVAL: "FAILED_PENDING_APPROVAL",
  WON: "WON",
  LOSS: "LOSS",
};

const TRANSACTION_STATUS_COLORS = {
  [TRANSACTION_STATUS_LIST.APPROVED]: "green",
  [TRANSACTION_STATUS_LIST.COMPLETED]: "green",
  [TRANSACTION_STATUS_LIST.APPROVED]: "green",
  [TRANSACTION_STATUS_LIST.FAILED]: "red",
  [TRANSACTION_STATUS_LIST.DECLINED]: "red",
  [TRANSACTION_STATUS_LIST.ERROR]: "red",
  [TRANSACTION_STATUS_LIST.FAILED_PENDING_APPROVAL]: "red",
  [TRANSACTION_STATUS_LIST.APPROVED]: "green",
  [TRANSACTION_STATUS_LIST.DECLINED]: "red",
  [TRANSACTION_STATUS_LIST.ERROR]: "red",
  [TRANSACTION_STATUS_LIST.WON]: "green",
  [TRANSACTION_STATUS_LIST.LOSS]: "red",
};

const TRANSACTION_OPERATION_LIST = {
  REFUND: "REFUND",
};

const TRANSACTION_OPERATION_COLORS = {
  [TRANSACTION_OPERATION_LIST.REFUND]: "orange",
};

// TODO: Move functions to the another folder for improving the code style and prevent the possible circular dependencies.
export const TRANSACTION_SECURITY_TYPES = {
  'FULL SECURE': {
    operation: ['3D', '3DAUTH'],
  },
  'HALF SECURE': {
    operation: ['3D', '3DAUTH'],
    mdStatus: ['2', '3', '4'],
  },
  'NON SECURE': {
    operation: ['DIRECT'],
  },
}

export function getTransactionCompleted(transaction: any) {
  return transaction.status === TRANSACTION_STATUS_LIST.COMPLETED;
}

export function isOperationRefund(operation: any) {
  return operation === TRANSACTION_OPERATION_LIST.REFUND;
}

export function findTransactionStatusColor(status = "") {
  const color = TRANSACTION_STATUS_COLORS[status.toUpperCase()];
  return color || "black";
}

export function findTransactionOperationColor(operation: any) {
  const color = TRANSACTION_OPERATION_COLORS[operation.toUpperCase()];
  return color || "black";
}

export function checkIsTransactionRefundable(transaction: any) {
  try {
    return !!(
      transaction.refundable &&
      // TODO: Create a constants file for merchant object alone.
      transaction.transaction.merchant.status ===
        TRANSACTION_STATUS_LIST.APPROVED &&
      transaction.transaction.merchant.type === "AUTH"
    );
  } catch (e) {
    return false;
  }
}
export function checkIsTransactionPreAuth(transaction: any) {
  
  return (
    transaction.transaction.merchant.status === TRANSACTION_STATUS_LIST.APPROVED &&
    transaction.transaction.merchant.operation !== "CANCEL" &&
    transaction?.captureAble === true
  );
}
