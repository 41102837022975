import { Spin } from "antd";

declare const SpinSizes: ["small", "default", "large"];
export declare type SpinSize = typeof SpinSizes[number];
interface LoadingIndicatorProps {
  size?: SpinSize;
}

const LoadingIndicator = ({ size }: LoadingIndicatorProps) => {
  return <Spin size={size} />;
}

export default LoadingIndicator
