import DateCell from "@/components/base/table/date-cell";
import {ProFormRadio} from "@ant-design/pro-form";
import CustomSelect from "@/components/custom-select";
import {get, toPath} from "lodash";
import {checkIsTransactionPreAuth, checkIsTransactionRefundable, findTransactionStatusColor} from "@/utils/transaction";
import {Button, Tag} from "antd";
import {formatAmount} from "@/utils/finance";


const getColumns = (customParams: any) => {
    const { t, setSelectedTransaction, setOpenCaptureModal, isVisibleFilter, isVisibleTrxDetail, isVisibleRefund } = customParams;

    const filters = isVisibleFilter ? [
        {
            title: t("transaction.search"),
            valueType: "select",
            dataIndex: "filterField",
            hideInTable: true,
            initialValue: { filterField: "transactionId", filterValue: "" },
            request: async () => [
                { label: t("transaction.amount"), value: "originalAmount" },
                { label: t("transaction.transactionId"), value: "transactionId" },
                { label: t("transaction.cardNumber"), value: "cardPan" },
                { label: t("transaction.email"), value: "customerEmail" },
                { label: t("transaction.referenceNo"), value: "referenceNo" },
                { label: 'mdStatus', value: "mdStatus" },
            ],
            //@ts-ignore
            renderFormItem: ({ initialValue }, { fieldProps, options }, form) => (
                <CustomSelect
                    {...fieldProps}
                    options={options}
                    initialValue={initialValue}
                    placeholder="Please enter test"/>
            ),
        },
        {
            title: t("transaction.date"),
            dataIndex: "dateRange",
            valueType: "dateRange",
            sorter: true,
            hideInTable: true,
            render: (value: string) => <DateCell date={value} />,
            fieldProps: {
                onChange: () => {
                    // @ts-ignore
                    const dateRange = formRef.current.getFieldValue("dateRange");
                    if (!dateRange) {
                        // @ts-ignore
                        formRef.current.setFieldValue("trxListType", "trxDate");
                    }
                },
            },
        },
        {
            title: t("transaction.status"),
            valueType: "select",
            dataIndex: "status",
            hideInTable: true,
            fieldProps: {
                mode: "multiple",
                style: {
                    maxWidth: "90%",
                },
                showArrow: true,
                filterOption: (input: string, option: any) => option.children.toLowerCase().includes(input.toLowerCase()),
            },
            request: async () => [
                {
                    label: t("transaction.status.waiting"),
                    value: "WAITING",
                },
                {
                    label: t("transaction.status.approved"),
                    value: "APPROVED",
                },
                {
                    label: t("transaction.status.declined"),
                    value: "DECLINED",
                },
                {
                    label: t("transaction.status.error"),
                    value: "ERROR",
                },
            ],
        },
        {
            title: t("transaction.transactionType"),
            dataIndex: "operation",
            hideInTable: true,
            fieldProps: {
                mode: "multiple",
                style: {
                    maxWidth: "90%",
                },
                showArrow: true,
            },
            request: async () => [
                {
                    label: t("transaction.sell"),
                    value: ["DIRECT", "3D", "3DAUTH", "NON SECURE", "HALF SECURE"].join(
                        ","
                    ),
                },
                {
                    label: t("transaction.refund.selectbox"),
                    value: "REFUND",
                },
                {
                    label: t("transaction.void"),
                    value: "VOID",
                },
                {
                    label: t("transaction.cancel"),
                    value: "CANCEL",
                },
                {
                    label: t("transaction.capture"),
                    value: "CAPTURE",
                },
            ],
        },
        {
            title: t("transaction.3dSecure"),
            dataIndex: "threeDSecureStatus",
            hideInTable: true,
            fieldProps: {
                style: {
                    maxWidth: "90%",
                },
                showArrow: true,
            },
            request: async () => [
                {
                    label: 'Full Secure',
                    value: '3DAUTH',
                },
                {
                    label: 'Half Secure',
                    value: '3D',
                },
                {
                    label: 'Non Secure',
                    value: 'DIRECT',
                },
            ],
        },
        {
            title: "Listeleme Tipi",
            dataIndex: "trxListType",
            valueType: "radio",
            initialValue: "trxDate",
            valueEnum: {
                trxDate: { text: "İşlem Tarihi", status: "trxDate" },
                valorDate: { text: "Hesaba Geçiş Tarihi", status: "valorDate" },
            },
            hideInTable: true,
            renderFormItem: (item: any, { type, defaultRender, formItemProps, fieldProps, ...rest }: any, form: any) => {
                if (type === "form") {
                    return null;
                }
                const dateRange = form.getFieldValue("dateRange");
                if (dateRange) {
                    return defaultRender(item);
                }
                return (<ProFormRadio.Group valueEnum={item.valueEnum} disabled={true} />);
            },
        },
    ] : [];
    // @ts-ignore
    const columnns = [
        {
            title: t("transaction.merchantName"),
            hideInSearch: true,
            key: "merchantName",
            dataIndex: toPath("merchant.name"),
        },
        {
            title: t("transaction.transactionId"),
            dataIndex: toPath("transaction.merchant.transactionId"),
            key: "transactionId",
            render: (value: string, transaction:any) => {
                if (isVisibleTrxDetail) {
                    return (
                        <Button onClick={()=>customParams.onDetailClick(`/transactions/all/${value}`, transaction.valorDate, transaction.customerInfo)}>{value}</Button>
                    )
                }
                return null;
            },
            hideInSearch: true,
        },
        {
            title: t("transaction.referenceNo"),
            hideInSearch: true,
            key: "referenceNo",
            dataIndex: toPath("transaction.merchant.referenceNo"),
        },
        {
            title: t("transaction.date"),
            dataIndex: "created_at",
            key: "date",
            hideInSearch: true,
            render: (value: string) => {
                return <DateCell date={value} />;
            },
        },
        {
            title: t("transaction.valorDate"),
            dataIndex: "valorDate",
            key: "valorDate",
            hideInSearch: true,
            render: (value:string)=> <div>{value?.[0] || "-"}</div>
        },
        {
            title: t("transaction.type"),
            hideInSearch: true,
            key: "type",
            dataIndex: toPath("transaction.merchant.operation"),
            render: (value: string, row: any) => {
                const isPreAuth = row.transaction.merchant.type === 'PREAUTH';
                const isCanceled = value === 'CANCEL';
                const bothPreAuthAndCanceled = isPreAuth && isCanceled;
                return t(bothPreAuthAndCanceled ? `transaction.operation.${value}` : isPreAuth ? `transaction.preAuth` : `transaction.operation.${value}`)
            },
        },
        {
            title: t("transaction.status"),
            dataIndex: "transaction",
            key: "status",
            hideInSearch: true,
            render: (value: any) => {
                const { status } = value?.merchant;
                const color = findTransactionStatusColor(status);
                const lowerCaseStatus = status.toLowerCase();
                return (
                    <Tag color={color}>{t(`transaction.status.${lowerCaseStatus}`)}</Tag>
                );
            },
        },
        {
            title: t("transaction.message"),
            key: "message",
            hideInSearch: true,
            dataIndex: toPath("transaction.merchant.message"),
        },
        {
            title: t("transaction.installment"),
            key: "installment",
            hideInSearch: true,
            dataIndex: toPath("transaction.merchant.installment"),
        },
        {
            title: t("transaction.amount"),
            hideInSearch: true,
            key: "amount",
            dataIndex: toPath("fx.merchant.originalAmount"),
            renderText: (val: any, row: any) =>
                formatAmount(val, get(row, "fx.merchant.originalCurrency")),
        },
        {
            title: t("transaction.fullName"),
            hideInSearch: true,
            key: "fullname",
            dataIndex: toPath("customerInfo"),
            renderText: (val: any) => (
                <>
                    {val?.billingFirstName} {val?.billingLastName}{" "}
                </>
            ),
        },
        {
            title: t("transaction.cardNumber"),
            hideInSearch: true,
            key: "cardNumber",
            dataIndex: toPath("customerInfo.number"),
            renderText: (cardNumber: string) => cardNumber?.replaceAll("x", "X"),
        },
        {
            title: t("transaction.actions"),
            hideInSearch: false,
            render: (value: any, transaction: any) => [
                checkIsTransactionRefundable(transaction) && isVisibleRefund && (
                    <Button
                        type={"primary"}
                        onClick={() => setSelectedTransaction(transaction)}
                    >
                        {t("transaction.refund")}
                    </Button>
                ),
                checkIsTransactionPreAuth(transaction) && (
                  <>
                    <Button
                      type="primary"
                      onClick={() => {
                        setSelectedTransaction({ ...transaction, preAuthType: 'total' });
                        setOpenCaptureModal(true);
                      }}
                    >
                        {t("transaction.preAuth.close")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setSelectedTransaction({ ...transaction, preAuthType: 'cancel' });
                        setOpenCaptureModal(true);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                        {t("transaction.preAuth.cancel")}
                    </Button>
                  </>
                ),
            ]
        },
    ];
    return [
        ...filters,
        ...columnns
    ];
}


export default getColumns;