import { gql } from "@apollo/client";

const FIND_PAY_TO_LINK = gql `
    query findPayToLink($apiKey: String!) {
      findPayToLink(apiKey: $apiKey) {
        status
        message
        result {
          id
          check3D
          name
          desc
          amount
          currency
          imageUrlMedium
          imageUrlLarge
          stock
          limit
          cargo
          status
          maxInstallmentNumber
        }
      }
    }
`;

export default FIND_PAY_TO_LINK;