import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SubMerchant {
  id: number;
  name: string;
}

export interface AuthParams {
  token: string | null;
  role: string | null;
  merchantUserId: number | null;
  merchantId: number | null;
  activeMerchantId: number | null;
  subMerchants: [SubMerchant] | [];
  email: string | null;
  timestamp: any | null;
}

export interface AuthState {
  params: AuthParams;
}

const initialState: AuthState = {
  params: {
    token: null,
    role: null,
    merchantUserId: null,
    merchantId: null,
    activeMerchantId: null,
    subMerchants: [],
    email: null,
    timestamp: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthParams: (state, action: PayloadAction<object>) => {
      state.params = { ...state.params, ...action.payload };
    },
    removeAuthParams: (state) => {
      state.params = initialState.params;
    },

  },
});

export const { setAuthParams, removeAuthParams, } = authSlice.actions;

export default authSlice.reducer;
