import { PageContainer } from "@ant-design/pro-layout";
import BaseTable from "@/components/base/table";

import {Button, Card} from 'antd';
import TableTopSearch from "@/features/pos-management/routes/mid-list/table/top-search";
import {useQuery} from "@apollo/client";
import GET_MIDS_LIST from "@/features/pos-management/api/queries/get-mids-list";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import GET_ACQUIRER_LIST from "@/features/pos-management/api/queries/get-acquirer-list";
import {useTranslation} from "react-i18next";

const PosManagement = () => {
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const { data: acquirersData , loading: acquirersLoading, error: acquirersError} = useQuery(GET_ACQUIRER_LIST, {
        variables: {
            merchantId: String(activeMerchantId)
        }
    })
    const { t } = useTranslation()

    const { data, error, loading } = useQuery(GET_MIDS_LIST, {
        variables: {
            merchantId: activeMerchantId
        }
    });
    const [result, setResult] = useState([]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center'
        },
        {
            title: t("pos.name"),
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: t("pos.acquirer"),
            dataIndex: 'acquirer',
            key: 'acquirer',
            align: 'center',
            render: (value: string) => {
                const acquirers = acquirersData.getAcquirerList.result;
                const selectedAcquirer = acquirers.find((acquirer: any) => acquirer.id == value);
                return <label>{selectedAcquirer.name}</label>
            },
        },
        {
            title: t("pos.description"),
            dataIndex: 'descriptor',
            key: 'descriptor',
            align: 'center'
        },
        {
            title: t("pos.blockageDay.sale.installment"),
            dataIndex: 'blockageDayNumber',
            key: 'blockageDayNumber',
            align: 'center',
            render: (value: string, item: any) => {
                const saleBlockageDayNumber = getSaleBlockageDayNumber(item.rates.sale);
                const installmentSaleBlockageDayNumber = item.rates.installment_sale.blockageDayNumber;
                return <p>{`${saleBlockageDayNumber} / ${installmentSaleBlockageDayNumber}`}</p>
            },
        },
        {
            title: t("pos.createDate"),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (value: string) => {
                return <p>{moment(value).format("DD.MM.YYYY HH.mm")}</p>
            },
        },
        {
            dataIndex: 'edit',
            key: 'edit',
            width:'200px',
            align: 'center',
            render: (intRate: any, item: any) => {
                return (
                    <div style={{display:"flex"}}>
                        <Button style={{marginRight:"5px"}}> <Link to={`/pos-management/virtual-pos/${item.id}`}> {t("pos.edit")} </Link> </Button>
                        <Button> <Link to={`/pos-management/mid-rates/${item.id}`}>{t("pos.midRates")} </Link> </Button>
                    </div>
                );
            }
        }
    ];

    const getSaleBlockageDayNumber = (sale: any) => {
        const sortingTypes = ["onus_debit", "onus_credit", "domestic_debit", "domestic_credit", "international_debit", "international_credit"];
        let blockageDayNumber = "";
        sortingTypes.forEach((sortingType) => {
           if (typeof sale[sortingType] === "number") {
               blockageDayNumber = sale[`${sortingType}_blockageDayNumber`];
           }
        });
        return blockageDayNumber
    }

    useEffect(() => {
        if (data) {
            setResult(data.getMidConfigurationList.result);
        }
    }, [data]);

    const search = (values: any) => {
        const filteredResult = data.getMidConfigurationList.result.filter((item: any) => {
            const keys = Object.keys(values);
            if (keys) {
                let returned = true;
                console.log(keys);
                console.log(values);
                keys.forEach((key) => {
                    if (key === "blockageDayNumber") {
                        const selectedBlockageDay = getSaleBlockageDayNumber(item.rates.sale);
                        if (values[key].toString() !== selectedBlockageDay.toString() && values[key].toString() !== item.rates.installment_sale.blockageDayNumber.toString()) {
                            returned = false;
                        }
                    }
                    else if(key === "dateRange") {
                        const beginDate = values[key][0];
                        const endDate = values[key][1];
                        if (beginDate && endDate) {
                            const selectedDate = moment(item["receivedDate"]);
                            const compare = selectedDate.isBetween(beginDate, endDate);
                            if (!compare) {
                                returned = false;
                            }
                        }
                    }
                    else {
                        if (item[key].toString() !== values[key].toString()) {
                            returned = false;
                        }
                    }
                });
                return returned
            }
            return true;
        });
        setResult(filteredResult);
    }

    const clearSearch = () => {
        setResult(data.getMidConfigurationList.result)
    }

    if (loading || acquirersLoading) return <p>Loading...</p>;
    if (error || acquirersError) return <p>Error...</p>;


    return (
        <PageContainer>

            <Card title={t("pos.midList")} style={{marginTop: "15px"}}>
                <TableTopSearch
                    acquirers={acquirersData?.getAcquirerList?.result}
                    clear={clearSearch}
                    search={search}
                    mids={result}
                />
                <BaseTable
                    columns={columns}
                    dataSource={result}
                    bordered
                    search={false}
                    pagination={false}
                    options={false}
                />
            </Card>
        </PageContainer>

    )
}

export default PosManagement