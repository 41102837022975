export const bankCredentialsMock: any = {

    "Ziraat Bank":
        [
            { code: "username", tooltip: "API Kullanıcı Adı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Numarası(Client Id)" },
            { code: "secretKey", tooltip: "Storekey(Üye İş Yeri Anahtarı)" },
            { code: "bank", referenceNo: "ziraat", readOnlyRefNo: true }
        ],
    "HalkBank":
        [
            { code: "username", tooltip: "API Kullanıcı Adı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Numarası(Client Id)" },
            { code: "secretKey", tooltip: "Storekey(Üye İş Yeri Anahtarı)" },
            { code: "bank", referenceNo: "halkbank", readOnlyRefNo: true }
        ],
    "IsBank":
        [
            { code: "username", tooltip: "API Kullanıcı Adı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Numarası(Client Id)" },
            { code: "secretKey", tooltip: "Storekey(Üye İş Yeri Anahtarı)" },
            { code: "bank", referenceNo: "is", readOnlyRefNo: true }
        ],
    "AkBank":
        [
            { code: "username", tooltip: "API Kullanıcı Adı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Numarası(Client Id)" },
            { code: "secretKey", tooltip: "Storekey(Üye İş Yeri Anahtarı)" },
            { code: "bank", referenceNo: "akbank", readOnlyRefNo: true }
        ],
    "AlternatifBank":
        [
            { code: "username", tooltip: "API Kullanıcı Adı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Numarası(Client Id)" },
            { code: "secretKey", tooltip: "Storekey(Üye İş Yeri Anahtarı)" },
            { code: "bank", referenceNo: "alternatifbank", readOnlyRefNo: true }
        ],
    "AnadoluBank":
        [
            { code: "username", tooltip: "API Kullanıcı Adı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Numarası(Client Id)" },
            { code: "secretKey", tooltip: "Storekey(Üye İş Yeri Anahtarı)" },
            { code: "bank", referenceNo: "anadolubank", readOnlyRefNo: true }
        ],
    "DenizBank":
        [
            { code: "ShopCode", tooltip: "Banka tarafından sağlanan mağaza kodu" },
            { code: "MerchantPass", tooltip: "İşyeri Anahtarı" },
            { code: "UserCode", tooltip: "API Kullanıcı Kodu" },
            { code: "UserPass", tooltip: "API Kullanıcı Şifresi" },
        ],
    "FinansBank":
        [
            { code: "UserCode", tooltip: "Kullanıcı Adı" },
            { code: "UserPass", tooltip: "Kullanıcı Şifre" },
            { code: "MerchantId", tooltip: "Üye İşyeri Numarası" },
            { code: "MerchantPass", tooltip: "3D Üye İşyeri Anahtarı" },
            { code: "paymentFacilitatorId", tooltip: "Ödeme Sağlayıcı ID" },
            { code: "memberId", tooltip: "İşyeri ID" },
        ],
    "Kuveyt Turk":
        [
            { code: "username", tooltip: "API Kullanıcı" },
            { code: "PASSWORD", tooltip: "API Kullanıcı Şifresi" },
            { code: "MerchantId", tooltip: "Mağaza Kodu" },
            { code: "customerId", tooltip: "Müşteri Numarası" },
        ],
    "VakifBank":
        [
            { code: "terminalId", tooltip: "Terminal Numarası" },
            { code: "midId", tooltip: "MID Numarası" },
            { code: "PASSWORD", tooltip: "Üye İşyeri API Şifresi" },
            { code: "MerchantType", tooltip: "Üye İşyeri Tipi - Ana Bayi: 1, Alt Bayi: 2" },
        ],
    "YapiKredi":
        [
            { code: "MerchantId", tooltip: "Üye İşyeri Numarası" },
            { code: "terminalId", tooltip: "Terminal Numarası" },
            { code: "POS_Token", tooltip: "Posnet Numarası" },
            { code: "secretKey", tooltip: "ENCKEY Bilgisi" },
        ],

}
