import {useLazyQuery, useMutation} from "@apollo/client";
import BaseTable from "@/components/base/table";
import { PageContainer } from "@ant-design/pro-layout";
import { useTranslation } from "react-i18next";
import { toPath } from "lodash";
import { formatAmount } from "@/utils/finance";
import {Button, Dropdown, Menu, Modal} from "antd";
import { getCompleted } from "@/features/financial-management/routes/completed/helper";
import GET_SETTLEMENTS from "@/features/financial-management/api/queries/get-settlements";
import EXPORT_SETTLEMENTS_CSV from "@/features/financial-management/api/mutations/export-settlements-csv";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import moment from "moment";
import PERMISSIONS from "@/config/permissions";
import {useEffect, useState} from "react";
import useAuthorization from "@/hooks/use-authorization";

const Completed = () => {
  const [isExportable, setIsExportable] = useState(false);
  const [getSettlements] = useLazyQuery(GET_SETTLEMENTS);
  const [exportSettlementsCSV] = useMutation(EXPORT_SETTLEMENTS_CSV);
  const { activeMerchantId, role, email } = useSelector((state: RootState) => state.auth.params);
  const { t } = useTranslation();
  const { checkPermission } = useAuthorization();


  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    //const canExport = await checkPermission(PERMISSIONS.financialManagement.payments.export);
    setIsExportable(true);
  }

  const onActionClick = (valorDate: any, exportType: any ) => {
    const date = moment(valorDate).format('YYYY-MM-DD');
    const body = {
      valorFromDate: date,
      valorToDate: date,
      selectedFields: exportedFields,
      status: 'APPROVED',
      merchantId: String(activeMerchantId),
      userRole: role,
      customerExportEmail: email,
      exportType,
    };
    exportSettlementsCSV({
      variables: body
    }).then((response) => {
      Modal.success({
        title: t("transaction.status.approved"),
        content: t("transaction.export.approved")
      });
    })
  }


  const exportedFields = [
    'psp=on',
    'parents=on',
    'company=on',
    'originalAmount=on',
    'netAmount=on',
    'commission=on',
    'created_at=on',
    'valorDate=on',
    'originalCurrency=on',
    'transactionUUID=on',
    'responseCode=on',
    'responseMessage=on',
    'relatedTransactionId=on',
    'creditcard=on',
    'transactionType=on',
    'installment=on',
    'referenceNo=on',
    'issuerBank=on',
    'loyalty=on',
  ].join('&');


  const TABLE_COLUMNS = [
    // FILTERS
    {
      title: t("settlement.date"),
      dataIndex: 'readyToPaymentDate',
      valueType: 'dateRange',
      hideInTable: true,
    },

    // COLUMNS
    {
      title: t("settlement.paymentDate"),
      dataIndex: 'readyToPaymentDate',
      valueType: 'date',
      hideInSearch: true,
      renderText: (value: string) => value,
    },
    {
      title: t("settlement.paidAmount"),
      dataIndex: toPath('totals.merchantPayout'),
      hideInSearch: true,
      renderText: (value: any, settlement: any) => formatAmount(value, settlement.settlementCurrency),
    },
    {
      title: t("settlement.fee"),
      dataIndex: toPath('totals.fee'),
      hideInSearch: true,
      renderText: (value: any, settlement: any) => formatAmount(value, settlement.settlementCurrency),
    },
    {
      title: t("settlement.totalRefund"),
      dataIndex: toPath('totals.refund'),
      hideInSearch: true,
      renderText: (value: any, settlement: any) => formatAmount(value, settlement.settlementCurrency),
    },
    {
      title: t("settlement.totalChargebackAmount"),
      dataIndex: toPath('totals.chargeBack'),
      hideInSearch: true,
      renderText: (value: any, settlement: any) => formatAmount(value, settlement.settlementCurrency),
    },
    {
      title: t("settlement.paidDate"),
      dataIndex: 'paidDate',
      valueType: 'date',
      hideInSearch: true,
      renderText: (value: string) => value,
    },
    {
      title: t("settlement.actions"),
      hideInSearch: true,
      render: (val: any) => {
        const menu = (
            <Menu>
              <Menu.Item onClick={() => onActionClick(val.readyToPaymentDate, 'csv')}>
                CSV
              </Menu.Item>
              <Menu.Item onClick={() => onActionClick(val.readyToPaymentDate, 'xlsx')}>
                Excel
              </Menu.Item>
            </Menu>
        );
        if (isExportable) {
          return (
              <Dropdown key="menu" overlay={menu} trigger={['click']}>
                <Button>
                  {t("settlement.export")}
                </Button>
              </Dropdown>
          );
        }
        return null;
      },
    },
  ];
  const initialVariables = {
    current: 1,
    merchantId: String(activeMerchantId),
    page: 1,
    pageSize: 50
  }

  return (
      <PageContainer>
        <BaseTable columns={TABLE_COLUMNS}
                   rowKey={(record: any) => record.readyToPaymentDate + "-" + record.totals.fee}
                   request={(params: any, filter: any) =>
                       getCompleted({ ...params, filter }, getSettlements, initialVariables)
                   }/>
      </PageContainer>
  )
};

export default Completed;
