import { Space, Statistic, Tag, Card } from "antd";
import { useTranslation } from "react-i18next";
import {
  findTransactionOperationColor,
  findTransactionStatusColor,
  isOperationRefund,
} from "@/utils/transaction";
interface BasketItemsProps {
  data: any;
}
const BasketItems = ({ data }: BasketItemsProps) => {
  const { t } = useTranslation();
  const { transactionId, operation, status, basketItems } = data;
  const tags = [
    <Tag color={findTransactionStatusColor(status)}>
      {t(`transaction.status.${status.toString().toLowerCase()}`)}
    </Tag>,
    isOperationRefund(operation) && (
      <Tag color={findTransactionOperationColor(operation)}>
        {t(`transaction.operation.${operation}`)}
      </Tag>
    ),
  ];
  return (
    <Space style={{ width: "100%" }} direction={"vertical"}>
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        <Statistic
          title={t("transaction.transactionId")}
          value={transactionId}
          suffix={tags}
        />
      </Space>
      <Space>
        {basketItems &&
          basketItems.map((item: any) => (
            <Card
              title={`${item.name} - ${item.quantity} Adet`}
              key={`${item.name}${item.quantity}`}
            >
              <p>{`Açıklama: ${item.description}`} </p>
              <p>{`Kategori: ${item.category}`} </p>
              <p>{`Birim Fiyat: ${item.unitPrice}`} </p>
            </Card>
          ))}
      </Space>
    </Space>
  );
};

export default BasketItems;
