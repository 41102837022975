import { gql } from "@apollo/client";

const EXPORT_SETTLEMENTS = gql`
  mutation (
    $customerExportEmail: String!
    $fromDate: String!
    $toDate: String!
    $merchantId: String!
    $exportType: String!
  ) {
    exportSettlements(
      customerExportEmail: $customerExportEmail
      fromDate: $fromDate
      toDate: $toDate
      merchantId: $merchantId
      exportType: $exportType
    ) {
      response
      status
    }
  }
`;

export default EXPORT_SETTLEMENTS;
