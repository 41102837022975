const modals = {
  "modals.user.create.title": "Yeni Kullanıcı",
  "modals.user.edit.title": "Kullanıcıyı Düzenle",

  // Buttons
  "modals.buttons.save": "Kaydet",
  "modals.buttons.cancel": "Vazgeç",
};

export default modals
