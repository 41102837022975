import { DatePicker, Select, Space } from 'antd';

interface DynamicFilterProps {
    options?: any,
    rangePickerProps: any,
    selectProps?: any
}

const DynamicFilter = ({ options, rangePickerProps, selectProps }: DynamicFilterProps) => {
    const renderRangePicker = () => {
        const { RangePicker } = DatePicker;
        return rangePickerProps ? <RangePicker {...rangePickerProps} /> : null
    };

    const renderSelect = () => {
        if (!options) {
            return null;
        }

        const renderedOptions = Object.keys(options).map((option) => (
            <Select.Option key={option} value={option}>{option}</Select.Option>
        ));

        return <Select {...selectProps}>{renderedOptions}</Select>;
    };

    return (
        <Space direction={'horizontal'}>
            {renderSelect()}
            {renderRangePicker()}
        </Space>
    );
}

export default DynamicFilter
