import { PageContainer } from "@ant-design/pro-layout";
import MerchantInfo from "@/features/settings/components/merchant-info";
import KeyInfo from "@/features/settings/components/key-info";

import "./index.less";
import useAuthorization from "@/hooks/use-authorization";
import {useEffect, useState} from "react";
import PERMISSIONS from "@/config/permissions";

const Merchant = () => {
  const [isVisibleApiKey, setIsVisibleApiKey] = useState(false);
  const [isVisibleMerchantInfo, setIsVisibleMerchantInfo] = useState(false);
  const { checkPermission } = useAuthorization();

  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
      //const permissions = [PERMISSIONS.settings.merchant.merchantInfo.show,PERMISSIONS.settings.merchant.apiKey.show];
      //const [canSeeMerchantInfo, canSeeApiKey] = await checkPermission(permissions);
      setIsVisibleApiKey(true);
      setIsVisibleMerchantInfo(true);
  }
  return (
    <PageContainer>
        { isVisibleApiKey && <KeyInfo />}
        { isVisibleMerchantInfo && <MerchantInfo />}
    </PageContainer>
  );
};

export default Merchant;
