const createPassword = {
    "createPassword.title": "Create Password",
    "createPassword.email": "Email",
    "createPassword.password": "Password",
    "createPassword.passwordConfirm": "Confirm Password",
    "createPassword.submit": "Create",
    "createPassword.error": "Registration failed. Please make a new request."
};

export default createPassword
