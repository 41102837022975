import BaseTable from "@/components/base/table";
import { Card, InputNumber } from "antd";
import cardInfo from "./cardInfo.json";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";


const TypeSale = ({ data, receivedValues }: any) => {
    const [tableColumns, setTableColumns] = useState([])
    const [tableData, setTableData] = useState([])
    const { t } = useTranslation();

    const handleChange = (index: number, key: string, value: any) => {
        setTableData((currentTableData: any) => currentTableData.map((item: any, i: number) => {
            return i === index ? { ...item, [key]: value } : item;
        }));
    }

    useEffect(() => {
        receivedValues((currentValues: any) => ({ ...currentValues, sale: [...tableData] }));
    }, [tableData, receivedValues])


    const createColumns = () => {
        const staticColumn: Array<any> = [
            {
                title: '',
                dataIndex: 'title',
                key: 'title',
                width: "30%",
                align: 'center',
                render: (value: any) => {
                    return value && value !== '-' ? value : "";
                },
            }
        ]

        const columns = cardInfo.map((item: any) => {
            return {
                title: item.title,
                dataIndex: item.name,
                key: item.id,
                align: "center",
                width: "",
                render: (value: any, record: any, index: number) => {
                    return <InputNumber
                        value={value}
                        onChange={(e) => handleChange(index, item.name, e)}
                        style={{ width: "100%" }}
                    />
                },
            }
        })

        return staticColumn.concat(columns);
    }

    const createData = () => {
        const createdTableData: any = [];

        const creditCards: any = { title: "", id: "sale-1" };
        const blockageDayNumbers: any = { title:`${t("pos.blockageDay")}`, id: "sale-2" };

        cardInfo.forEach((item: any) => {
            creditCards[item.name] = data[item.name];
            blockageDayNumbers[item.name] = data[`${item.name}_blockageDayNumber`];
        });

        createdTableData.push(creditCards, blockageDayNumbers)
        return createdTableData;
    }

    useEffect(() => {
        let columnsData: any = createColumns();
        let tablesData: any = createData();
        setTableColumns(columnsData);
        setTableData(tablesData);
    }, [])

    return (
        <Card title={t("pos.rates.for.transaction.type.sale")} style={{ marginTop: "15px" }}>
            {
                tableColumns &&
                <BaseTable
                    columns={tableColumns}
                    dataSource={tableData}
                    bordered
                    search={false}
                    pagination={false}
                    options={false}
                />
            }
        </Card>
    )
}

export default TypeSale;