import { gql } from "@apollo/client";

const GET_MERCHANT_USERS = gql`
  query ($userRole: UserRole!, $merchantId: String!) {
    getMerchantUsers(userRole: $userRole, merchantId: $merchantId) {
      merchantUsers {
        email
        id
        role
        name
      }
    }
  }
`;

export default GET_MERCHANT_USERS;
