import JWTDecode from "jwt-decode";


interface SubMerchantInterface {
    id: number
    name: string
    subMerchants: any
}

const login = async (loginMutation: any, inputValues: any) => {
    let authToken = "";
    await loginMutation({ variables: inputValues }).then((response: any) => {
        const { token } = response.data.login;
        authToken = token;
    })
    // @ts-ignore
    return authToken ? {...JWTDecode(authToken), token: authToken, loginStatus: true}: { loginStatus: false };
}

export const getCurrentMerchantsData = async (getCurrentMerchantsQuery: any, merchantId: any, token: string) => {
    const currentMerchantsResponse = await getCurrentMerchantsQuery({
        variables: {
            merchantId: String(merchantId),
        },
        context: {
            headers: {
                authorization: token,
            },
        },
    });

    const { getMerchant, getSubMerchants } = currentMerchantsResponse.data;
    const { id, name } = getMerchant.merchant;
    const formattedMainMerchant = [{ id, name }];
    const formattedSubMerchants = getSubMerchants.subMerchants.map((subMerchant: SubMerchantInterface) => ({ id: subMerchant.id, name: subMerchant.name, subMerchants: subMerchant.subMerchants }));
    const allMerchants = [ ...formattedMainMerchant, ...formattedSubMerchants ];

    return allMerchants;
}


export default login