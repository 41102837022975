import {Button, Card, Checkbox, Col, Form, Input, InputNumber, message, Row, Select} from 'antd';
import { useMutation } from "@apollo/client";
import { PlusOutlined } from "@ant-design/icons";
import FileUpload from "@/features/pay-with-link/components/pay-with-link/file-upload";
import CREATE_PAY_TO_LINK from "@/features/pay-with-link/api/mutations/createPayToLink";
import { RESPONSE_STATUS } from "@/config/constants";


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const initialValues = {
    cargo: false,
    check3D: false,
    desc: "",
    maxInstallmentNumber: 1,
    name: "",
    stock: null,
    price: {
        amount: null,
        fraction: null,
        currency: "TRY",
    }
}

const CreateLink = ({ apiKey, refetchList, createForm, paymentStatus, openPopup }: any) => {
    const [createPaytoLink] = useMutation(CREATE_PAY_TO_LINK);

    const onFinish = (values: any) => {
        const { price, ...rest } = values
        const requestParams = {
            createPayToLinkInput: {
                ...rest,
                currency: price.currency,
                amount: `${price.amount}.${price.fraction}`,
                apiKey: apiKey
            }
        };
        if(values.file) {
            createPaytoLink({
                variables: requestParams
            }).then((response) => {
                const { result, status, message: responseMessage } = response.data.createPayToLink;
                if (status !== RESPONSE_STATUS.APPROVED) {
                    throw new Error(responseMessage);
                }
                refetchList();
                createForm.resetFields();
                openPopup(result);
                message.success(responseMessage);
            }).catch((error) => {
                message.error(error.message);
            });
        } else {
            message.error("Resim alanı boş geçilemez!")
        }

    };

    return (
        <>
            <Card title="Link Oluştur">
                <Form {...layout}
                    form={createForm}
                    name="nest-messages"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    labelAlign="left"
                    autoComplete={"off"}
                    initialValues={initialValues}>
                    <Row gutter={30} wrap={true}>
                        <Col span={12}>
                            <Form.Item name={'name'} label="Ürün Adı" rules={[{ required: true }]}>
                                <Input placeholder="Ürün adı gir" />
                            </Form.Item>

                            <Form.Item label="Tutar">
                                <Input.Group compact>
                                    <Form.Item noStyle name={['price', 'amount']} rules={[{ required: true }]}>
                                        <InputNumber min={0} controls={false}
                                            style={{ width: '25%', marginRight: "5px" }} placeholder="0" />
                                    </Form.Item>
                                    .
                                    <Form.Item noStyle name={['price', 'fraction']} rules={[{ required: true }]}>
                                        <InputNumber<string> stringMode min={"00"} max={"99"} minLength={2}
                                            controls={false}
                                            style={{ width: '25%', marginLeft: "5px" }}
                                            placeholder="00"
                                        />
                                    </Form.Item>

                                    <span style={{ padding: "0 10px" }} />
                                    <Form.Item noStyle name={['price', 'currency']}>
                                        <Select style={{ width: '25%' }}>
                                            <Select.Option value="TRY">TRY</Select.Option>
                                            <Select.Option value="EUR">EUR</Select.Option>
                                            <Select.Option value="USD">USD</Select.Option>
                                            <Select.Option value="GBP">GBP</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>

                            <Form.Item name={'desc'} label="Açıklama" rules={[{ required: true }]}>
                                <Input placeholder="Açıklama gir" />
                            </Form.Item>

                            <Form.Item name={'maxInstallmentNumber'} label="Taksit Sayısı"
                                rules={[{ required: true }]}>
                                <Select style={{ width: '50%' }}>
                                    {Array.from(Array(12).keys()).map((value: number) => (
                                        <Select.Option value={value + 1}>{value + 1}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item name={'stock'} label="Stok Limiti" rules={[{ required: true }]}>
                                <InputNumber min={0} controls={false} placeholder="Stok Limiti gir" style={{ width: '50%' }} />
                            </Form.Item>

                            <Form.Item name={'cargo'} label="Alıcıdan Kargo Talep Et" style={{ marginBottom: "5px" }}
                                valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>

                            <Form.Item name={'check3D'} label="3D Secure" valuePropName={'checked'}>
                                <Checkbox disabled={paymentStatus?.result?.checkbox.disabled} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    <PlusOutlined /> Link Oluştur
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingBottom: 20 }}>
                            <Form.Item name={"file"} valuePropName={'fileList'}>
                                <FileUpload onDone={(file: string | null) => createForm.setFieldValue("file", file)} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export default CreateLink;
