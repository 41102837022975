import { gql } from "@apollo/client";

const GET_VIRTUAL_POS_STATS = gql `
    query ($fromDate: String!, $toDate: String!, $merchantId: String!, $status: [String]!, $refundOperation: [String], $voidsOperation: [String], $operation: [String]) {
         getSuccessTransactionCountByDate(fromDate: $fromDate, status: $status, toDate: $toDate, operation: $operation) {
            status
            response {
                count
                date
                hour
                percentage
                total
            }
        },
       
        getRefundTransactionCountByDate(fromDate: $fromDate, status: $status, toDate: $toDate, operation: $refundOperation) {
            status
            response {
                count
                date
                hour
                percentage
                total
            }
        },
        
        getVoidsTransactionCountByDate(fromDate: $fromDate, status: $status, toDate: $toDate, operation: $voidsOperation) {
            status
            response {
                count
                date
                hour
                percentage
                total
            }
        },
        
        getTransactionsReport(fromDate: $fromDate, toDate: $toDate, merchantId: $merchantId) {
            status
            response {
                count
                currency
                total
                _id
            }
        },
        
        getRefundsReport(fromDate: $fromDate, toDate: $toDate, merchantId: $merchantId) {
            status
            response {
                count
                currency
                total
                _id
            }
        },
        
        getVoidsReport(fromDate: $fromDate, toDate: $toDate, merchantId: $merchantId) {
            status
            response {
                count
                currency
                total
                _id
            }
        }
    }
`;

export default GET_VIRTUAL_POS_STATS;