import { gql } from "@apollo/client";

const SEND_MAIL_WITH_PAY_TO_LINK = gql `
    mutation SendMailWithPayToLink($sendMailWithPayToLinkInput: SendMailWithPayToLinkInput!) {
      sendMailWithPayToLink(sendMailWithPayToLinkInput: $sendMailWithPayToLinkInput) {
        status
        message
      }
    }
`;

export default SEND_MAIL_WITH_PAY_TO_LINK