import {gql} from "@apollo/client";

const GET_CUSTOM_PAY_TO_LINK = gql`
    query GetCustomPayToLink($apiKey: String!) {
      getCustomPayToLink(apiKey: $apiKey) {
        status
        message
        result {
          id
        }
      }
}
`;

export default GET_CUSTOM_PAY_TO_LINK;