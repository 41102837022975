import React, { useEffect, useState } from "react";
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import { FileImageOutlined } from '@ant-design/icons';
import { Upload, UploadFile } from 'antd';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const customRequest = (info: any) => {
    setTimeout(() => info.onSuccess("OK"), 0);
};

const props: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: true,
    accept: "image/*",
};

interface FileUploadProps {
    onDone: (base64Code: string | null) => void;
    url?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ onDone, url }) => {
    const [file, setFile] = useState<string | null>('');

    const handleChange = (info: any) => {
        const { status } = info.file;

        if (status === "done") {
            getBase64(info.file.originFileObj as RcFile, (encodedImage) => {
                setFile(encodedImage);
            });
        }
    };

    const handleRemove = (info: UploadFile) => {
        setFile('');
    };

    useEffect(() => {
        onDone(file ? file : null)
    }, [file])

    return (
        <Upload.Dragger
            {...props}
            onChange={handleChange}
            customRequest={(data) => customRequest(data)}
            onRemove={handleRemove}
            style={{ padding: "30px" }}
        >
            {!url && !file &&
                <>
                    <p className="ant-upload-drag-icon">
                        <FileImageOutlined />
                    </p>
                    <p className="ant-upload-hint">
                        <span style={{ color: "#307fe2" }}>Görsel seçiniz</span> veya buraya<br />
                        sürükleyip bırakınız
                    </p>
                </>
            }
            {url && !file && <img
                src={url}
                style={{ width: "100%", objectFit: "contain" }}
                alt="link"
            />}
            {file && <img
                src={file}
                style={{ width: "100%", objectFit: "contain" }}
                alt="link"
            />}
        </Upload.Dragger>
    );
}

export default FileUpload;