const CHART_TYPES = {
    TINY: 'TINY',
    DEFAULT: 'DEFAULT',
};

const sharedStyles = {
    areaStyle: () => ({ fill: 'l(270) 0:#ffffff 1:#5B8FF9' }),
};

const tinyChartConfig = {
    ...sharedStyles,
    smooth: true,
    yAxis: false,
    xAxis: false,
    appendPadding: [10, 0],
    height: 80,
};

const defaultChartConfig = {
    ...sharedStyles,
};

let finalConfig = {};

const getChartConfig = (type: string, customConfig: any) : any => {
    switch (type) {
        case CHART_TYPES.TINY:
            finalConfig = { ...tinyChartConfig, ...customConfig };
            break;
        default:
            finalConfig = { ...defaultChartConfig, ...customConfig };
            break;
    }
    return finalConfig;
}


export default getChartConfig
