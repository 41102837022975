import { StatisticCard, Statistic } from '@ant-design/pro-card';
import { Typography, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useQuery } from "@apollo/client";
import {useEffect, useState} from "react";
import moment from "moment";
import { formatAmount } from "@/utils/finance";
import { populateNDaysData, populate24HoursData } from "@/utils/date";
import _ from "lodash";
import TinyChart, { CHART_TYPES } from '@/components/charts/tiny-chart';
import { paymentMethods } from "@/utils/dashboard";

import GET_VIRTUAL_POS_STATS from "@/features/dashboard/api/queries/get-virtual-pos-stats";
import DynamicFilter from "@/components/base/filters";
import useAuthorization from "@/hooks/use-authorization";
import PERMISSIONS from "@/config/permissions";

const { Title } = Typography;

interface VirtualPosStatsProps {
    isResponsive: boolean
}
const currencies = {
    TRY: 'TRY',
    USD: 'USD',
    EUR: 'EUR',
};
const VirtualPosStats = ({ isResponsive }: VirtualPosStatsProps) => {
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const { checkPermission } = useAuthorization();
    const [isVisibleFilters, setIsVisibleFilters] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dateRange, setDateRange] = useState({
        fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
    });
    const [newData, setNewData] = useState({});
    const { data, error, loading, refetch } = useQuery(GET_VIRTUAL_POS_STATS, {
        variables: {
            fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            merchantId: String(activeMerchantId),
            operation: [
                paymentMethods.DIRECT,
                paymentMethods.STORED,
                paymentMethods['3D'],
                paymentMethods['3DAUTH'],
            ],
            refundOperation: ["REFUND"],
            voidsOperation: ["VOID"],
            status: ["APPROVED"]
        },
        fetchPolicy: "no-cache"
    });
    const [selectedCurrency, setSelectedCurrency] = useState(currencies.TRY);


    const prepareData = (data: any) => {
        const preparedData = {
            refundTransactionsCountReport: data.getRefundTransactionCountByDate.response,
            refundTransactionsReport: data.getRefundsReport.response,
            successfulTransactionsCountReport: data.getSuccessTransactionCountByDate.response,
            successfulTransactionsReport: data.getTransactionsReport.response,
            voidTransactionsCountReport: data.getVoidsTransactionCountByDate.response,
            voidTransactionsReport: data.getVoidsReport.response
        };
        setNewData(preparedData);
        setIsLoading(false);
    }

    useEffect(() => {
        if (data) {
            prepareData(data);
            checkPermissions();
        }
    }, [data]);

    const checkPermissions = async () => {
        //const controlFilters = await checkPermission(PERMISSIONS.dashboard.vpos_report.filter);
        setIsVisibleFilters(true);
    }

    if (error) return <p>error</p>;

    if(loading) return <p>loading</p>;
    const getCurrencyData = (innerData: any) => {
        const found = innerData.find((innerData: any) => innerData._id === selectedCurrency);
        return found ?? { count: '0', total: '-' };
    };
    const selectCurrencyData = () => {
        // @ts-ignore
        const { successfulTransactionsReport, refundTransactionsReport, voidTransactionsReport } = newData;

        const selectedSuccessfulTransactions = getCurrencyData(successfulTransactionsReport);
        const selectedRefundTransactions = getCurrencyData(refundTransactionsReport);
        const selectedVoidTransactions = getCurrencyData(voidTransactionsReport);

        return { selectedSuccessfulTransactions, selectedRefundTransactions, selectedVoidTransactions };
    };
    const formatData = () => {
        // @ts-ignore
        const { successfulTransactionsCountReport,  refundTransactionsCountReport,  voidTransactionsCountReport,
        } = newData;

        const calculateSuccessfulTransactionCount = (report: any) => {
            return report.reduce((prev: any, curr: any) => prev + curr.total, 0);
        };
        const { selectedSuccessfulTransactions, selectedRefundTransactions, selectedVoidTransactions } =
            selectCurrencyData();
        const successfulTransactionCount = calculateSuccessfulTransactionCount(
            successfulTransactionsCountReport,
        );


        return [
            {
                title: 'Başarılı İşlem Tutarı',
                value: formatAmount(selectedSuccessfulTransactions.total, selectedCurrency),
                subtitle: 'İşlem Sayısı',
                subValue: successfulTransactionCount,
                chartData: successfulTransactionsCountReport,
            },
            {
                title: 'Başarılı İade İşlem Tutarı',
                value: formatAmount(selectedRefundTransactions.total, selectedCurrency),
                subtitle: 'İşlem Sayısı',
                subValue: selectedRefundTransactions.count,
                chartData: refundTransactionsCountReport,
            },
            {
                title: 'Başarılı İptal İşlem Tutarı',
                value: formatAmount(selectedVoidTransactions.total, selectedCurrency),
                subtitle: 'İşlem Sayısı',
                subValue: selectedVoidTransactions.count,
                chartData: voidTransactionsCountReport,
            },
        ];
    };

    const renderFilters = () => {
        if (!isVisibleFilters) {
            return null
        }
        const selectProps = {
            defaultValue: currencies.TRY,
            onChange: (currency: any) => setSelectedCurrency(currency),
        };

        const rangePickerProps = {
            ranges: {
                'Son 7 Gün': [moment().subtract('1', 'weeks'), moment()],
                'Son 14 Gün': [moment().subtract('2', 'weeks'), moment()],
                'Son 30 Gün': [moment().subtract('30', 'days'), moment()],
            },
            onChange: (dates: any) => {
                if (dates) {
                    refetch({
                        fromDate: moment(dates[0]).format('YYYY-MM-DD'),
                        toDate: moment(dates[1]).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD'),
                        merchantId: String(activeMerchantId)
                    })
                }
            },
        };

        return (
            <DynamicFilter
                selectProps={selectProps}
                options={currencies}
                rangePickerProps={rangePickerProps}
            />
        );
    };

    const renderCells = () => {
        const statistics = formatData();
        return statistics.map((statistic) => {
            const { title, value, subtitle, subValue, chartData } = statistic;
            const { fromDate, toDate } = dateRange;
            let sortedDateData;
            let chartMeta = {
                total: { alias: 'Günlük Toplam' },
                date: {
                    formatter: (dateString: any) => moment(dateString).format('DD.MM - dddd'),
                    range: [0, 1],
                },
            };

            if (!moment(fromDate).isSame(moment(toDate), 'day')) {
                const filteredData = populateNDaysData(chartData, fromDate, toDate);
                sortedDateData = _.orderBy(filteredData, (firstDate: any, secondDate: any) =>
                    moment(firstDate).diff(moment(secondDate)),
                );
            } else {
                sortedDateData = populate24HoursData(
                    chartData[0],
                    //@ts-ignore
                    moment(toDate).diff(moment(fromDate), 'days'),
                );
                chartMeta = {
                    total: { alias: 'Saatlik Toplam' },
                    // @ts-ignore
                    date: {
                        range: [0, 1],
                    },
                };
            }

            const chartConfig = {
                data: sortedDateData,
                xField: 'date',
                yField: 'total',
                meta: chartMeta,
            };

            return (
                <StatisticCard key={Math.floor(Math.random() * 100000)}>
                    <Statistic title={title} value={value} layout={'vertical'} />
                    <TinyChart type={CHART_TYPES.TINY} customConfig={chartConfig} />
                    <Statistic title={subtitle} value={subValue} layout={'vertical'} />
                </StatisticCard>
            );
        });
    };

    return (
        <StatisticCard
            title={<Title level={3}>Sanal POS</Title>}
            extra={renderFilters()}
            headerBordered
        >
            <StatisticCard.Group split={isResponsive ? 'horizontal' : 'vertical'}>
                {isLoading ? <Skeleton active /> : renderCells()}
            </StatisticCard.Group>
        </StatisticCard>
    );
};

export default VirtualPosStats;
