import BaseTable from "@/components/base/table";
import { PageContainer } from "@ant-design/pro-layout";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Dropdown, Menu, Modal } from 'antd';
import { exportFile, getTransactions } from "@/features/transactions/routes/all/helper";
import { useState, useRef, useEffect } from "react";
import GET_TRANSACTIONS from "@/features/transactions/api/queries/get-transactions";
import { useSelector, } from "react-redux";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";
import RefundModal from "@/features/transactions/components/refund-modal";
import { RESPONSE_STATUS } from "@/config/constants";
import getColumns from "@/features/transactions/routes/all/table-config";
import useAuthorization from "@/hooks/use-authorization";
import PERMISSIONS from "@/config/permissions";
import { DownOutlined } from '@ant-design/icons';
import EXPORT_TRANSACTION_LIST from "../../api/mutations/export-transaction-list";
import PreAuthModal from "../preAuth";
import { useNavigate } from "react-router-dom";

const All = () => {
  const { t } = useTranslation();
  const auth = useSelector((state: RootState) => state.auth.params);
  const { checkPermission } = useAuthorization();
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleTrxDetail, setIsVisibleTrxDetail] = useState(false);
  const [isVisibleRefund, setIsVisibleRefund] = useState(false);
  const [isExportable, setIsExportable] = useState(false);

  const [getTransactionsGQL, { refetch }] = useLazyQuery(GET_TRANSACTIONS);
  const [exportTransactionList] = useMutation(EXPORT_TRANSACTION_LIST);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);
  const [openCaptureModal, setOpenCaptureModal] = useState(false)
  const formRef = useRef();
  const actionRef = useRef();
  const navigate = useNavigate();


  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = async () => {
    //const permissions = [PERMISSIONS.transactions.vpos_trx.export, PERMISSIONS.transactions.vpos_trx.filter, PERMISSIONS.transactions.vpos_trx.detail.show, PERMISSIONS.transactions.vpos_trx.refund.show];
    //const [canExport, virtualPosFilter, trxDetail, refund] = await checkPermission(permissions);
    setIsExportable(true);
    setIsVisibleFilter(true);
    setIsVisibleTrxDetail(true);
    setIsVisibleRefund(true);
  }

  const onDetailClick = (url: string, valorDate: string, customerInfo: string) => {
    navigate(url,{ state:{
      selectedTransactionValorDate: valorDate,
      selectedCustomerInfo: customerInfo
    }});
  }

  const initialVariables = {
    pageSize: 50,
    page: 1,
    current: 1,
    merchantId: String(auth.activeMerchantId),
  };
  const TABLE_COLUMNS = getColumns({
    isVisibleFilter,
    isVisibleTrxDetail,
    isVisibleRefund,
    setSelectedTransaction,
    onDetailClick,
    t,
    setOpenCaptureModal
  });

  const columnsStateMap = {
    amount: {
      visible: true,
    },
    cardNumber: {
      visible: false,
    },
    fullname: {
      visible: false,
    },
  };

  const toolBarRender = () => {
    const menu = (
      <Menu>
        <Menu.Item onClick={() => onExport('csv')} key="1">
          CSV
        </Menu.Item>
        <Menu.Item onClick={() => onExport('xlsx')} key="2">
          Excel
        </Menu.Item>
      </Menu>
    );
    if (isExportable) {
      return [
        <Dropdown key=" menu " overlay={menu} arrow={true} trigger={["click"]}>
          <Button>
            {t("transaction.export")}
            <DownOutlined />
          </Button>
        </Dropdown>,
      ];
    }
    return null;
  };

  const onExport = async (exportType: string) => {
    // @ts-ignore
    const { getFieldValue } = formRef.current;
    const dateRange = getFieldValue('dateRange');
    const status = getFieldValue('status');
    const operation = getFieldValue('operation');
    const trxListType = getFieldValue('trxListType');
    if (dateRange) {
      const requestParams = {
        exportType,
        dateRange,
        status,
        operation,
        trxListType,
        email: auth.email,
        merchantId: String(auth.activeMerchantId)
      }
      const variables = exportFile(requestParams);

      exportTransactionList({
        variables
      }).then((response) => {
        const { status } = response.data.exportTransactionList;
        if (status === RESPONSE_STATUS.APPROVED) {
          Modal.success({
            title: t("transaction.status.approved"),
            content: t("transaction.export.approved")
          });
        }
      })
    } else {
      Modal.error({
        title: t("transaction.status.error"),
        content: t("transaction.export.dateError")
      });
    }
  };



  const closePreAuthModal = (responseStatus: String) => {
    if (responseStatus === RESPONSE_STATUS.APPROVED) {
      refetch().then(()=>{
        setSelectedTransaction(null)
        //@ts-ignore
        actionRef.current.reload()
      })  
    }
    setOpenCaptureModal(false);
  };

  return (
    <PageContainer>
      <BaseTable
        columns={TABLE_COLUMNS}
        formRef={formRef}
        actionRef={actionRef}
        toolBarRender={toolBarRender}
        rowKey={(record: any) => record.transaction.merchant.transactionId}
        request={(params: any, filter: any) =>
          getTransactions(
            { ...params, filter },
            getTransactionsGQL,
            initialVariables
          )
        }

        columnsState={{
          defaultValue: columnsStateMap
        }}
      />
      {
        //@ts-ignore
        selectedTransaction?.transaction?.merchant?.type === 'PREAUTH' && openCaptureModal && (
          <PreAuthModal
            transaction={selectedTransaction}
            //@ts-ignore
            openCaptureModal={openCaptureModal}
            //@ts-ignore
            closePreAuthModal={closePreAuthModal}
          />
        )}
      {
        selectedTransaction?.transaction?.merchant?.type === 'AUTH' && (
          <RefundModal
            transaction={selectedTransaction}
            closeModal={() => {
              refetch().then(() => {
                setSelectedTransaction(null);
              });
            }}
          />
        )
      }
    </PageContainer>
  );
};

export default All;
