import { ProFormText } from "@ant-design/pro-form";
import { LockOutlined } from "@ant-design/icons";

const PasswordInput = () => {
  const placeholder = "Parola";
  const rules = [
    {
      required: true,
      message: "Parolanızı girin!",
    },
  ];

  return (
    <ProFormText.Password
      name="password"
      fieldProps={{
        size: "large",
        prefix: <LockOutlined className="prefixIcon" />,
      }}
      placeholder={placeholder}
      rules={rules}
    />
  );
};

export default PasswordInput;
