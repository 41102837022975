import { Descriptions, Space, Typography, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { RootState } from "@/store";
import GET_MERCHANT from "@/api/queries/get-merchant";

const { Title } = Typography;

const MerchantInfo = () => {
  const { t } = useTranslation();
  const activeMerchantId = useSelector(
    (state: RootState) => state.auth.params.activeMerchantId
  );
  const { loading, data, error } = useQuery(GET_MERCHANT, {
    variables: {
      merchantId: String(activeMerchantId),
    },
  });

  if (loading) return <p>Loading</p>;
  if (error) return <p>Error</p>;
  return (
    <Descriptions
      title={
        <Space
          direction={"vertical"}
          style={{ width: "100%", marginTop: "16px" }}
        >
          <Title level={4}>{t("pages.merchant.settings.companyInfo")}</Title>
          <Divider style={{ margin: "0 0 4px 0" }} />
        </Space>
      }
      labelStyle={{ fontWeight: "bold" }}
      bordered
      column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item label={t("pages.merchant.settings.tradeName")}>
        {data.getMerchant.merchant.tradeName}
      </Descriptions.Item>
      <Descriptions.Item label={t("pages.merchant.settings.iban")}>
        {data.getMerchant.merchant.iban}
      </Descriptions.Item>
      <Descriptions.Item label={t("pages.merchant.settings.companyType")}>
        {data.getMerchant.merchant.type}
      </Descriptions.Item>
      <Descriptions.Item label={t("pages.merchant.settings.domain")}>
        {data.getMerchant.merchant.name}
      </Descriptions.Item>
      <Descriptions.Item label={t("pages.merchant.settings.email")}>
        {data.getMerchant.merchant.email}
      </Descriptions.Item>
      <Descriptions.Item label={t("pages.merchant.settings.status")}>
        {data.getMerchant.merchant.status3D.join(", ")}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default MerchantInfo;
