import { useState, useEffect } from "react";

// Libraries
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

// Components
import BaseModal from "../base";
import CreateUserForm from "@/features/settings/components/form/create";
import BaseResult from "@/components/base-result";
import { Form } from "antd";
import { RESPONSE_STATUS } from "@/config/constants";

// Queries
import CREATE_MERCHANT_USER from "@/features/settings/api/create-merchant-user";

interface CreateUserModalProps {
  visible: boolean;
  onProcessEnd: () => void;
  external: any;
}

const ModalUserCreate = ({
  onProcessEnd,
  visible,
  external,
}: CreateUserModalProps) => {
  const [formRef] = Form.useForm();
  const [createMerchantUser] = useMutation(CREATE_MERCHANT_USER);
  const [error, setError] = useState(null)
  const [result, setResult] = useState<string | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    formRef.resetFields();
    setResult(undefined);
  }, [formRef, visible]);

  const onOk = async () => {
    const formValues = await formRef.validateFields().catch(() => false);
    if (!formValues) return;

    try {
      
      const createMerchantUserResponse = await createMerchantUser({
        variables: {
          ...formValues,
          merchantId: String(external.activeMerchantId),
        },
      });
      setResult(createMerchantUserResponse?.data?.createMerchantUser?.status);
    } catch (error) {
      //@ts-ignore
      const errorMEssage = error.graphQLErrors
      //@ts-ignore
      setError(errorMEssage[0]?.extensions?.response?.body?.message)
    }
  };
  const renderModalContent = () => {
    if (error) {
      return {
        showModalFooter: false,
        component: (
          <BaseResult
            status={"error"}
            title={t("results.error.title")}
            subTitle={error}
            buttonText={t("component.button.tryAgain")}
            buttonOnClick={() => setError(null)}
          />
        ),
      };
    }
    switch (result) {
      case RESPONSE_STATUS.DECLINED:
        return {
          showModalFooter: false,
          component: (
            <BaseResult
              status={"error"}
              title={t("results.error.title")}
              subTitle={t("results.error.subtitle")}
              buttonText={t("component.button.tryAgain")}
              buttonOnClick={() => setResult(undefined)}
            />
          ),
        };
      case RESPONSE_STATUS.APPROVED:
        return {
          showModalFooter: false,
          component: (
            <BaseResult
              status={"success"}
              title={t("results.success.title")}
              subTitle={t("results.success.subtitle")}
              buttonText={t("component.button.close")}
              buttonOnClick={onProcessEnd}
            />
          ),
        };
      default:
        return {
          component: <CreateUserForm formRef={formRef} />,
        };
    }
  };

  const modalContent = renderModalContent();

  return (
    <BaseModal
      visible={visible}
      title={t("modals.user.create.title")}
      onCancel={onProcessEnd}
      onOk={() => onOk()}
      footer={modalContent.showModalFooter}
    >
      {modalContent.component}
    </BaseModal>
  );
};

export default ModalUserCreate;
