import { gql } from "@apollo/client";

const RESETPASSWORD = gql`
    mutation Mutation($email: String!){
        resetPassword(email: $email){
            date
            message
            status
        }
    }
`

export default RESETPASSWORD