import { gql } from "@apollo/client";

const SEND_CUSTOM_MAIL_WITH_PAY_TO_LINK = gql `
    mutation SendCustomMailWithPayToLink($sendCustomMailWithPayToLinkInput: SendCustomMailWithPayToLinkInput!) {
      sendCustomMailWithPayToLink(sendCustomMailWithPayToLinkInput: $sendCustomMailWithPayToLinkInput) {
        status
        message
      }
    }
`;

export default SEND_CUSTOM_MAIL_WITH_PAY_TO_LINK