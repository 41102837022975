import component from "./tr-TR/component";
import globalHeader from "./tr-TR/globalHeader";
import menu from "./tr-TR/menu";
import pwa from "./tr-TR/pwa";
import settingDrawer from "./tr-TR/settingDrawer";
import settings from "./tr-TR/settings";
import pages from "./tr-TR/pages";
import others from "./tr-TR/others";
import transactions from "./tr-TR/transactions";
import settlements from "./tr-TR/settlements";
import form from "./tr-TR/form";
import createPassword from "./tr-TR/createPassword";
import financialManagement from "./tr-TR/financialManagement";
import modals from "./tr-TR/modals";
import results from "./tr-TR/results";
import { posStatement } from "./tr-TR/posStatement";
import payment from "./tr-TR/payment";
import posManagement from "@/locales/tr-TR/posManagement";

const TR = {
  ...others,
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...transactions,
  ...createPassword,
  ...settlements,
  ...form,
  ...financialManagement,
  ...modals,
  ...results,
  ...posStatement,
  ...payment,
  ...posManagement
};

export default TR
