import { gql } from "@apollo/client";

const GET_ACQUIRER_LIST = gql`
    query Query($merchantId: String!) {
      getAcquirerList(merchantId: $merchantId) {
        result {
          id
          name
        }
      }
    }
`;

export default GET_ACQUIRER_LIST