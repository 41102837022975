import { gql } from "@apollo/client";

const GET_MIDS_LIST = gql`
    query($merchantId: Int!) {
      getMidConfigurationList(merchantId: $merchantId) {
        status
        result {
            id
            name
            acquirer
            descriptor
            blockageDayNumber
            receivedDate
            createdAt
            rates {
                sale 
                installment_sale
            }
        } 
        
      }
    }
`;

export default GET_MIDS_LIST