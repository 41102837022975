import { ProFormText } from "@ant-design/pro-form";
import { UserOutlined } from "@ant-design/icons";

const EmailInput = () => {
  const placeHolder = "E-Posta Adresi : ";
  const rules = [
    {
      required: true,
      message: "Email adresi girin!",
    },
  ];
  return (
    <ProFormText
      name="email"
      fieldProps={{
        size: "large",
        prefix: <UserOutlined className="prefixIcon" />,
      }}
      placeholder={placeHolder}
      rules={rules}
    />
  );
};

export default EmailInput;
