import CreatePaymentItem from "@/features/payment-items/routes/create";
import ListPaymentItem from "@/features/payment-items/routes/list";

const createPaymentItemRoutes = {
  path: "payment-items",
  children: [
    {
      path: "payment-item-list",
      element: <ListPaymentItem />,
    },
    {
      path: "create-payment-item",
      element: <CreatePaymentItem />,
    }
  ]
};

export default createPaymentItemRoutes;
