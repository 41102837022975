import { gql } from "@apollo/client";

const GET_PHYSICAL_POS_TRANSACTIONS = gql `
  query ($pageSize: Int, $page: Int, $merchantId: String!, $current: Int, $filterValue: String, $filterField: String, $fromDate: String, $toDate: String, $status: [String], $operation: [String]) {
      getPhysicalPosTransactions(pageSize: $pageSize, page: $page, merchantId: $merchantId, current: $current, filterValue: $filterValue, filterField: $filterField, fromDate: $fromDate, toDate: $toDate, status: $status, operation: $operation) {
            current_page
            first_page_url
            data {
              totalVal
              transactionCount
              amount
              cardNo
              code
              currency
              customField1
              customField2
              customField3
              date
              installment
              merchant {
                city
                descriptor
                iban
                id
                mcc
                name
                parentId
                sectorCode
                settlementConfig
                taxId
                tckn
                town
              }
              provisionNo
              pspRefNo
              refNo
              rrn
              status
              terminalId
              type
            }
            from
            last_page
            last_page_url
            next_page_url
            path
            per_page
            prev_page_url
            to
            total
      }
  }
`

export default GET_PHYSICAL_POS_TRANSACTIONS