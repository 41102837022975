import {PageContainer} from "@ant-design/pro-layout";
import {Button, Card, Modal, Input} from 'antd';
import {CloseOutlined, EditOutlined, PlusOutlined, ShakeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import BaseTable from "@/components/base/table";
import data from "./mock.json";
import moment from "moment";

const ListPaymentItem = () => {
    const { confirm } = Modal;

    const showConfirm = (Link: string) => {
        confirm({
            title: 'Link Paylaş:',
            icon: <ShakeOutlined/>,
            content: <Input type = "text" value={Link}/>,
            okText: 'Kopyala',
            cancelText: 'Vazgeç',
            onOk() {
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const Styles : any = {
            activeStyle: {
                background: "#1677ff",
                color: "#FFF",
                width: 60,
                margin: "auto",
                padding: "3px 0",
                borderRadius: "5px"

            },
            passiveStyle: {
                background: "#CDCDCD",
                color: "#FFF",
                width: 60,
                margin: "auto",
                padding: "3px 0",
                borderRadius: "5px"
            }
    }

    const TABLE_COLUMNS = [
        // COLUMNS
        {
            title: "Kullanıcı",
            dataIndex: 'user',
            hideInSearch: true,
        },
        {
            title: "Açıklama",
            dataIndex: 'description',
            align: "center",
            hideInSearch: true,
        },
        {
            title: "Tutar",
            align: "center",
            dataIndex: 'price',
            hideInSearch: true,
            render: (text: any, record: any, index: any) => {
                return (<>{record.price} <span style={{color: "#1677ff", fontWeight: "bold"}}>{record.currency}</span></>);
            }
        },
        {
            title: "Ödeme Şekli",
            align: "center",
            dataIndex: 'paymentType',
            hideInSearch: true,
        },
        {
            title: "Aktif",
            align: "center",
            dataIndex: 'isActive',
            hideInSearch: true,
            render: (status: boolean) =>
                <div style={status ? Styles.activeStyle : Styles.passiveStyle}>
                    {status ? "Aktif" : "Pasif"}
                </div>,

        },
        {
            title: "Geçerlilik Tarihi",
            dataIndex: 'effectiveDate',
            align: "center",
            render: (text: any, record: any, index: any) => {
                return (<>{moment(record.fromDate).format("DD/MM/YYYY")} <strong>{" > "}</strong> {moment(record.toDate).format("DD/MM/YYYY")}</>);
            }
        },
        {
            title: "Ayarlar",
            align: "center",
            dataIndex: 'settings',
            hideInSearch: true,
            render: (text: any, record: any, index: any) => {
                return (
                    <>
                        <Button
                            onClick={() => showConfirm(record.shareLink)}
                            icon={<ShakeOutlined/>}
                            style={{
                            border: "1px solid #e0a80f",
                            color: "#e0a80f",
                            marginLeft: 10,
                            boxShadow: "none"
                        }}>Link Paylaş</Button>
                        <Button icon={<EditOutlined/>} style={{
                            border: "1px solid #1677ff",
                            color: "#1677ff",
                            marginLeft: 10,
                            boxShadow: "none"
                        }}>Düzenle</Button>
                        <Button icon={<CloseOutlined/>} style={{
                            border: "1px solid #dc0f0f",
                            color: "#dc0f0f",
                            marginLeft: 10,
                            boxShadow: "none"
                        }}>Sil</Button>

                    </>
                );

            }
        }
    ];
    return (
        <PageContainer>
            <Card style={{marginBottom: "15px"}}>
                <Link to={"/payment-items/create-payment-item"}>
                    <Button
                        htmlType='button'
                        type={"primary"}
                        icon={<PlusOutlined/>}
                    >
                        Ödeme Kalemi Oluştur
                    </Button>
                </Link>
            </Card>
            {data &&
                <BaseTable
                    columns={TABLE_COLUMNS}
                    dataSource={data.result}

                    search={false}
                />
            }
        </PageContainer>
    );
};

export default ListPaymentItem;
