const pagination = {
    defaultPageSize: 50,
    showSizeChanger: false,
    showLessItems: true,
    showQuickJumper: true,
};

const search = {
    labelWidth: "auto",
};

const options = {
    fullScreen: true,
};

const defaultColConfig = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 8,
};

export { pagination, search, options, defaultColConfig };