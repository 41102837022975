import { gql } from "@apollo/client";

const GET_SETTLEMENTS = gql`
  query($merchantId: String!, $page: Int, $pageSize: Int, $status: [String], $current: Int, $fromDate: String, $toDate: String) {
      getSettlements(merchantId: $merchantId, page: $page, pageSize: $pageSize, status: $status, current: $current, fromDate: $fromDate, toDate: $toDate) {
        current_page
        data {
          readyToPaymentDate
          settlementCurrency
          paidDate
          totals {
            chargeBack
            fee
            gross
            manualAdjustment
            merchantPayout
            payout
            payoutFee
            refund
            reserveHeld
            reserveReleased
          }
          net {
            TRY {
              count
              total
              type
            }
          }
        }
        first_page_url
        from
        last_page
        last_page_url
        next_page_url
        path
        per_page
        prev_page_url
        to
        total
      }
    }
`;

export default GET_SETTLEMENTS