export const INPUT_TYPES = {
  NUMBER_WITH_CURRENCY: "NUMBER_WITH_CURRENCY",
};

export const FILTER_FIELDS = {
  TRANSACTION_ID: "transactionId",
  ORIGINAL_AMOUNT: "originalAmount",
  CARD_PAN: "cardPan",
  CUSTOMER_EMAIL: "customerEmail",
  REFERENCE_NO: "referenceNo",
  PSP_REF_NO: "pspRefNo",
  TRANSACTION_TYPE: "transactionType",
  RRN: "rrn",
  TERMINAL_ID: "terminalId",
};

export function findFilterFieldInputType(filterField: any) {
  switch (filterField) {
    case FILTER_FIELDS.ORIGINAL_AMOUNT:
      return INPUT_TYPES.NUMBER_WITH_CURRENCY;
    default:
      return null;
  }
}
