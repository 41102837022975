const payment = {
  "payment.nameSurname": "Name Surname:",
  "payment.emailAddress": "Email Address:",
  "payment.phoneNumber": "Phone Number:",
  "payment.address": "Address:",
  "payment.address2": "Address 2:",
  "payment.country": "Country:",
  "payment.city": "City:",
  "payment.postCode": "Post Code:",
  "payment.price": "Price:",
  "payment.piece": "Piece:",
  "payment.pay": "Pay",

  "payment.status.success": "PAYMENT SUCCESS",
  "payment.status.error": "PAYMENT ERROR",
  "payment.status.waiting": "PAYMENT WAITING",
};

export default payment
