// Utils
import { Space } from "antd";
import CardUser, { User } from "../card-user";

// Components
import { PlusOutlined } from "@ant-design/icons";
import IconCard from "@/components/base/icon-card";

interface ListUserProps {
	users: User[];
	onAddClick: () => void;
	onEditClick: (user: User) => void;
	isEditable: boolean;
	isCreateVisible: boolean;
}

export default function ListUsers(props: ListUserProps): JSX.Element {
	const { users, onAddClick, onEditClick, isEditable, isCreateVisible } = props;

	const CardsUser = users.map((user: User) => (
		<CardUser
			user={user}
			isEditable={isEditable}
			onEditClick={(selectedUser) => onEditClick(selectedUser)}
			key={user.email}
		/>
	));

	const CardAdd = (
		<IconCard
			onClick={() => onAddClick()}
			icon={<PlusOutlined style={{ fontSize: 30 }} />}
			key={"CardAdd"}
		/>
	);

	return (
		<Space direction={"horizontal"} size={"large"} wrap>
			{CardsUser}
			{isCreateVisible && CardAdd}
		</Space>
	);
}
