// Libraries
import { Form, Input, Select } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

// Utils
import { ROLES } from "@/config/constants";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface EditUserFormProps {
  formRef: any;
  selectedUser: any;
}
const EditUserForm = ({ formRef, selectedUser }: EditUserFormProps) => {
  const { t } = useTranslation();
  return (
    <Form
      form={formRef}
      layout={"vertical"}
      initialValues={{
        role: selectedUser?.role,
        name: selectedUser?.name,
        email: selectedUser?.email,
      }}
    >
      <Form.Item
        name="name"
        label={t("form.name")}
        rules={[{ required: true, message: t("form.message.general") }]}
      >
        <Input type={"text"} disabled={true} />
      </Form.Item>
      <Form.Item
        name={"email"}
        label={t("form.email")}
        rules={[{ required: true, message: t("form.message.email") }]}
      >
        <Input type={"email"} disabled={true} />
      </Form.Item>
      <Form.Item
        name={"password"}
        label={t("form.password")}
        rules={[
          {
            required: true,
            message: t("form.message.general"),
          },
          {
            pattern: /^(?=.*[A-Z]{2})(?=.*[a-z]{3})(?=.*\d{2})(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*].{8,}$/,
            message: t("form.error.weakPassword"),
          },
        ]}
      >
        <Input.Password
          placeholder={t("form.message.password")}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item
        name={"role"}
        label={t("form.role")}
        //@ts-ignore
        placeholder={t("form.role")}
        rules={[{ required: true, message: t("form.message.general") }]}
      >
        <Select placeholder={t("form.role")}>
          <Option value={ROLES.admin}>{t("form.admin")}</Option>
          <Option value={ROLES.user}>{t("form.user")}</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default EditUserForm;
