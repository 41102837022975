import CreatePassword from "@/features/create-password/routes/create-password";


const createPasswordRoutes = [
    {
        path: "",
        element: <CreatePassword />
    },
];

export default createPasswordRoutes;
