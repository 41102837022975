import { gql } from "@apollo/client";

const EXPORT_PHYPOS_LIST = gql `
    mutation ExportPhysicalPosList($toDate: String, $merchantId: String, $fromDate: String!, $customerExportEmail: String!, $exportType: String!) {
        exportPhysicalPosList(toDate: $toDate, merchantId: $merchantId, fromDate: $fromDate, customerExportEmail: $customerExportEmail, exportType: $exportType) {
            response
            status
        }
    }
`

export default EXPORT_PHYPOS_LIST
