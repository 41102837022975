// Libraries
import _ from "lodash";

// Components
import { Avatar, Badge, Card } from "antd";
import { EditOutlined } from "@ant-design/icons";

// Utils
import { SYSTEM_COLORS } from "@/config/constants";
import useAuth from "@/hooks/use-auth";

const { Meta } = Card;

export interface User {
  email: string;
  name: string;
  role: string;
}
interface UserCardProps {
  user: User;
  onEditClick: (selectedUser: User) => void;
  isEditable: boolean;
}
const CardUser = ({ user, onEditClick, isEditable }: UserCardProps) => {
  const { email, name, role } = user;

  const colors = _.values(SYSTEM_COLORS);
  const backgroundColor = colors[Math.floor(Math.random() * colors.length)];
  const capitalLetters = name
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");

  const { auth } = useAuth();
  const actions = [
    isEditable && (
      <EditOutlined key="edit" onClick={() => onEditClick(user)} />
    ),
  ];

  const avatar = (
    <Avatar size={"large"} style={{ backgroundColor }}>
      {capitalLetters}
    </Avatar>
  );

  return (
    <Badge.Ribbon text={role}>
      <Card actions={actions} style={{ width: 300 }}>
        <Meta avatar={avatar} title={name} description={email} />
      </Card>
    </Badge.Ribbon>
  );
};

export default CardUser;
