import { gql } from "@apollo/client";

const GET_CHARGEBACKS = gql `
  query GetPhysicalPosTransactions($pageSize: Int!, $page: Int!, $merchantId: String!, $current: Int!) {
      getChargebacks(pageSize: $pageSize, page: $page, merchantId: $merchantId, current: $current) {
        data {
          merchantTransactionId
          chargeBacks {
            arn
            caseId
            created_at
            dueDate
            id
            kind
            merchantId
            merchantNo
            merchantReferenceNo
            merchantTransactionId
            processingDate
            reasonCode
            reasonMessage
            settledAmount
            settledCurrency
            status
            updated_at
          }
          parentTransactionId
        }
        current_page
        first_page_url
        from
        last_page
        last_page_url
        next_page_url
        path
        per_page
        prev_page_url
        to
        total
      }
  }
`

export default GET_CHARGEBACKS