import {useState} from "react";
import ProTable from "@ant-design/pro-table";
import { TableSkeleton } from "@ant-design/pro-skeleton";
import { pagination, search, options, defaultColConfig } from "@/config/table";

const BaseTable = ({ request,
                     scroll,
                     columnsState,
                     disableSkeleton,
                     dataSource,
                     ...props } : any) => {

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  if ((dataSource || []).length > 0 && isFirstLoad && !request) {
    setIsFirstLoad(false);
  }

  return (
      <ProTable
          dateFormatter={"string"}
          options={options}
          dataSource={dataSource}
          pagination={pagination}
          {...(!!request
              && {
                request: async (params = {}) => {
                  return request(params);
                },
              })}
          rowKey={transaction => transaction.id}
          tableRender={(proTableProps, dom) => (
              (!disableSkeleton && isFirstLoad) ? <TableSkeleton size={7} active={true} /> : dom
          )}
          onLoadingChange={(status) => {
            if (status === false && isFirstLoad === true) {
              setIsFirstLoad(false)
            }
          }}
          search={
            props.search === false
                ? false
                : {
                  ...search,
                  ...props.search,
                }
          }
          scroll={{
            x: "max-content",
            ...scroll,
          }}
          columnsState={{
            persistenceKey: `columnsState@${window.location.pathname}`,
            persistenceType: "localStorage",
            ...columnsState,
          }}
          form={{
            defaultCollapsed: false,
            span: defaultColConfig,
            syncToUrl: (values, type) => {
              if (type === "get") {
                if (values.filterField) {
                  let filterField = {};
                  try {
                    filterField = JSON.parse(values.filterField);
                  } catch (e) {
                    // continue regardless of error
                  }
                  return {
                    ...values,
                    filterField,
                  };
                }
              }
              return values;
            },
            syncToInitialValues: false,
          }}
          {...props}
      />
  )
}

export default BaseTable