import { useEffect, useState } from "react";

// Libraries
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

// Components
import EditUserForm from "@/features/settings/components/form/edit";
import BaseModal from "../base";
import { Form } from "antd";

// Queries
import UPDATE_MERCHANT_USER from "@/features/settings/api/update-merchant-user";
import UPDATE_MERCHANT_USER_PASSWORD from "@/features/settings/api/update-merchant-user-password";
import { RESPONSE_STATUS } from "@/config/constants";
import BaseResult from "@/components/base-result";

interface ModalUserEditProps {
  visible: boolean;
  onProcessEnd: () => void;
  user: any;
  external: any;
}

const ModalUserEdit = ({
  visible,
  onProcessEnd,
  user,
  external,
}: ModalUserEditProps) => {
  const [formRef] = Form.useForm();
  const [updateMerchantUser] = useMutation(UPDATE_MERCHANT_USER);
  const [updateMerchantUserPassword] = useMutation(
    UPDATE_MERCHANT_USER_PASSWORD
  );
  const [result, setResult] = useState<string | undefined>();
  const { t } = useTranslation();

  const { activeMerchantId } = external;

  useEffect(() => {
    formRef.resetFields();
    setResult(undefined);
  }, [formRef, visible]);

  const onOk = async () => {
    const formValues = await formRef.validateFields().catch(() => false);
    if (!formValues) return;

    const updateMerchantUserResponse = await updateMerchantUser({
      variables: {
        ...formValues,
        merchantId: String(activeMerchantId),
        id: user.id,
      },
    });

    const updateMerchantUserPasswordResponse = await updateMerchantUserPassword(
      {
        variables: {
          merchantId: String(activeMerchantId),
          id: user.id,
          password: formValues.password,
        },
      }
    );

    const isActionsSuccessful =
      updateMerchantUserResponse?.data?.updateMerchantUser?.status ===
        RESPONSE_STATUS.APPROVED &&
      updateMerchantUserPasswordResponse?.data?.updateMerchantUserPassword
        ?.status === RESPONSE_STATUS.APPROVED;

    setResult(
      isActionsSuccessful ? RESPONSE_STATUS.APPROVED : RESPONSE_STATUS.DECLINED
    );
  };

  const renderModalContent = () => {
    switch (result) {
      case RESPONSE_STATUS.DECLINED:
        return {
          showModalFooter: false,
          component: (
            <BaseResult
              status={"error"}
              title={t("results.error.title")}
              subTitle={t("results.error.subtitle")}
              buttonText={t("component.button.tryAgain")}
              buttonOnClick={() => setResult(undefined)}
            />
          ),
        };
      case RESPONSE_STATUS.APPROVED:
        return {
          showModalFooter: false,
          component: (
            <BaseResult
              status={"success"}
              title={t("results.success.title")}
              subTitle={t("results.success.subtitle")}
              buttonText={t("component.button.close")}
              buttonOnClick={onProcessEnd}
            />
          ),
        };
      default:
        return {
          component: <EditUserForm selectedUser={user} formRef={formRef} />,
        };
    }
  };

  const modalContent = renderModalContent();

  return (
    <BaseModal
      visible={visible}
      title={t("modals.user.edit.title")}
      onCancel={onProcessEnd}
      onOk={() => onOk()}
      footer={modalContent.showModalFooter}
    >
      {modalContent.component}
    </BaseModal>
  );
};

export default ModalUserEdit;
