import { gql } from "@apollo/client";

const GET_MID_RATES = gql`
    query ($merchantId: String!, $midId: String!) {
      getMidRates(merchantId: $merchantId, midId: $midId) {
        message
        result
        status
      }
    }
`;

export default GET_MID_RATES