const getCompleted = async (
  params: any,
  getSettlements: any,
  initialVariables: any
) => {
  console.log(params);
  let parameters = {
    ...initialVariables,
    current: params.current,
    pageSize: params.pageSize,
    page: params.current,
  };

  if(params.readyToPaymentDate) {
    parameters = {
      ...parameters,
      fromDate: params.readyToPaymentDate[0],
      toDate: params.readyToPaymentDate[1]
    }
  }

  const data = await getSettlements({
    variables: parameters,
  });

  return {
    data: data.data.getSettlements.data,
    total: data.data.getSettlements.total,
    success: true,
  };
};

export { getCompleted };
