const transactions = {
  "transaction.address": "Adres",
  "transaction.amount": "İşlem Tutarı",
  "transaction.billingAddress": "Fatura Adresi",
  "transaction.cardDetails": "Kart Detayları",
  "transaction.cardNumber": "Kart Numarası",
  "transaction.cardType": "Kart Tipi",
  "transaction.chargebacks.amount": "İtiraz Tutarı",
  "transaction.chargebacks.chargeback": "İtiraz Durumu",
  "transaction.chargebacks.date": "İtiraz Tarihi",
  "transaction.chargebacks.dueDate": "Son Yanıt Tarihi",
  "transaction.chargebacks.referenceNo": "Referans Numarası",
  "transaction.chargebacks.transactionId": "Ödeme Numarası",
  "transaction.chargebacks.updatedDate": "Güncelleme Tarihi",
  "transaction.city": "Şehir",
  "transaction.country": "Ülke",
  "transaction.currency": "Para Birimi",
  "transaction.customerDetails": "Müşteri Detayları",
  "transaction.date": "İşlem Tarihi",
  "transaction.email": "E-Posta",
  "transaction.export": "Dışa Aktar",
  "transaction.export.approved":
    "Raporunuz hazırlanıp size mail olarak gönderilecektir.",
  "transaction.export.error":
    "Rapor oluşturma sırasında bir hata oluştu.Daha sonra tekrar deneyiniz.",
  "transaction.export.dateError": "Lütfen tarih aralığı seçiniz.",
  "transaction.export.okText": "Anladım",
  "transaction.firstName": "Adı",
  "transaction.fullName": "Adı Soyadı",
  "transaction.installment": "Taksit Sayısı",
  "transaction.ip": "IP Adresi",
  "transaction.lastName": "Soyadı",
  "transaction.merchantName": "Üye İşyeri",
  "transaction.message": "İşlem Mesajı",
  "transaction.operation.DIRECT": "Satış",
  "transaction.operation.3D": "Satış",
  "transaction.operation.3DAUTH": "Satış",
  "transaction.operation.NON3D": "Satış",
  "transaction.operation.REFUND": "İade",
  "transaction.operation.VOID": "İptal",
  "transaction.operation.CANCEL": "Otorizasyon İptal",
  "transaction.operation.CAPTURE": "Otorizasyon Kapama",
  "transaction.paymentDetails": "Ödeme Detayları",
  "transaction.paymentId": "Ödeme ID",
  "transaction.paymentType": "Ödeme Tipi",
  "transaction.paymentSource": "Ödeme Kanalı",
  "transaction.paymentSource.api": "API",
  "transaction.paymentSource.paymentwithlink": "Link ile Ödeme",
  "transaction.paymentSource.checkout": "Checkout",
  "transaction.performer": "İşlemi Gerçekleştiren",
  "transaction.phone": "Cep Telefonu Numarası",
  "transaction.productServiceInfo": "Fatura Adresi",
  "transaction.pspRefNo": "Ozan İşlem No",
  "transaction.referenceNo": "Referans Numarası",
  "transaction.refund": "İade Et",
  "transaction.refundableAmount": "Kalan İade Edilebilir Tutar",
  "transaction.refund.selectbox": "İade",
  "transaction.refund.warning":
    " tutarındaki işlem iade edilecektir. İadeyi onaylıyor musunuz?",
  "transaction.refundAmount": "İade Tutarı",
  "transaction.refundSuccess": "İşlem başarıyla gerçekleştirildi.",
  "transaction.refundError": "İşlem sırasında bir hata meydana geldi.",
  "transaction.responseDescription": "Yanıt Kodu Açıklaması",
  "transaction.rrn": "RRN",
  "transaction.status": "İşlem Durumu",
  "transaction.status.waiting": "YÖNLENDİRİLDİ",
  "transaction.search": "Arama",
  "transaction.shippingAddress": "Kargo Adresi",
  "transaction.success": "Başarılı İşlem",
  "transaction.transactionId": "Ozan İşlem No",
  "transaction.terminalId": "Terminal ID",
  "transaction.type": "İşlem Tipi",
  "transaction.unknownError": "Bir Hata Meydana Geldi.",
  "transaction.errorMessage": "Hata Mesajı",
  "transaction.status.approved": "BAŞARILI",
  "transaction.status.declined": "BAŞARISIZ",
  "transaction.status.error": "HATA",
  "transaction.name": "Üye İş Yeri",
  "transaction.billingCountry": "Fatura Ülke",
  "transaction.billingCity": "Fatura Şehir",
  "transaction.billingPhone": "Fatura Telefon",
  "transaction.billingPostcode": "Faatura Posta Kodu",
  "transaction.shippingCountry": "Teslimat Ülkesi",
  "transaction.shippingCity": "Teslimat Şehri",
  "transaction.shippingPhone": "Teslimat Telefon",
  "transaction.shippingPostcode": "Teslimat Posta Kodu",
  "transaction.provisionNo": "Provizyon Numarası",
  "transaction.actions": "İşlemler",
  "transaction.3d": "3D/NON3D",
  "transaction.commission": "Ozan Komisyon Tutarı",
  "transaction.allRefund": "Toplu İade",
  "transaction.partialRefund": "Parçalı İade",
  "transaction.sell": "Satış",
  "transaction.void": "İptal",
  "transaction.cancel": "Otorizasyon İptal",
  "transaction.capture": "Otorizasyon Kapama",
  "transaction.transactionType": "İşlem Tipi",
  'transaction.preAuth.close': 'Otorizasyon Kapama',
  'transaction.preAuth.cancel': 'Otorizasyon İptal',
  'transaction.preAuth.CANCEL': 'Otorizasyon İptal',
  'transaction.preAuth.caution': ' <strong class="ff" >{{amount}}</strong>  tutarındaki işlem kapatılacaktır. Onaylıyor musunuz?',
  'transaction.preAuth.total': 'Toplu Kapama',
  'transaction.preAuth.partial': 'Parçalı Kapama',
  'transaction.preAuthType.total': 'Ön Otorizasyon Kapama',
  'transaction.preAuthType.cancel': 'Ön Otorizasyon İptal',
  'transaction.preAuth': 'Ön Otorizasyon',
  'transaction.3dSecure': '3D Secure',
  "transaction.valorDate": "Hesaba Geçiş Tarihi",
  "transaction.viewMore": " Devamını Görüntüle",
  "transaction.mdStatus": "3D Statüsü",
  "transaction.bankName": "Kart Bankası",
  "transaction.brand": "Kart Şeması",
  "transaction.domesticIntl": "Yurtiçi/Yurtdışı",
  "transaction.domesticIntl.international": "Yurtdışı",
  "transaction.domesticIntl.domestic": "Yurtiçi",
  "transaction.countryCode": "Kart Ülkesi",
  "transaction.currencyCode": "Para Birimi",
  "transaction.cardCategory": "Kart Kategorisi",
  "transaction.cardLoyalty": "Kart Markası",
};
export default transactions
