import {Row, Col, Button, message} from "antd";
import {useMutation} from "@apollo/client";
import DELETE_PAY_TO_LINK from "@/features/pay-with-link/api/mutations/deletePayToLink";
import "../../index.less"
import {RESPONSE_STATUS} from "@/config/constants";
import {useNavigate} from "react-router-dom";

const ProductInfo = ({selectedLink}: any) => {
    const navigate = useNavigate();
    const [deletePayToLink] = useMutation(DELETE_PAY_TO_LINK);
    const deleteLink = (id: string) => {
        deletePayToLink({
            variables: {
                deletePayToLinkId: id,
            },
        }).then((response) => {
            const { status, message: responseMessage } = response.data.deletePayToLink;
            if (status !== RESPONSE_STATUS.APPROVED) {
                throw new Error(responseMessage);
            }
            message.success(responseMessage);
            window.location.reload();
        }).catch((error) => {
            message.error(error.message);
        });
    }

    return (
        <div className="product-info">
            <Row>
                <Col span={12} style={{padding: 25}}>
                    <div className="product-name">{selectedLink?.name}</div>
                    <div className="product-desc">{selectedLink?.desc}</div>
                    <ul className="product-detail-list">
                        <li>
                            <span>Fiyat:</span>
                            <span>{selectedLink?.amount} <strong style={{color: "#00bda1"}}>{selectedLink?.currency}</strong></span>
                        </li>
                        <li>
                            <span>Stok:</span>
                            <span>{selectedLink?.stock}</span>
                        </li>
                        <li>
                            <span>Durum:</span>
                            <span className={`status ${selectedLink?.stock > selectedLink?.limit && "active"}`}>
                                {selectedLink?.stock > selectedLink?.limit ? "Aktif" : "İnaktif"}
                            </span>
                        </li>
                    </ul>
                </Col>
                <Col span={12} className="product-image-wrapper">
                    <img alt={selectedLink?.name} src={selectedLink?.imageUrlLarge}/>
                </Col>
            </Row>
            <Row className="buttons-wrapper">
                <Button type={"primary"} onClick={() => {navigate('/pay-with-link/edit/' + selectedLink?.id)}}> Düzenle </Button>
                <Button type={"default"} danger onClick={() => deleteLink(selectedLink?.id)}> Sil </Button>
            </Row>
        </div>
    )
}
export default ProductInfo;