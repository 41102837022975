const getPhysicalPosTransactions = async (
    params: any,
    getPhysicalPosTransactionsGQL: any,
    initialVariables: any
) => {
    let parameters = {
        ...initialVariables,
        current: params.current,
        pageSize: params.pageSize,
        page: params.current,
    };
    console.log(params);
    if (params.operation) {
        let operation: any[] = [];
        if (typeof params.operation === "string") {
            operation = params.operation.split(",");
        } else {
            params.operation.forEach((param: any) => {
                const subParams = param.split(",");
                operation = [...operation, ...subParams];
            });
        }
        parameters = {
            ...parameters,
            operation,
        };
    }
    if (params.status) {
        parameters = {
            ...parameters,
            status:
                typeof params.status === "string" ? [params.status] : params.status,
        };
    }
    if (params.dateRange) {
        parameters = {
            ...parameters,
            fromDate: params.dateRange[0],
            toDate: params.dateRange[1],
        };
    }
    if (params.filterField) {
        parameters = {
            ...parameters,
            filterField: params.filterField.filterField,
            filterValue: params.filterField.filterValue,
        };
    }
    const data = await getPhysicalPosTransactionsGQL({
        variables: parameters,
    });

    return {
        data: data.data.getPhysicalPosTransactions.data,
        success: true,
        total: data.data.getPhysicalPosTransactions.total,
    };
};

export { getPhysicalPosTransactions };
