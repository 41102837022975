import BlankLayout from "@/layouts/blank";
import authRoutes from "@/features/auth/routes";

const publicRoutes = {
  path: "/user",
  element: <BlankLayout />,
  children: authRoutes,
};

export default publicRoutes;
