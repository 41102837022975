import React, { useState } from 'react';

import { findFilterFieldInputType, INPUT_TYPES } from '@/utils/input';
import { currencyFormatter, currencyParser } from '@/utils/finance';
import { Input, InputNumber, Select } from 'antd';


const CustomSelect = ({ value = {}, onChange, options }: any) => {
    const [filterValue, setFilterValue] = useState(undefined);
    const [filterField, setFilterField] = useState(undefined);

    const triggerChange = (changedValue:any) => {
        onChange?.({
            filterValue,
            filterField,
            ...value,
            ...changedValue,
        });
    };

    const onFilterValueChange = (e: any) => {
        const newFilterValue = typeof e === 'object' ? e.target.value : e;
        if (!('filterValue' in value)) {
            setFilterValue(newFilterValue);
        }
        triggerChange({
            filterValue: newFilterValue,
        });
    };

    const onFilterFieldChange = (newFilterField: any) => {
        if (!('filterField' in value)) {
            setFilterField(newFilterField);
        }

        triggerChange({
            filterField: newFilterField,
        });
    };

    const renderInput = () => {
        const sharedStyle = {
            width: 'calc(60% - 2px)',
            marginLeft: '2px',
        };

        switch (findFilterFieldInputType(value.filterField)) {
            case INPUT_TYPES.NUMBER_WITH_CURRENCY:
                return (
                    <InputNumber
                        style={sharedStyle}
                        defaultValue={value.filterValue || filterValue}
                        formatter={(value) => currencyFormatter(value)}
                        parser={(value) => currencyParser(value)}
                        onChange={(value) => onFilterValueChange(value)}
                    />
                );
            default:
                return (
                    <Input
                        type={'text'}
                        value={value.filterValue || filterValue}
                        onChange={onFilterValueChange}
                        allowClear
                        style={sharedStyle}
                    />
                );
        }
    };

    return (
        <span>
      <Input.Group compact>
        <Select
            value={value.filterField || filterField}
            style={{
                width: '40%',
            }}
            onChange={onFilterFieldChange}
            options={options}
        />
          {renderInput()}
      </Input.Group>
    </span>
    );
};

export default CustomSelect;
