import { useLocation, useParams } from "react-router-dom";
import { useSelector, } from "react-redux";
import { RootState } from "@/store";
import { useQuery } from "@apollo/client";
import GET_TRANSACTION_DETAIL from "@/features/transactions/api/queries/get-transaction-detail";
import BasketItems from "@/features/transactions/components/basket-items";
import PaymentDetails from "@/features/transactions/components/payment-details";
import CardDetails from "@/features/transactions/components/card-details";
import AddressDetails from "@/features/transactions/components/address-details";
import CustomerDetails from "@/features/transactions/components/customer-details";

const TransactionDetail = () => {
  let { state } = useLocation();
  const {selectedTransactionValorDate,selectedCustomerInfo}  = state || {}
  const { id } = useParams();
  const {activeMerchantId, } = useSelector(
      (state: RootState) => state.auth.params
  );
  const { data, error, loading } = useQuery(GET_TRANSACTION_DETAIL, {
    variables: {
      merchantId: String(activeMerchantId),
      transactionId: id,
    },
  });

 

  if (error) return <p>Error</p>;

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      <BasketItems data={data.getTransactionDetail.transaction.merchant} />
      <PaymentDetails data={data.getTransactionDetail} valorDateData={selectedTransactionValorDate}/>
      <CardDetails data={data.getTransactionDetail} customerInfoData = {selectedCustomerInfo}/>
      <AddressDetails data={data.getTransactionDetail} />
      <CustomerDetails data={data.getTransactionDetail} />
    </div>
  );
};

export default TransactionDetail;
