import { Outlet } from "react-router-dom";
import logo from "@/assets/logo.png";
import "./index.less";

const CheckoutLayout = () => {
  return (
    <div className="checkout-layout">
        <header className="header-wrapper">
            <div className="checkout-container">
                <img src={logo}/>
            </div>
        </header>
        <Outlet />
    </div>
  );
};

export default CheckoutLayout;
