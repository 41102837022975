const PERMISSIONS = {
    dashboard: {
        weekly_report: {
            show: "MAIN_CAN_SEE_7DAY_REPORT"
        },
        vpos_report: {
            show: "MAIN_CAN_SEE_VPOS_REPORT",
            filter: "MAIN_CAN_FILTER_VPOS_REPORT"
        },
        phypos_report: {
            show: "MAIN_CAN_SEE_PHYPOS_REPORT",
            filter: "MAIN_CAN_FILTER_PHYPOS_REPORT"
        }
    },
    transactions: {
        vpos_trx: {
            show: "TRX_CAN_SEE_VPOS_TRX",
            filter: "TRX_CAN_FILTER_VPOS_TRX",
            export: "TRX_CAN_EXPORT_VPOS_TRX",
            detail: {
                show: "TRX_CAN_SEE_TRX_DETAILS"
            },
            refund: {
                show: "TRX_CAN_REFUND"
            }
        },
        phypos_trx: {
            show: "TRX_CAN_SEE_PHYPOS_TRX",
            filter: "TRX_CAN_FILTER_PHYPOS_TRX",
            export: "TRX_CAN_EXPORT_PHYPOS_TRX"
        },
        refund: {
            show: "TRX_CAN_SEE_REFUND_TRX",
            filter: "TRX_CAN_FILTER_PHYPOS_TRX",
            export: "TRX_CAN_EXPORT_PHYPOS_TRX",
            detail: {
                show: "TRX_CAN_SEE_REFUND_DETAILS"
            }
        },
        chargebacks: {
            show: "TRX_CAN_SEE_CHARGEBACK",
            detail: {
                show: "TRX_CAN_SEE_CHARGEBACK_DETAILS"
            }
        }
    },
    financialManagement: {
        payments: {
            show: "FINANCE_CAN_SEE_PAYMENTS",
            filter: "FINANCE_CAN_FILTER_PAYMENTS",
            export: "FINANCE_CAN_EXPORT_PAYMENTS"
        },
        pending: {
            show: "FINANCE_CAN_SEE_PENDING_PAYMENTS",
            filter: "FINANCE_CAN_FILTER_PENDING_PAYMENTS",
            detail: {
                show: "FINANCE_CAN_SEE_PENDING_PAYMENTS_DETAILS"
            }
        },
        bankStatement: {
            show: "FINANCE_CAN_EXPORT_BANK_STATEMENT"
        },
        commissions: {
            show: "FINANCE_CAN_SEE_COMMISSIONS",
            edit: "FINANCE_CAN_EDIT_COMMISSIONS"
        }
    },
    settings: {
        merchant: {
            show: "SETTINGS_CAN_SEE_MERCHANT_SETTINGS",
            apiKey: {
                show: "SETTINGS_CAN_SEE_APIKEY"
            },
            merchantInfo: {
                show: "SETTINGS_CAN_SEE_MERCHANT_INFO"
            }
        },
        user: {
            show: "SETTINGS_CAN_SEE_USERS",
            create: "SETTINGS_CAN_CREATE_USERS",
            edit: "SETTINGS_CAN_EDIT_USERS"
        }
    },
    plugins: {
        show: "CAN_SEE_PLUGINS"
    }
}

export default PERMISSIONS