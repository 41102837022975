const transactions = {
  "transaction.address": "Address",
  "transaction.amount": "Amount",
  "transaction.billingAddress": "Billing Address",
  "transaction.cardDetails": "Card Details",
  "transaction.cardNumber": "Card No",
  "transaction.cardType": "Card Type",
  "transaction.chargebacks.amount": "Amount",
  "transaction.chargebacks.chargeback": "Status",
  "transaction.chargebacks.date": "Date",
  "transaction.chargebacks.dueDate": "Last Reply Date",
  "transaction.chargebacks.referenceNo": "Reference No",
  "transaction.chargebacks.transactionId": "Payment No",
  "transaction.chargebacks.updatedDate": "Date of Update",
  "transaction.city": "City",
  "transaction.country": "Country",
  "transaction.currency": "Currency",
  "transaction.customerDetails": "Customer Details",
  "transaction.date": "Date",
  "transaction.email": "E-Mail",
  "transaction.export": "Export",
  "transaction.export.approved":
    "Your report will be prepared and sent to you by e-mail.",
  "transaction.export.error":
    "An error occurred while generating the report. Please try again later.",
  "transaction.export.dateError": "Please select a date range.",
  "transaction.export.okText": "Okay",
  "transaction.firstName": "Name",
  "transaction.fullName": "Name Surname",
  "transaction.installment": "Installment",
  "transaction.ip": "IP Address",
  "transaction.lastName": "Surname",
  "transaction.merchantName": "Merchant Name",
  "transaction.message": "Message",
  "transaction.operation.DIRECT": "Sale",
  "transaction.operation.3D": "Sale",
  "transaction.operation.3DAUTH": "Sale",
  "transaction.operation.NON3D": "Sale",
  "transaction.operation.REFUND": "Refund",
  "transaction.operation.VOID": "Cancel",
  "transaction.operation.CANCEL": "Authorization Cancel",
  "transaction.operation.CAPTURE": "Authorization Close",
  "transaction.paymentDetails": "Payment Channel",
  "transaction.paymentId": "Payment ID",
  "transaction.paymentType": "Payment Type",
  "transaction.paymentSource": "Payment Source",
  "transaction.paymentSource.api": "API",
  "transaction.paymentSource.paymentwithlink": "Pay by Link",
  "transaction.paymentSource.checkout": "Checkout",
  "transaction.performer": "Performer of the transaction",
  "transaction.phone": "Phone Number",
  "transaction.productServiceInfo": "Product Service Info",
  "transaction.pspRefNo": "Transaction ID",
  "transaction.referenceNo": "Reference No",
  "transaction.refund": "Refund",
  "transaction.refundableAmount": "Refundable Amount",
  "transaction.refund.warning":
    " amount will be refunded. Do you approve the refund?",
  "transaction.refundAmount": "Refund Amount",
  "transaction.refundSuccess": "The refund has been done successfully.",
  "transaction.refundError": "There was an error during refund.",
  "transaction.responseDescription": "Response Description",
  "transaction.rrn": "RRN",
  "transaction.status": "Status",
  "transaction.status.waiting": "Waiting",
  "transaction.search": "Search",
  "transaction.success": "Successful",
  "transaction.shippingAddress": "Shipping Address",
  "transaction.transactionId": "Transaction ID",
  "transaction.terminalId": "Terminal ID",
  "transaction.type": "Transaction Type",
  "transaction.unknownError": "An error occured.",
  "transaction.errorMessage": "Error Message",
  "transaction.status.approved": "APPROVED",
  "transaction.status.declined": "DECLINED",
  "transaction.status.error": "ERROR",
  "transaction.name": "Member Company",
  "transaction.billingCountry": "Billing Country",
  "transaction.billingCity": "Billing City",
  "transaction.billingPhone": "Billing Phone",
  "transaction.billingPostcode": "Billing Postcode",
  "transaction.shippingCountry": "Shipping Country",
  "transaction.shippingCity": "Shipping City",
  "transaction.shippingPhone": "Shipping Phone",
  "transaction.shippingPostcode": "Shipping Postcode",
  "transaction.provisionNo": "Provision No",
  "transaction.actions": "Actions",
  "transaction.3d": "3D/NON3D",
  "transaction.comission": "Ozan Commission Amount",
  'transaction.preAuth': 'Pre-Auth',
  'transaction.preAuth.close': 'Close pre-auth',
  'transaction.preAuth.cancel': 'Cancel pre-auth',
  'transaction.preAuth.CANCEL': 'Cancel pre-auth',
  'transaction.preAuth.caution': ' {{amount}}  will be closed. Do you agree?',
  'transaction.preAuth.total': 'Total',
  'transaction.preAuth.partial': 'Partial',
  'transaction.preAuthType.total': 'Pre-auth Close',
  'transaction.preAuthType.cancel': 'Pre-auth Cancel',
  'transaction.3dSecure': '3D Secure',
  "transaction.valorDate": "Account Transfer Date",
  "transaction.viewMore": " View More",
  "transaction.mdStatus": " 3D Status",
  "transaction.bankName": "Card Bank",
  "transaction.brand": "Card Brand",
  "transaction.domesticIntl": "International/Domestic",
  "transaction.domesticIntl.international": "International",
  "transaction.domesticIntl.domestic": "Domestic",
  "transaction.countryCode": "Card Country",
  "transaction.currencyCode": "Currency",
  "transaction.cardCategory": "Card Category",
  "transaction.cardLoyalty": "Card Loyalty",
};

export default transactions
