import SubMerchantDropdown from "@/components/header/right/submerchant-dropdown";
import HeaderAvatar from "@/components/header/right/avatar";
import "@/components/header/index.less";

interface HeaderRightProps {
    theme?: string
    layout?: string
}

const HeaderRight = ({ theme, layout }: HeaderRightProps) => {
  let className = "right";
  if (theme === "dark" && layout === "top") {
    className = `right dark`;
  }
  return (
    <div className={className}>
      <SubMerchantDropdown />
      <HeaderAvatar menu />
    </div>
  );
};

export default HeaderRight;
