const component = {
  "component.tagSelect.expand": "Expand",
  "component.tagSelect.collapse": "Collapse",
  "component.tagSelect.all": "All",

  "component.button.close": "Kapat",
  "component.button.tryAgain": "Tekrar Dene",
};

export default component
