import {gql} from "@apollo/client";

const GET_MERCHANT_3D_STATUS = gql`
    query GetMerchant3dStatus($merchantId: String!) {
      getMerchant3dStatus(merchantId: $merchantId) {
        status
        message
        result {
          valid
          checkbox {
            disabled
            value
          }
        }
      }
    }
`;
export default GET_MERCHANT_3D_STATUS;