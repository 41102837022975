import Profile from "@/features/user/routes/profile";
const userRoutes = {
  path: "user",
  children: [
    {
      path: "profile",
      element: <Profile />,
    },
  ],
};

export default userRoutes;
