import {
  AxessLogo,
  BonusLogo,
  WorldLogo,
  ParafLogo,
  MaximumLogo,
  CardFinansLogo,
  SaglamKartLogo,
  BankkartComboLogo,
} from '@/assets/icons/bank-logos';

const cardLogos: {[index: string] : any} = {
  '002': AxessLogo,
  '004': BonusLogo,
  '010': WorldLogo,
  '008': ParafLogo,
  '006': MaximumLogo,
  '005': CardFinansLogo,
  '009': SaglamKartLogo,
  '003': BankkartComboLogo,
};
export const bankLogos = (id: string) => {
  return <img alt={id} width={100} src={cardLogos[id]} />;
};
