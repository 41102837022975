import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { formatAmount } from '@/utils/finance';

import { Statistic, Typography } from 'antd';
import { StatisticCard } from '@ant-design/pro-card';
import GET_PHYSICAL_POS_TRANSACTIONS from "@/features/transactions/api/queries/get-physical-pos-transactions";
import {useQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import DynamicFilter from "@/components/base/filters";
import useAuthorization from "@/hooks/use-authorization";
import PERMISSIONS from "@/config/permissions";

const { Title } = Typography;
const currencies = {
  TRY: 'TRY',
  USD: 'USD',
  EUR: 'EUR',
};

interface PhysicalPosStatsInterface {
  isResponsive: boolean
}

const PhysicalPosStats = ({ isResponsive }: PhysicalPosStatsInterface) => {
  const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
  const { data, error, loading, refetch } = useQuery(GET_PHYSICAL_POS_TRANSACTIONS, {
    variables: {
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      merchantId: String(activeMerchantId)
    }
  });
  const { checkPermission } = useAuthorization();
  const [isVisiblePhysicalPosStatsFilter,setIsVisiblePhysicalPosStatsFilter] = useState(false);
  const [totalVal, setTotalVal] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies.TRY);
  const [dateRange, setDateRange] = useState({
    fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    if (data) {
      setPhysicalData();
      checkPermissions();
    }

  }, [data]);

  const checkPermissions = async () => {
    //const controlFilters = await checkPermission(PERMISSIONS.dashboard.phypos_report.filter);
    setIsVisiblePhysicalPosStatsFilter(true);
  }

  const setPhysicalData = async () => {
    setTotalVal(data.totalVal);
    setTransactionCount(data.transactionCount);
  };

  const formatData = () => {
    return [
      {
        title: 'Toplam İşlem',
        value: transactionCount,
      },
      {
        title: 'Toplam Brüt Tutar',
        value: formatAmount(totalVal, selectedCurrency),
      },
      {
        title: 'Net Tutar',
        value: formatAmount(totalVal, selectedCurrency),
      },
    ];
  };


  const renderCells = () => {
    const statistics = formatData();
    return statistics.map((statistic) => {
      const { title, value } = statistic;
      return (<StatisticCard key={title}><Statistic title={title} value={value}/></StatisticCard>);
    });
  };

  const renderFilters = () => {
    if (!isVisiblePhysicalPosStatsFilter) {
      return null
    }
    const rangePickerProps = {
      ranges: {
        'Son 7 Gün': [moment().subtract('1', 'weeks'), moment()],
        'Son 14 Gün': [moment().subtract('2', 'weeks'), moment()],
        'Son 30 Gün': [moment().subtract('30', 'days'), moment()],
      },
      onChange: (dates: any) => {
        if (dates) {
          refetch({
            fromDate: moment(dates[0]).format('YYYY-MM-DD'),
            toDate: moment(dates[1]).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD'),
            merchantId: String(activeMerchantId)
          })
        }
      },
    };

    return <DynamicFilter rangePickerProps={rangePickerProps} />;
  };


  if (error) return <p>Error</p>
  if (loading) return <p>loading</p>
  return (
      <StatisticCard title={<Title level={3}>Fiziksel POS</Title>} extra={renderFilters()}>
        <StatisticCard.Group split={isResponsive ? 'horizontal' : 'vertical'}>
          {renderCells()}
        </StatisticCard.Group>
      </StatisticCard>
  );
}

export default PhysicalPosStats
