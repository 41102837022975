import React from "react";
import { Card, Space } from "antd";

interface IconCardProps {
  icon: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const IconCard = ({ icon, onClick }: IconCardProps) => {
  const cardStyles = {
    height: "100%",
    width: 300,
    color: "blue",
    border: "1px dashed blue",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <Card onClick={onClick} style={cardStyles} hoverable>
      <Space align="center">{icon}</Space>
    </Card>
  );
};

export default IconCard;
