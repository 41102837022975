import { Outlet } from "react-router-dom";
import "./index.less";

const BlankLayout = () => {
  return (
    <div className="blank-layout">
      <Outlet />
    </div>
  );
};

export default BlankLayout;
