import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query (
    $pageSize: Int!
    $page: Int!
    $merchantId: String!
    $current: Int!
    $filterField: String
    $filterValue: String
    $fromDate: String
    $toDate: String
    $status: [String]
    $operation: [String]
    $mdStatus: [String]
    $trxListType: String
    $valorFromDate: String
    $valorToDate: String
    $threeDSecureStatus: [String]
  ) {
    getTransactions(
      pageSize: $pageSize
      page: $page
      merchantId: $merchantId
      current: $current
      filterField: $filterField
      filterValue: $filterValue
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      operation: $operation
      mdStatus: $mdStatus
      trxListType: $trxListType
      valorToDate: $valorToDate
      valorFromDate: $valorFromDate
      threeDSecureStatus: $threeDSecureStatus
    ) {
      current_page
      first_page_url
      from
      last_page
      last_page_url
      next_page_url
      path
      per_page
      prev_page_url
      to
      total
      data {
        valorDate
        created_at
        refundable
        captureAble
        customerInfo {
          billingFirstName
          billingLastName
          number
          bin {
            bankName
            binNumber
            brand
            cardCategory
            cardType
            countryCode
            currencyCode
            domesticIntl
            loyalty
          }
        }
        merchant {
          name
          allowPartialCapture
        }
        transaction {
          merchant {
            mainRefNo
            referenceNo
            operation
            message
            installment
            status
            transactionId
            created_at
            type
          }
        }
        fx {
          merchant {
            originalAmount
            originalCurrency
          }
        }
      }
    }
  }
`;

export default GET_TRANSACTIONS;
