import CreateLink from "@/features/pay-with-link/components/pay-with-link/create-link";
import CurrentLinks from "@/features/pay-with-link/components/pay-with-link/current-links";
import PastLinks from "@/features/pay-with-link/components/pay-with-link/past-links";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import CREATE_KEY from "@/features/settings/api/create-key";
import {useEffect, useState} from "react";
import FIND_PAY_TO_LINK from "@/features/pay-with-link/api/queries/findPayToLink";
import GET_MERCHANT_3D_STATUS from "@/features/pay-with-link/api/queries/getMerchant3dStatus";
import {Alert, Form} from "antd";

const PayWithLink = () => {
    const {activeMerchantId} = useSelector((state: RootState) => state.auth.params);
    const [getKeyInfo] = useMutation(CREATE_KEY);
    const [getPastLinks, { refetch }] = useLazyQuery(FIND_PAY_TO_LINK);
    const [createForm] = Form.useForm();
    useQuery(GET_MERCHANT_3D_STATUS, {
        variables: {
            merchantId: String(activeMerchantId)
        },
        onCompleted(data) {
            console.log(data);
            setPaymentStatus(data?.getMerchant3dStatus);
            createForm.setFieldValue("check3D", data?.getMerchant3dStatus?.result.checkbox.value);
        }
    });
    const [paymentStatus, setPaymentStatus] = useState<any>();
    const [apiKey, setApiKey] = useState("");
    const [pastLinks, setPastLinks] = useState<any>(null);
    const [createdSelectedLink, setCreatedSelectedLink] = useState(null);


    const openPopup = (data: any) => {
        setCreatedSelectedLink(data);
    }
    const getPastLinksData = async (key: any) => {
        getPastLinks({
            variables: {
                merchantId: String(activeMerchantId),
                apiKey: String(key || apiKey)
            },
            errorPolicy: 'all'
        }).then((response) => {
            const validData = response.data.findPayToLink.result.filter((item: any) => !!item);
            const findPayToLinkData = {
                findPayToLink: {
                    result: validData
                }
            };
            setPastLinks(findPayToLinkData);
        });
    }
    useEffect(() => {
        getKeyInfo({
            variables: {
                merchantId: String(activeMerchantId),
                keyType: "apiKey",
            },
        }).then((res) => {
            const {key} = res.data.createKey;
            setApiKey(key);
            getPastLinksData(key);
        });
    }, [])

    return (
        <>
            {paymentStatus && !paymentStatus?.result?.valid &&
                <Alert
                    message="HATA !"
                    description="3D ödeme tanımınızda hata mevcut. Lütfen posoperasyon@ozan.com adresiyle iletişime geçin."
                    type="error"
                    showIcon
                    closable
                    style={{marginBottom: 20}}
                />
            }
            {apiKey && paymentStatus && paymentStatus.result.valid && (
                <>
                    <CreateLink
                        paymentStatus={paymentStatus}
                        createForm={createForm}
                        refetchList={() => {
                            refetch({
                                merchantId: String(activeMerchantId),
                                apiKey: String(apiKey)
                            }).then((res) => {
                                console.log(res.data);
                                setPastLinks(res.data)
                            })
                        }}
                        openPopup={openPopup}
                        apiKey={apiKey} />
                    <CurrentLinks apiKey={apiKey} />
                    { pastLinks && (<PastLinks
                        createdLink={createdSelectedLink}
                        setCreatedLink={setCreatedSelectedLink}
                        links={pastLinks} activeMerchantId={activeMerchantId} apiKey={apiKey}/>) }
                </>
            )}
        </>
    );
};

export default PayWithLink;
