import { useTranslation } from "react-i18next";
import { toPath } from "lodash";
import { formatAmount } from "@/utils/finance";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import BaseTable from "@/components/base/table";
import { getAwaiting } from "@/features/financial-management/routes/awaiting/helper";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import {useEffect, useRef, useState} from "react";
import GET_AWAITING_SETTLEMENTS from "@/features/financial-management/api/queries/get-awaiting-settlements";
import PERMISSIONS from "@/config/permissions";
import useAuthorization from "@/hooks/use-authorization";

interface AwaitingProps {
  currency: string;
}

const Awaiting = ({ currency }: AwaitingProps) => {
  const { checkPermission } = useAuthorization();
  const [getAwaitingSettlements] = useLazyQuery(GET_AWAITING_SETTLEMENTS);
  const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
  const { t } = useTranslation();
  const formRef = useRef();
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleDetails, setIsVisibleDetails] = useState(false);


  useEffect(() => {
    checkPermissions()
  }, []);

  const checkPermissions = async () => {
    //const awaitingFilter = await checkPermission(PERMISSIONS.financialManagement.pending.filter);
    //const awaitingDetails = await checkPermission(PERMISSIONS.financialManagement.pending.detail.show);

    setIsVisibleFilter(true);
    setIsVisibleDetails(true);
  }

  const filters = isVisibleFilter ? [
    {
      title: t("settlement.awaitingDate"),
      valueType: "dateRange",
      dataIndex: "dateRange",
      key: "dateRange",
      hideInTable: true,
    },
  ] : [];

  const columns = [
    {
      title: t("settlement.paymentDate"),
      dataIndex: "date",
      key: "paymentDate",
      hideInSearch: true,
    },

    {
      title: t("settlement.blockedAmount"),
      dataIndex: toPath(`merchant.HOLD.${currency}.total`),
      render: (value: any) => <>{formatAmount(value, currency)}</>,
      key: "blockedAmount",
      hideInSearch: true,
    },
    {
      title: t("settlement.unblockedAmount"),
      dataIndex: toPath(`merchant.RELEASED.${currency}.total`),
      render: (value: any) => <>{formatAmount(value, currency)}</>,
      key: "blockedAmount",
      hideInSearch: true,
    },
    {
      title: t("settlement.actions"),
      hideInSearch: true,
      dataIndex: "date",
      key: "actions",
      renderText: (value: any) => {
        if (isVisibleDetails) {
          return (
              <Link
                  to={{
                    pathname: "/transactions/all/",
                    search: `?${new URLSearchParams([
                      ["trxListType", "valorDate"],
                      ["dateRange", value],
                      ["dateRange", value],
                    ])}`,
                  }}
              >
                İşlemler
              </Link>
          );
        }
        return "";
      },
    },
  ];

  const TABLE_COLUMNS = [...filters, ...columns];

  const initialVariables = {
    current: 1,
    merchantId: String(activeMerchantId),
    page: 1,
    pageSize: 50,
  };
  return (
    <BaseTable
      request={(params: any, filter: any) =>
        getAwaiting({ ...params, filter }, getAwaitingSettlements, initialVariables)
      }
      rowKey={(record: any) => record.date + "-"+ Math.floor(Math.random() * 10001)}
      columns={TABLE_COLUMNS}
      formRef={formRef}
    />
  );
};

export default Awaiting;
