const payment = {
  "payment.nameSurname": "İsim Soyisim:",
  "payment.emailAddress": "E-posta Adresi:",
  "payment.phoneNumber": "Telefon Numarası:",
  "payment.address": "Adres:",
  "payment.address2": "Adres 2:",
  "payment.country": "Ülke:",
  "payment.city": "Şehir:",
  "payment.postCode": "Posta Kodu:",
  "payment.price": "Fiyat:",
  "payment.piece": "Adet:",
  "payment.pay": "Öde",

  "payment.status.success": "ÖDEMENİZ ALINDI",
  "payment.status.error": "ÖDEME HATASI",
  "payment.status.waiting": "ÖDEMENİZ BEKLEMEDE",
};

export default payment
