import { gql } from "@apollo/client";

const SAVE_MID_CONFIGURATION = gql `
    mutation CreateMidConfiguration($merchantId: String!, $name: String!, $acquirer: Int!, $descriptor: String, $midTested: Int, $tester: String, $acquirerReferences: JSON, $plusInstallment: Int, $fromDateForPlusInstallment: String, $toDateForPlusInstallment: String, $id: Int) {
      createMidConfiguration(merchantId: $merchantId, name: $name, acquirer: $acquirer, descriptor: $descriptor, midTested: $midTested, tester: $tester, acquirerReferences: $acquirerReferences, plusInstallment: $plusInstallment, fromDateForPlusInstallment: $fromDateForPlusInstallment, toDateForPlusInstallment: $toDateForPlusInstallment, id: $id) {
        message
        status
        id
      }
    }
`;


export default SAVE_MID_CONFIGURATION