export const list = {
  "00": "İşlem onaylandı",
  "01": "Başarısız işlem - Manual onay gerekli",
  "02": "Başarısız işlem - Manual onay gerekli",
  "03": "Başarısız işlem",
  "04": "Başarısız işlem - Karta el koy",
  "05": "Başarısız işlem - Kart sahibi banka ret",
  "06": "Başarısız işlem",
  "07": "Başarısız işlem - Bloke kart ile işlem denemesi, özel durum",
  "08": "Başarısız işlem",
  "09": "Başarısız işlem",
  11: "Başarısız işlem - Manual onay gerekli özel durum",
  12: "Başarısız işlem - Hatalı işlem",
  13: "Başarısız işlem - Hatalı tutar",
  14: "Başarısız işlem - Geçersiz kart numarası",
  15: "Başarısız işlem - Tanımsız issuer hatası",
  16: "Başarısız işlem",
  19: "Başarısız işlem - Tekrar deneyiniz.",
  21: "Başarısız işlem",
  24: "Başarısız işlem",
  25: "Başarısız işlem",
  27: "Başarısız işlem",
  26: "Başarısız işlem",
  30: "Başarısız işlem - Kart sahibi bankası genel ret",
  32: "Başarısız işlem - İşlem iade edilmiş",
  33: "Başarısız işlem - Süresi dolmuş kart, karta el koy",
  34: "Başarısız işlem - Kart bankası şüpeli işlem olarak belirlemiş",
  36: "Başarısız işlem - Kısıtlı kart, karta el koy",
  37: "Başarısız işlem - Güvenlik şüphesi, karta el koy",
  38: "Başarısız işlem - PIN deneme sayısı aşıldı",
  41: "Başarısız işlem - Kayıp kart ile işlem",
  43: "Başarısız işlem - Çalıntı kart ile işlem",
  51: "Başarısız işlem - Kart Limiti Yetersiz",
  52: "Başarısız işlem - Karta ait tanımlı hesap bulunamadı",
  54: "Başarısız işlem - Vade süresi dolmuş kart",
  55: "Başarısız işlem - PIN hatası",
  56: "Başarısız işlem - Karta kart bulunamadı",
  57: "Başarısız işlem - Kart sahibine acik olmayan islem",
  58: "Başarısız işlem - Destek ekibi ile iletişime geçiniz",
  59: "Başarısız işlem - Fraud şüphesi",
  61: "Başarısız işlem - Kart Günlük Limit Aşıldı",
  62: "Başarısız işlem - Şüpheli kart ile işlem",
  63: "Başarısız işlem",
  65: "Başarısız işlem - Kart Günlük Limit Aşıldı",
  66: "Başarısız işlem - Güvenlik nedenli işlem red edildi",
  75: "Başarısız işlem - PIN hatası",
  82: "Başarısız işlem - Hatalı CVV ya da CVV geçersiz",
  84: "Başarısız işlem",
  86: "Başarısız işlem - PIN Doğrulanamıyor",
  91: "Başarısız işlem - Kart Bankası veya Switch yanıt vermiyor",
  92: "Başarısız işlem",
  93: "Başarısız işlem - Hukuki Nedenlerle İşlem Reddedildi",
  94: "Başarısız işlem - Bu işlem numarası ile zaten başarılı bir işlem var",
  95: "Başarısız işlem",
  96: "Başarısız işlem",
  107: "Başarısız işlem - Kart sahibi banka ret",
  110: "Başarısız işlem",
  117: "Başarısız işlem",
  127: "Başarısız işlem - İşlem bulunamadı",
  140: "Başarısız işlem",
  145: "Başarısız işlem - Tanımlı hesap bulunamadı",
  148: "Başarısız işlem - Time out",
  149: "Başarısız işlem",
  150: "Başarısız işlem - Tekrar deneyiniz.",
  156: "Başarısız işlem - Gönderilen capture ile preauth bulunamadı",
  157: "Başarısız işlem - Kart sahibine acik olmayan islem",
  169: "Başarısız işlem",
  172: "Başarısız işlem",
  173: "Başarısız işlem - Hatalı işlem denemesi, destek ekibi ile iletişime geçiniz",
  175: "Başarısız işlem - İşlem hatası hakkında bilgi almak için destek ekibi ile iletişime geçiniz",
  177: "Başarısız işlem",
  181: "Başarısız işlem - Bloke kart ile işlem denemesi",
  182: "Başarısız işlem",
  191: "Başarısız işlem - Hatalı tutar gönderimi",
  196: "Başarısız işlem - CVV bilgisi hatalı",
  211: "Başarısız işlem",
  219: "Başarısız işlem",
  237: "Başarısız işlem - 3D Secure işlem yapmalısınız.",
  282: "Başarısız işlem - Yurtdışı kredi kartı yetkisi kapalı",
  283: "Başarısız işlem",
  284: "Başarısız işlem",
  286: "Başarısız işlem - Önceden iptal edilmiş işlem, tekrar iade edilemez",
  297: "Başarısız işlem - İşlem Günlük Limit Aşıldı",
  404: "Başarısız işlem - Tanımsız issuer hatası",
  412: "Başarısız işlem - Provizyon kapaması yapılmış",
  503: "Başarısız işlem - İade tutarı orginal işlem tutarından fazla olamaz",
  504: "Başarısız işlem - Sipariş numarasının devam eden işlemi var",
  505: "Başarısız işlem - Provizyon kapaması orginal işlem tutarından fazla olamaz",
  506: "Başarısız işlem",
  507: "Başarısız işlem - Taksitli işlem için puan kullanılmaz",
  508: "Başarısız işlem - İade yapılamaz, sipariş gün sonuna girmemiş",
  511: "Başarısız işlem",
  513: "Başarısız işlem - Eski tarihli işlem için Capture isteği yapılmaz",
  514: "Başarısız işlem - Belirtilenden eski islemler iade yapilamaz.",
  515: "Başarısız işlem - PostAuth sadece ilişkili preauth için yapılmalı",
  517: "Başarısız işlem - İade edilemez, gün sonu ya da capture edilmiş işlem bulunamadı",
  520: "Başarısız işlem - İptal sadece başarılı işlemler için yapılabilir",
  521: "Başarısız işlem",
  522: "Başarısız işlem - işlem geçmişinde preauth işlem bulunamadı",
  523: "Başarısız işlem - İade için eşleşen sipariş bulunamadı",
  524: "Başarısız işlem - Yabancı para birimi için taksitli işlemi olmaz",
  525: "Başarısız işlem - Yabancı para birimi ile puanlı satış yapılmaz",
  526: "Başarısız işlem",
  527: "Başarısız işlem - Puan işlem tutarından büyük olamaz",
  528: "Başarısız işlem",
  529: "Başarısız işlem",
  532: "Başarısız işlem",
  533: "Başarısız işlem",
  535: "Başarısız işlem",
  544: "Başarısız işlem - Debit kart ile taksitli satış yapılamaz",
  545: "Başarısız işlem - Puan negatif olamaz",
  546: "Başarısız işlem",
  575: "Başarısız işlem",
  576: "Başarısız işlem",
  577: "Başarısız işlem",
  578: "Başarısız işlem",
  579: "Başarısız işlem",
  906: "Başarısız işlem",
  907: "Başarısız işlem",
  910: "Başarısız işlem",
  1022: "Başarısız işlem",
  1060: "Başarısız işlem - Taksit numarası hatalı",
  1078: "Başarısız işlem - Taksit desteklenmeyen kart",
  1091: "Başarısız işlem - Kartın taksit özelliği bulunmuyor",
};

export function getResponseDescription(code: Number) {
  // @ts-ignore
  if (list[code]) {
    // @ts-ignore
    return list[code];
  }
  return "Başarısız işlem";
}
