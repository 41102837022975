import { exportDateFixer } from "@/utils/date";
import { tableFilterArrayFixer } from "@/utils/findDummyDataValue";
import { TRANSACTION_SECURITY_TYPES } from "@/utils/transaction";

const getTransactions = async (
  params: any,
  getTransactionsGQL: any,
  initialVariables: any
) => {

  let parameters = {
    ...initialVariables,
    current: params.current,
    pageSize: params.pageSize,
    page: params.current,
  };
  if (params.operation) {
    let operation: any[] = [];
    if (typeof params.operation === "string") {
      operation = params.operation.split(",");
    } else {
      params.operation.forEach((param: any) => {
        const subParams = param.split(",");
        operation = [...operation, ...subParams];
      });
    }
    parameters = {
      ...parameters,
      operation,
    };
  }
  if (params.trxListType === "valorDate") {
    parameters = {
      ...parameters,
      trxListType: params.trxListType,
      status: ["APPROVED"],
      operation: [
        "DIRECT",
        "3D",
        "3DAUTH",
        "NON SECURE",
        "HALF SECURE",
        "REFUND",
        "CAPTURE",
      ],
    };
  }
  if (params.security) {
    // @ts-ignore
    const securityDetails = TRANSACTION_SECURITY_TYPES[String(params.security)];
    if (parameters.operation) {
      parameters.operation.push(...securityDetails.operation);
    } else {
      parameters.operation = securityDetails.operation;
    }

    if (securityDetails.mdStatus) {
      parameters.mdStatus = securityDetails.mdStatus;
    }
  }
  if (params.status) {
    parameters = {
      ...parameters,
      status:
        typeof params.status === "string" ? [params.status] : params.status,
    };
  }
  if (params.dateRange) {
    if (params.trxListType === "valorDate") {
      parameters = {
        ...parameters,
        valorFromDate: params.dateRange[0],
        valorToDate: params.dateRange[1],
      };
    } else {
      parameters = {
        ...parameters,
        fromDate: params.dateRange[0],
        toDate: params.dateRange[1],
      };
    }
  }
  if (params.filterField) {
    parameters = {
      ...parameters,
      filterField: params.filterField.filterField,
      filterValue:
        params.filterField.filterField === "originalAmount"
          ? String(params.filterField.filterValue)
          : String(params.filterField.filterValue.trim()),
    };    
  }
  if (params.filterField && params.filterField.filterField === 'mdStatus') {
    delete parameters.filterField;
    delete parameters.filterValue;
    parameters = {
      ...parameters,
      mdStatus: [params.filterField.filterValue,]
    };
  }  
  if (params.threeDSecureStatus) {
    parameters = {
      ...parameters,
      threeDSecureStatus: params.threeDSecureStatus,
    };
  }
  const data = await getTransactionsGQL({
    variables: parameters,
  });

  return {
    data: data.data.getTransactions.data,
    success: true,
    total: data.data.getTransactions.total,
  };
};

const exportFile = ({
  exportType,
  dateRange,
  trxListType,
  status = [],
  operation = [],
  email,
  merchantId,
}: any) => {
  const [fromDate, toDate] = dateRange;

  const customDateRange = (() => {
    if (trxListType === "valorDate") {
      return {
        valorFromDate: exportDateFixer(fromDate, ""),
        valorToDate: exportDateFixer(toDate, ""),
        status: ["APPROVED"],
      };
    }
    return {
      fromDate: exportDateFixer(fromDate, "00:00"),
      toDate: exportDateFixer(toDate, "23:59"),
    };
  })();

  const selectedFields = [
    "parents",
    "company",
    "originalAmount",
    "netAmount",
    "commission",
    "merchantRate",
    "created_at",
    "valorDate",
    "originalCurrency",
    "transactionUUID",
    "responseCode",
    "responseMessage",
    "status",
    "relatedTransactionId",
    "creditcard",
    "transactionType",
    "installment",
    "referenceNo",
    "cardCategory",
    "issuerBank",
    "customerName",
    "customerEmail",
    "cardType",
    "refunded",
    "mdStatus",
  ].join("&");

  let data = {
    customerExportEmail: email,
    errorCode: "",
    filterField: "",
    filterValue: "",
    exportType,
    selectedFields,
    page: 1,
    ...(operation.length > 0 && {
      operation: tableFilterArrayFixer(operation),
    }),
    ...(status.length > 0 ? { status } : {}),
    ...customDateRange,
    merchantId,
  };

  return data;
};

export { getTransactions, exportFile };
