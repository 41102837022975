import {
  Home,
  Transaction,
  Financial,
  Link,
  Settings,
  Extension,
} from "@/assets/icons/components";
import {InsertRowRightOutlined} from "@ant-design/icons";
import {CreditCardOutlined} from "@ant-design/icons";
import PERMISSIONS from "@/config/permissions";

export const IconMap = {
  dashboard: <Home />,
  transaction: <Transaction />,
  financial: <Financial />,
  pos: <CreditCardOutlined />,
  link: <Link />,
  paymentItem: <InsertRowRightOutlined />,
  settings: <Settings/>,
  extension: <Extension />,
};

export const getDefaultMenus = (permissions: any, isVisiblePosManagement: boolean) => {
  let transactionSubMenus = [
    {
      path: "all",
      name: "Tüm İşlemler",
      permission: PERMISSIONS.transactions.vpos_trx.show
    },
    {
      path: "physical-pos",
      name: "Fiziki POS İşlemleri",
      permission: PERMISSIONS.transactions.phypos_trx.show
    },
    {
      path: "refund",
      name: "İptal/İade",
      permission: PERMISSIONS.transactions.refund.show
    },
    {
      path: "chargebacks",
      name: "Harcama İtirazları",
      permission: PERMISSIONS.transactions.chargebacks.show
    }
  ];

  let financialSubMenus = [
    {
      path: "completed",
      name: "Ödemeler",
      permission: PERMISSIONS.financialManagement.payments.show,
    },
    {
      path: "awaiting",
      name: "Bekleyen Ödemeler",
      permission: PERMISSIONS.financialManagement.pending.show,
    },
    {
      path: "undue",
      name: "Vadesi Gelmemiş Ödemeler",
      permission: PERMISSIONS.financialManagement.pending.show,
    },
    {
      path: "pos-statement",
      name: "Hesap Dökümü",
      permission: PERMISSIONS.financialManagement.bankStatement.show
    },
    {
      path: "commissions",
      name: "Komisyonlar",
      permission: PERMISSIONS.financialManagement.commissions.show,
    },
  ];

  let posSubMenus = [
    {
      path: "mid-list",
      name: "POS Listesi"
    },
    {
      path: "merchant-rates",
      name: "İşyeri Oranları"
    },
    {
      path: "mid-profile",
      name: "POS Profilleri"
    },
  ];

  let settingsSubMenus = [
    {
      path: "merchant",
      name: "Firma Ayarları",
      permission: PERMISSIONS.settings.merchant.show
    },
    {
      path: "users",
      name: "Kullanıcı Ayarları",
      permission: PERMISSIONS.settings.user.show
    },
  ];

  const defaultMenus = [
    {
      path: "/welcome",
      name: "Ana sayfa",
      icon: "dashboard",
      isVisible: true
    },
    {
      path: "/transactions",
      name: "İşlemler",
      icon: "transaction",
      isVisible: transactionSubMenus.length > 0,
      routes: transactionSubMenus
    },
    {
      path: "/financial-management",
      name: "Finansal Yönetim",
      icon: "financial",
      isVisible: financialSubMenus.length > 0,
      routes: financialSubMenus,
    },
    {
      path: "/pos-management",
      name: "Pos Yönetimi",
      icon: "pos",
      isVisible: isVisiblePosManagement,
      routes: posSubMenus
    },
    {
      path: "/pay-with-link",
      name: "Link ile Ödeme",
      icon: "link",
      isVisible: true
    },
    {
      path: "/settings",
      name: "Ayarlar",
      icon: "settings",
      isVisible: settingsSubMenus.length > 0,
      routes: settingsSubMenus
    },
    {
      path: "https://docs.ozan.com/docs/plugins",
      name: "Eklentiler",
      icon: "extension",
      isUrl: true,
      isVisible: true
    },
  ].filter((menuItem) => menuItem.isVisible);

  return defaultMenus
}