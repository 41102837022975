import {gql} from "@apollo/client";

const GET_CURRENT_MERCHANTS = gql`
  query ($merchantId: String!) {
    getSubMerchants(merchantId: $merchantId) {
      message
      status
      subMerchants {
        id
        company
        email
        name
        subMerchants
      }
    }
    getMerchant(merchantId: $merchantId) {
      message
      status
      merchant {
        id
        name
      }
    }
  }
`;


export default GET_CURRENT_MERCHANTS