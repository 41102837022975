import {
    Alert,
    Checkbox,
    Col,
    Descriptions,
    InputNumber,
    Modal,
    Row,
    Space,
    Spin,
    Typography
} from 'antd';
import { formatAmount } from "@/utils/finance";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";
import { currencyParser, currencyFormatter } from "@/utils/finance";
import { RESPONSE_STATUS } from "@/config/constants";
import {useMutation, useQuery} from "@apollo/client";
import REFUND from "@/features/transactions/api/mutations/refund";
import CREATE_KEY from "@/features/settings/api/create-key";
import {useSelector} from "react-redux";
import {RootState} from "@/store";
import GET_REFUNDABLE_AMOUNT from "@/features/transactions/api/queries/get-transaction-refundable-amount";

const RefundModal = ({ transaction, closeModal }: any) => {
    const [refundMutation] = useMutation(REFUND);
    const [createKey] = useMutation(CREATE_KEY);
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [refundableAmount,setRefundableAmount]=useState("");


    const { Text } = Typography;

    const [value, setValue] = useState(transaction.fx.merchant.originalAmount / 100);
    const [type, setType] = useState("all");
    const { t } = useTranslation();
    const { activeMerchantId } = useSelector((state: RootState) => state.auth.params);
    const transactionId = transaction.transaction.merchant.transactionId;
    const isRefundable = transaction.refundable;

    const { data, error, loading } = useQuery(GET_REFUNDABLE_AMOUNT, {
        variables: {
            merchantId: String(activeMerchantId),
            transactionId: transactionId,
        },
    });

    useEffect(() => {
        if (data) {
            setRefundableAmount(data.getTransactionRefundableAmount.balance);
        }
    }, [data]);


    const renderOptions = () => {
        const options = [
            { label: t('transaction.allRefund'), value: 'all' },
            { label: t('transaction.partialRefund'), value: 'partial' },
        ];

        const { originalAmount } = transaction.fx.merchant;
        const onChange = (event: any) => {
            const newType = event.target.value;
            setType(newType);
            setValue(newType === 'all' ? originalAmount / 100 : 0);
        };

        const renderedOptions = options.map((option) => (
            <Checkbox
                key={option.value}
                checked={type === option.value}
                value={option.value}
                onChange={onChange}
            >
                {option.label}
            </Checkbox>
        ));

        return <Space direction={'vertical'}>{renderedOptions}</Space>;
    }


    // @ts-ignore
    const innerCurrencyFormatter = (value: any) => {
        const { originalCurrency } = transaction.fx.merchant;
        //@ts-ignore
        return currencyFormatter(value, originalCurrency);
    }
    //@ts-ignore
    const innerCurrencyParser = (val: any) => {
        const { originalAmount } = transaction.fx.merchant;

        // @ts-ignore
        const reversedVal = currencyParser(val);

        if (reversedVal > originalAmount / 100) {
            return originalAmount / 100;
        }

        return reversedVal;
    }

    const resetState = () => {
        setValue(0);
        setIsLoading(false);
        setResponseStatus("");
        setResponseMessage("")
    }

    const renderErrorMessage = () => {
        return (
            <Alert
                description={responseMessage}
                message={t('transaction.errorMessage')}
                type={'error'}
            />
        );
    }

    const renderSuccessMessage = () => {
        return (
            <Alert
                description={t('transaction.refundSuccess')}
                message={t('transaction.success')}
                type={'success'}
            />
        );
    }

    const renderAlertSection = () => {

        switch (responseStatus) {
            case RESPONSE_STATUS.APPROVED:
                return renderSuccessMessage();
            case RESPONSE_STATUS.ERROR:
            case RESPONSE_STATUS.DECLINED:
                return renderErrorMessage();
            default:
                return <div />;
        }
    }

    const renderInfoTable = () => {
        const { originalAmount, originalCurrency } = transaction.fx.merchant;

        const intlName = t('transaction.fullName');
        const intlTransactionId = t('transaction.transactionId');
        const intlAmount = t('transaction.amount');
        const intlDate = t('transaction.date');
        const intlRefundableAmount = t('transaction.refundableAmount');

        return (
            <Descriptions column={1} bordered>
                <Descriptions.Item label={intlTransactionId}>
                    {transaction?.transaction?.merchant.transactionId}
                </Descriptions.Item>
                <Descriptions.Item label={intlAmount}>
                    {formatAmount(originalAmount, originalCurrency)}
                </Descriptions.Item>
                {isRefundable && (
                    <Descriptions.Item label={intlRefundableAmount}>
                        {formatAmount(refundableAmount, originalCurrency)}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label={intlName}>
                    {`${transaction?.customerInfo?.billingFirstName} ${transaction?.customerInfo?.billingLastName}`}
                </Descriptions.Item>
                <Descriptions.Item label={intlDate}>
                    {transaction?.transaction?.merchant.created_at}
                </Descriptions.Item>
            </Descriptions>
        );
    }

    const renderFormSection = () => {
        const { originalCurrency } = transaction.fx.merchant;
        const intlWarnMessage = t('transaction.refund.warning');
        const inputLabel = t('transaction.refundAmount');

        const inputGroup = (
            <Space direction={'vertical'}>
                {type === 'partial' ? <InputNumber
                    addonBefore={inputLabel}
                    defaultValue={0}
                    min={0}
                    //@ts-ignore
                    onChange={(value) => setValue(value)}
                    parser={(val) => innerCurrencyParser(val)}
                    formatter={(val) => innerCurrencyFormatter(val)}
                /> : null}
                <Text strong type="danger">
                    {`${currencyFormatter(
                        value,
                        originalCurrency,
                    )} ${originalCurrency}`}
                    <Text>{intlWarnMessage}</Text>
                </Text>
            </Space>
        );

        return (
            <Row align={'middle'} style={{ background: '#fafafa', borderRadius: 8, padding: 16 }}>
                <Col span={8}>{renderOptions()}</Col>
                <Col span={16}>{inputGroup}</Col>
            </Row>
        );
    }

    const renderModalContext = () => {

        if (loading) {
            return <Spin />;
        }

        return (
            <Space direction={'vertical'} style={{ width: '100%' }}>
                {renderInfoTable()}
                {renderFormSection()}
                {renderAlertSection()}
            </Space>
        );
    }



    return (
        <Modal
            title={t("transaction.refund")}
            confirmLoading={isLoading}
            visible={true}
            onOk={async () => {
                createKey({
                    variables: {
                        merchantId: String(activeMerchantId),
                        keyType: "apiKey",
                    },
                }).then((keyResponse) => {
                    const { key } = keyResponse.data.createKey;
                    const { referenceNo, transactionId } = transaction.transaction.merchant;
                    const { originalCurrency } = transaction.fx.merchant;
                    refundMutation({
                      variables: {
                        amount: Math.round(value * 100),
                        currency: originalCurrency,
                        merchantId: activeMerchantId,
                        referenceNo,
                        transactionId,
                        apiKey: key,
                      },
                    })
                    .then((response) => {
                        const { status, message } = response.data.refund;
                        setResponseMessage(message);
                        setResponseStatus(status);
                    })
                    .catch(() => {
                        setResponseStatus(RESPONSE_STATUS.ERROR);
                        setResponseMessage(t("transaction.refundError"));
                    });
                });
            }}
            onCancel={() => closeModal(responseStatus)}
            afterClose={() => resetState()}
            destroyOnClose
        >
            {renderModalContext()}
        </Modal>
    )
}

export default RefundModal