import { gql } from "@apollo/client";

const CREATE_KEY = gql`
  mutation ($keyType: String!, $merchantId: String!) {
    createKey(keyType: $keyType, merchantId: $merchantId) {
      key
    }
  }
`;

export default CREATE_KEY;
