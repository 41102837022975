import { gql } from "@apollo/client";

const GET_AWAITING_SETTLEMENTS = gql`
  query GetAwaitingSettlements($pageSize: Int!, $page: Int!, $merchantId: String!, $current: Int!, $fromDate: String, $toDate: String) {
  getAwaitingSettlements(pageSize: $pageSize, page: $page, merchantId: $merchantId, current: $current, fromDate: $fromDate, toDate: $toDate) {
    response
    status
  }
}
`;

export default GET_AWAITING_SETTLEMENTS;