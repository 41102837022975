const settlements = {
  "settlement.date": "Date Range",
  "settlement.paymentDate": "Payment Date",
  "settlement.paymentAmount": "Payment Amount",
  "settlement.paidAmount": "Paid Amount",
  "settlement.fee": "Fee",
  "settlement.totalRefund": "Total Refund",
  "settlement.totalChargebackAmount": "Total Chargeback",
  "settlement.actions": "Actions",
  "settlement.checkMail": "Please check your email",
  "settlement.unknownError": "An error occured",
  "settlement.csvSentEmail": "CSV file sent your email address",
  "settlement.tryAgain": "Try later",
  "settlement.export": "Export",
  "settlement.blockedAmount": "Blocked Amount(Gross)",
  "settlement.unblockedAmount": "Unblocked Amount(Gross)",
  "settlement.unblockedDate": "Unblocked Date",
};

export default settlements
