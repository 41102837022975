export const amountText = (amount: any | null, currency: any | null) => {
  if (amount === "-" || !(amount ?? false)) {
    return "-";
  }
  if (currency) {
    return `${amount / 100} ${currency}`;
  }
  return `${amount / 100}`;
};

export function formatAmount(value: any, currency: any) {
  if (!value || value === "-") {
    return "-";
  }

  const formatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: currency,
  });

  const formattedValue = formatter.format(value / 100);

  if (currency) {
    return `${formattedValue} ${currency}`;
  }

  return formattedValue;
}

export function currencyFormatter(value: any, currency = 'TRY', locales = 'tr-TR') {
  return new Intl.NumberFormat(locales, {
    currencyDisplay: 'code',
    style: 'currency',
    currency,
  })
      .format(value)
      .replace(currency, '')
      .trimStart();
}

export function currencyParser(val: any, locales = 'tr-TR') {
  try {
    if (typeof val === 'number') {
      val = val.toString();
    }

    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }
    let group = new Intl.NumberFormat(locales).format(1111).replace(/1/g, '');
    let decimal = new Intl.NumberFormat(locales).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;

    reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {}
}