const financialManagement = {
  "financialManagement.oneTime": "Tek Çekim",
  "financialManagement.internationalCard": "Yabancı Kart",
  "financialManagement.blockageDay": "Ödeme Periyodu",
  "financialManagement.day": "Gün",
  "financialManagement.installment": "Taksit Sayısı",
  "financialManagement.interestRate": "Komisyon",
  "financialManagement.buyerCost": "Alıcı Maliyeti",
  "financialManagement.saveChanges": "Değişiklikleri Kaydet",
  "financialManagement.modalOkeyText": "Evet",
  "financialManagement.modalCancelText": "Vazgeç",
  "financialManagement.continueQuestion":
    "Devam etmek istediğinize emin misiniz?",
  "financialManagement.undoChanges": "Değişiklikleri geri al",
  "financialManagement.changeAllInterestRate": "Tümünü kullanıcıya yansıt",
  "financialManagement.undoChanges.title":
    "Kaydetme işlemi gerçekleşmeden önce değişiklikleri geri al",
  "financialManagement.rates.caution": `100 TL'lik işlemde %{{buyerCostRate}}  alıcı maliyeti uygularsanız hesabınıza {{totalIncome}} geçecektir.`,
};

export default financialManagement