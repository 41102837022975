import { gql } from "@apollo/client";

const GET_REFUNDABLE_AMOUNT = gql`
  query GetTransactionRefundableAmount(
    $merchantId: String!
    $transactionId: String!
  ) {
    getTransactionRefundableAmount(
      merchantId: $merchantId
      transactionId: $transactionId
    ) {
      balance
      status
    }
  }
`;

export default GET_REFUNDABLE_AMOUNT;
