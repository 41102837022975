import moment from "moment";

interface DateCellProps {
  date: string;
}

const DateCell = ({ date }: DateCellProps) => {
  if (!date) {
    return <p>-</p>;
  }
  const dateObject = moment(date);
  return (
      <p style={{ margin: 0 }}>
        {dateObject.format("DD.MM.YYYY hh:mm:ss")}
      </p>
  );
}

export default DateCell