import { gql } from "@apollo/client";

const SAVE_INSTALLMENT_RATES = gql`
  mutation (
    $merchantId: String!
    $rates: [JSON]
  ) {
    saveInstallmentRates(
      merchantId: $merchantId
      rates: $rates
    ) {
      message
      status
    }
  }
`;

export default SAVE_INSTALLMENT_RATES;
