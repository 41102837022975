import {Button} from "antd";
import {useTranslation} from "react-i18next";

interface ActionButtonsProps {
    onChangeAllInterestRateClick: any,
    undoChanges: any,
    save: any
}

const ActionButtons = ({ onChangeAllInterestRateClick, undoChanges, save }: ActionButtonsProps) => {
    const { t } = useTranslation();
  return(
      <>
          <Button type="primary" onClick={save}>
              { t("financialManagement.saveChanges") }
          </Button>
          <Button onClick={undoChanges}>
              { t("financialManagement.undoChanges") }
          </Button>
          <Button onClick={onChangeAllInterestRateClick}>
              { t("financialManagement.changeAllInterestRate") }
          </Button>
      </>
  )
}

export default ActionButtons;