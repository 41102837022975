import { useQuery } from "@apollo/client";
import { useLayoutEffect } from "react";
import GET_WEEKLY_PREVIEW_DATA from "@/features/dashboard/api/queries/get-weekly-preview-data";
import useAuth from "@/hooks/use-auth";
import moment from "moment";
import ProCard, { StatisticCard } from '@ant-design/pro-card';
import { Area } from '@ant-design/plots';
import _ from "lodash";

import { Divider, Skeleton, Statistic, Typography } from 'antd';
import { formatAmount } from '@/utils/finance';
import {
  useState
} from "react";
import { populateNDaysData } from "@/utils/date";
const { Title } = Typography;

const WeeklyPreview = () => {
  const { auth } = useAuth();
  const { data, error, loading } = useQuery(GET_WEEKLY_PREVIEW_DATA, {
    variables: {
      merchantId: String(auth.activeMerchantId),
      fromDate: moment().subtract("7", "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      status: ["APPROVED"],
      settlementStatus: ["UNPAID"],
    },
  });
  const [weeklyData, setWeeklyData] = useState<any>([]);
  const [isResponsive, setIsResponsive] = useState(false);
  const [totalBalance, setTotalBalance] = useState([]);

  useLayoutEffect(() => {
    if (data) {
      const transactionCountReport = data.getTransactionCountByDate.response;
      const awaitingSettlements = data.getSettlements.data;
      const refundBalance = data.getRefundBalance.response;
      const filteredData = populateNDaysData(
          transactionCountReport,
          moment().subtract(6, 'days'),
          moment(),
      );
      const sortedDateData = _.orderBy(filteredData, (firstDate, secondDate) =>
          // @ts-ignore
          moment(firstDate).diff(moment(secondDate)),
      );
      setWeeklyData(sortedDateData);
      setTotalBalance(refundBalance.refundBalance + refundBalance.voidBalance);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const renderChart = () => {
    const config = {
      data: weeklyData ?? [],
      xField: 'date',
      yField: 'total',
      yAxis: {
        tickCount: 5,
        grid: {
          line: {
            style: {
              strokeOpacity: 0.4,
            },
          },
        },
      },
      xAxis: {
        range: [0, 1],
      },
      meta: {
        total: {
          alias: 'Günlük Toplam',
        },
        date: {
          formatter: (dateString: string) => moment(dateString).format('DD.MM - dddd'),
        },
      },
      areaStyle: () => {
        return {
          fill: 'l(270) 0:#ffffff 1:#5B8FF9',
        };
      },
    };

    return <StatisticCard colSpan={isResponsive ? 24 : 18} chart={<Area {...config} />} />;
  };
  const renderStatistics = () => {
    const awaitingSettlementsTry = _.get(data.getSettlements, 'data[0].totals.gross', '-');
    return (
        <StatisticCard direction="column">
          <StatisticCard title="Bakiyem">
            <StatisticCard.Group>
              <Statistic
                  value={formatAmount(totalBalance ?? 0, 'TRY')}
                  title="TRY"
                  //@ts-ignore
                  layout="horizontal"
                  formatter={(value) => value}
              />
            </StatisticCard.Group>
          </StatisticCard>
          <Divider />
          <StatisticCard title="Ödemeler">
            <StatisticCard.Group>
              <Statistic
                  value={formatAmount(awaitingSettlementsTry ?? 0, 'TRY')}
                  title="TRY"
                  //@ts-ignore
                  layout="horizontal"
              />
            </StatisticCard.Group>
          </StatisticCard>
        </StatisticCard>
    );
  };
  return (
      <ProCard
          title={<Title level={3}>Son 7 Gün</Title>}
          subTitle='Başarılı Satış, İptal ve İade İşemleri'
          headerBordered
      >
        {loading ? <Skeleton active /> :
            <StatisticCard split={isResponsive ? 'horizontal' : 'vertical'}>
              {renderChart()}
              {renderStatistics()}
            </StatisticCard>
        }
      </ProCard>
  );
};

export default WeeklyPreview;
