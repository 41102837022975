import { gql } from "@apollo/client";

const UPDATE_PAY_TO_LINK = gql`
  mutation UpdatePayToLink($updatePayToLinkId: ID!, $updatePayToLinkInput: UpdatePayToLinkInput!) {
      updatePayToLink(id: $updatePayToLinkId, updatePayToLinkInput: $updatePayToLinkInput) {
        status
        message
        result {
          id
          check3D
          name
          desc
          amount
          currency
          imageUrlMedium
          imageUrlLarge
          stock
          limit
          cargo
          status
          maxInstallmentNumber
        }
      }
}
`;

export default UPDATE_PAY_TO_LINK;
